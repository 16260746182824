import { Notify } from "notiflix";

Notify.init({
    position: 'center-center',
    distance: '50px',
    timeout: 2000,
});

const NOTIFY_INTERVAL = 60 * 60 * 1000; // 1 hour in milliseconds
const LAST_NOTIFY_TIME_KEY = 'lastNotifyTime';

export function checkNetworkType() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
        const type = connection.effectiveType;
        if (type !== '4g' && type !== '5g') {
            const now = Date.now();
            const lastNotifyTime = localStorage.getItem(LAST_NOTIFY_TIME_KEY);

            if (!lastNotifyTime || (now - lastNotifyTime) > NOTIFY_INTERVAL) {
                Notify.warning("Sinyal anda saat ini " + type + " mohon aktifkan mode pesawat");
                localStorage.setItem(LAST_NOTIFY_TIME_KEY, now);
            }
        }
    }
}
