import { useIsAuthenticated } from "@azure/msal-react";
import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, Col } from "antd";

import { useNavigate } from "react-router";
import { connect } from "react-redux";

import { InitializeTicket, InitializeTicketDetails } from "../../../redux/action";
import Navbar from "../../../atoms/navbar";
import Footer from "../../../atoms/footer";

import "antd/dist/antd.css";

function CreateTicket(props) {

  const [form] = Form.useForm();

  let navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const onFinish = (values) => {
    let getNumber = values.phonenumber.split('');
    if (getNumber[0] === 0) {
      getNumber[0] = '62'
      values.phonenumber = getNumber.join('')
    }
    props.setInitializeTicket(navigate, values);
  };

  const loadProfile = () => {
    // Load Session & set input value 
    form.setFieldsValue({
      email: props.initializeTicket.email,
      fullname: props.initializeTicket.fullname,
      phonenumber: props.initializeTicket.phone_number ? props.initializeTicket.phone_number : props.initializeTicket.phonenumber,
    });

  };

  useEffect(() => {

    if (!isAuthenticated) {
      navigate('/')
    } else {
      // 1. Load Profile for auto input name & email
      loadProfile();
    }

  }, []);

  return (
    <Fragment>
      <Navbar
        title="New Ticket"
        subtitle="Help Center"
        back={true}
        navigate="/help/history-ticket"
      ></Navbar>
      <div className="container white-back-cr">
        <div className="container my-3 p-4">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p className="fs-6 fw-bold">Contact Information</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form
                form={form}

                name="contact_information"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 12,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete={false}
                size="large"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email !",
                      type: "email",

                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>

                <Form.Item
                  label="Full Name"
                  name="fullname"
                  autocomplete={false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your name !",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  name="phonenumber"
                  autocomplete={false}

                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number !",
                    },
                  ]}
                >
                  <Input placeholder="example : 6295977300189" />
                </Form.Item>

                <Col xs={{ span: 12 }} lg={{ span: 12, offset: 5 }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Next
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}

const reduxState = (state) => ({
  initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
  setInitializeTicket: (navigate, data) =>
    dispatch(InitializeTicket(navigate, data)),
  setInitializeTicketDetails: (data) =>
    dispatch(InitializeTicketDetails(null, data)),
});

export default connect(reduxState, reduxDispatch)(CreateTicket);
