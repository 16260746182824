import React, { Fragment } from "react";

import "./App.css";

import { Provider } from 'react-redux';
import { store } from "../../redux";
import Router from "../../router";

function App() {
  return (
    <Provider store={store}>
      <Fragment>
        <Router />
      </Fragment>
    </Provider>
  );
}

export default App;
