import React, { useEffect, useState } from 'react';
import Navbar from '../../atoms/navbar';
import { Spin } from 'antd'; // Import komponen Spin dari Ant Design

function ApplicationLists() {
    const [iframeHeight, setIframeHeight] = useState(500); // Tinggi awal iframe
    const [isLoading, setIsLoading] = useState(true); // State untuk menandakan loading iframe

    useEffect(() => {
        // Fungsi untuk mengatur tinggi iframe sesuai tinggi layar saat komponen dimuat
        const setIframeHeightToScreenHeight = () => {
            const screenHeight = window.innerHeight;
            setIframeHeight(screenHeight);
        };

        // Panggil fungsi saat komponen dimuat
        setIframeHeightToScreenHeight();

        // Tambahkan event listener untuk menyesuaikan tinggi saat ukuran layar berubah
        window.addEventListener('resize', setIframeHeightToScreenHeight);

        // Bersihkan event listener saat komponen dibongkar
        return () => {
            window.removeEventListener('resize', setIframeHeightToScreenHeight);
        };
    }, []);

    // Fungsi untuk menandakan bahwa iframe sudah selesai dimuat
    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    return (
        <>
            <Navbar
                title="View"
                back={true}
                subtitle="Application Lists"
                navigate="/home"
            ></Navbar>
            <div className="container white-back-cr" style={{ minHeight: 500, position: 'relative' }}>
                {isLoading && (
                    <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                )}
                <iframe
                    src="https://digital.brmapps.com/"
                    width="100%"
                    key='prd-app'
                    height={iframeHeight + 'px'}
                    title="Application List"
                    allowFullScreen
                    onLoad={handleIframeLoad}
                    style={{ display: isLoading ? 'none' : 'block' }}
                ></iframe>
            </div>

        </>
    );
}

export default ApplicationLists;
