import React, { useState, useEffect } from 'react';
import ImportantInfo from '../../atoms/important-updates';
import Navbar from '../../atoms/navbar';
import Footer from '../../atoms/footer';
import { Tabs } from 'antd';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/api-config';
import ScheduleDriver from './schedule.booking';

const DriverMenu = () => {
    // Mendapatkan nilai tab aktif dari local storage saat komponen dimuat
    const initialActiveTab = localStorage.getItem('activeTab') || "1";
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    // Fungsi untuk menangani perubahan tab
    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    async function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then(async (responseToken) => {
            })
            .catch((e) => {
                console.error(e)
            });
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    // Menyimpan nilai tab aktif ke local storage setiap kali nilai berubah
    useEffect(() => {

        localStorage.setItem('activeTab', activeTab);
        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }


    }, [activeTab, inProgress, accounts, instance, isAuthenticated]);

    return (
        <>
            <Navbar
                title="Car"
                back={true}
                subtitle=""
                navigate="/home"
            />
            <div className="container white-back-cr">
                <ImportantInfo />
                <div className="container-fluid" style={{ alignSelf: "center" }}>
                    <Tabs
                        size='large'
                        defaultActiveKey={activeTab}
                        onChange={handleTabChange}
                    >
                        <Tabs.TabPane tab="Car Schedule" key="2">
                            <ScheduleDriver></ScheduleDriver>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DriverMenu;
