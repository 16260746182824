import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/api-config";
import { useNavigate } from "react-router";
import { useEffect } from "react";

function Login() {

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  async function handleLogin(instance) {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {

    async function checkLogin() {
      await handleLogin(instance);
    }

    const currentURL = window.location.href;
    const url = new URL(currentURL);

    switch (url.hostname) {
      case "hias.citrapalu.net":
      case "hias-staging.citrapalu.net":
      case "hias.gorontalominerals.com":
        return navigate('/hias/create');
      case "operator.citrapalu.net":
        return navigate('/fms/operator/register');
      case "fuel.citrapalu.net":
      case "backdaterf.citrapalu.net":
      case "backdatedf.citrapalu.net":
        return navigate('/fms/menu');
      default:
    }

    if (isAuthenticated) {
      return navigate('/home')
    }

    checkLogin();

  }, [isAuthenticated]);
}

export default Login;
