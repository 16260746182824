import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { useMsal } from '@azure/msal-react';

import { Layout, Collapse, Form, AutoComplete, Input, Row, Col, Button, message, Upload, Radio, Checkbox, TimePicker, Select, Space, DatePicker, Spin, Alert, InputNumber } from "antd";

import Navbar from '../../../atoms/navbar';

import { loginRequest } from '../../../config/api-config';
import HeaderFarms from '../../../utils/header_farms';
import API from '../../../services';

import { Block, Confirm, Loading, Report } from 'notiflix';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

function CreateWorkingPermit() {

    const [form] = Form.useForm();
    let navigate = useNavigate();

    const { TextArea } = Input;
    const { Panel } = Collapse;

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [jobType, setJobType] = useState(null);
    const [WorkSpv, setWorkSpv] = useState(null);
    const [Location, setLocation] = useState([]);
    const [itemsWp, setItemsWp] = useState([]);
    const [satkers, setSatker] = useState([]);
    const [user, setUser] = useState(null);

    const [employeeBranch, setEmployeeBranch] = useState(0)
    const [employeeDepartment, setEmployeeDepartment] = useState(null)

    const handleLocationChange = (value) => {
        setSelectedLocations(value);
    };

    const handleInputChange = (value) => {
        setInputValue(value);
    };

    const { instance, accounts } = useMsal();

    const handleAddLocation = () => {
        if (inputValue) {
            // Gabungkan lokasi yang ada dengan yang baru
            setSelectedLocations([...selectedLocations, inputValue]);
            setInputValue('');

            // Atur ulang nilai job_location pada form
            form.setFieldsValue({ job_location: [...selectedLocations, inputValue] });
        }
    };

    function mergeNotes(data, user) {

        if (data.notes) {
            // Create a new note with the requested date and time format
            let formattedTime = `[${user.fullname}] : ${data.notes}`;

            // Combine the previous notes with the new and old notes
            let newNotes = "\n" + formattedTime + '<br>';

            // Return the combined notes
            return newNotes;
        }

        return null
    }

    const onFinish = async (data) => {

        if (isSubmitting) return; // Cek apakah sedang dalam proses pengiriman data

        setIsSubmitting(true); // Set status pengiriman menjadi true untuk menandakan bahwa sedang dalam proses pengiriman

        const formValues = form.getFieldsValue();
        // Cek File apakah ada yang kosong
        for (const key in formValues) {
            if (formValues.hasOwnProperty(key)) {

                if (formValues[key]?.file?.status === 'removed') {
                    return message.warning('Mohon lengkapi Lampiran.')
                }
            }
        }

        Confirm.show(
            'Important Confirmation',
            'Are you sure ?',
            'Yes',
            'No',
            () => {

                Loading.standard('Mohon tunggu beberapa saat..')

                const request = {
                    ...loginRequest,
                    account: accounts[0],
                };

                instance
                    .acquireTokenSilent(request)
                    .then((responseToken) => {

                        let bodyFormData = new FormData();

                        let newNotes = mergeNotes(data, user);

                        if (newNotes) {
                            // Save the new notes back to data
                            data.notes = newNotes;
                        }

                        const attachments = extractAttachments(data);

                        let required_personal_protective_equipment = data.required_personal_protective_equipment
                        let identification_job_lifting = data.identification_job_lifting
                        let identification_job_area = data.identification_job_area
                        let other_safety_equipment = data.other_safety_equipment;
                        let general_preparation = data.general_preparation;
                        let job_preparation = data.job_preparation;
                        let getWorkers = data.workers;
                        let jobType = data.job_type

                        // Remove Items Table 

                        delete data.required_personal_protective_equipment
                        delete data.identification_job_lifting
                        delete data.identification_job_area
                        delete data.other_safety_equipment
                        delete data.general_preparation
                        delete data.job_preparation
                        delete data.job_type
                        delete data.workers

                        // getAllObject
                        for (const key in data) {
                            if (data.hasOwnProperty(key)) {
                                let value = data[key];

                                // Periksa apakah nilai adalah `undefined`
                                if (value === undefined) {
                                    // Jika nilai undefined dan kunci tidak dimulai dengan "ITEM_", hapus kunci tersebut dari objek
                                    if (!key.startsWith("item_")) {
                                        delete data[key];
                                        continue; // Langsung ke iterasi berikutnya
                                    } else {
                                        value = false;
                                    }
                                }

                                bodyFormData.append(key, value);
                            }
                        }

                        job_preparation?.forEach((item) => {
                            bodyFormData.append(`${item}`, true);
                        });

                        other_safety_equipment?.forEach((item) => {
                            bodyFormData.append(`${item}`, true);
                        });

                        general_preparation?.forEach((item) => {
                            bodyFormData.append(`${item}`, true);
                        });

                        required_personal_protective_equipment?.forEach((item) => {
                            bodyFormData.append(`${item}`, true);
                        })

                        identification_job_lifting?.forEach((item) => {
                            bodyFormData.append(`${item}`, true);
                        });

                        identification_job_area?.forEach((item) => {
                            bodyFormData.append(`${item}`, true);
                        })

                        // Worker
                        bodyFormData.append('workers', getWorkers);
                        // Job Type
                        bodyFormData.append('job_type', jobType);


                        // Append attachments
                        attachments.forEach((attachment) => {
                            // Remove Before object
                            bodyFormData.delete([attachment.item_id]);

                            // add new attachment after extract
                            // true columnt attachment on t_permit
                            bodyFormData.append([attachment.item_id], true);
                            // Send Attachment
                            bodyFormData.append([attachment.item_id], attachment.file);

                        });

                        for (const pair of bodyFormData.entries()) {
                            console.log(pair[0] + ': ' + pair[1]);
                        }

                        API.createWorkingPermit(HeaderFarms(responseToken.accessToken), bodyFormData).then(res => {

                            Report.success(
                                `The request was successfully`,
                                "Thankyou, We will immediately process your request",
                                "Okay",
                                function () {
                                    navigate('/wp/history')
                                }
                            );

                        }).catch(err => {
                            Loading.remove();
                            if (err.response.status === 400) {
                                Report.info(
                                    "Lampiran anda gagal di upload",
                                    err.response.data.status,
                                    "Okay"
                                );
                            } else {
                                Report.warning(
                                    "Oops.. something wrong",
                                    "Sorry, this application is experiencing problems",
                                    "Okay"
                                );
                            }
                        })
                            .finally(() => {
                                setIsSubmitting(false); // Set status pengiriman kembali ke false setelah selesai
                                Loading.remove();
                            });
                    })
                    .catch((e) => {
                        console.error(e)
                    });
            },
            () => {
                setIsSubmitting(false);
            }
        )
    }

    const extractAttachments = (data) => {
        const attachments = [];

        itemsWp
            .filter((a) => a.checkbox === 'Lampiran')
            .forEach((value) => {
                const itemName = 'item_' + value.item_id;
                const file = data[itemName];

                if (file?.fileList.length > 0) {
                    attachments.push({ file: file.file, item_id: itemName });
                }
            });


        return attachments;
    };

    const getEmployeeTalentaByBranch = async (id) => {
        try {
            const { data: employees } = await API.getEmployeeTalentaByBranch(HeaderFarms(null), id);

            return employees
        } catch (error) {

            console.error("Error in getEmployeeTalentaByBranch:", error);
        }
    }

    function RequestToken() {

        Block.hourglass('.ant-collapse');

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                API.getWorkingItems(HeaderFarms(responseToken.accessToken), null).then(_ => {

                    const token = responseToken.accessToken;

                    if (!user) {
                        GetUser(token)
                    } else {
                        getUsersAutoComplete(token)
                    }

                    if (Location.length === 0) {
                        getResposibleArea(token)
                    }

                    getWorkingItems(token)

                    Block.remove('.ant-collapse');
                }).catch(err => {
                    Block.remove('.ant-collapse');
                    console.error(err)
                    Report.warning(
                        "Oops.. something wrong",
                        "Sorry, this application is experiencing problems",
                        "Okay",
                    );
                })
            })
            .catch((e) => {
            });
    }

    const getWorkingItems = (token) => {
        API.getWorkingItems(HeaderFarms(token), null).then(res => {
            setItemsWp(res.data)
        }).catch(err => {
            Block.remove('.ant-collapse');
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const getResposibleArea = (token) => {
        API.ResponsibleArea(HeaderFarms(token), null)
            .then(res => {
                // Menyaring data berdasarkan company code
                const filteredData = res.data.filter(area => user?.company.code === area.company_code);
                // Menggunakan objek Set untuk membuat lokasi menjadi unik
                const uniqueLocations = [...new Set(filteredData.map(area => area.location))];
                // Menggunakan setLocations untuk menetapkan nilai lokasi
                setLocation(uniqueLocations);

            })
            .catch(err => {
                Block.remove('.ant-collapse');
                console.error(err);
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
            });
    }

    const GetUser = async (token) => {

        try {

            const { employee: { data: { employment } } }
                = await API.getEmployeeTalentaByEmployeeId(HeaderFarms(null), JSON.parse(localStorage.getItem('session_signin')).nik);

            const res = await API.getUserCredential(HeaderFarms(token), { access_token: token });

            if (employment.manager || employment.organization_id) {
                form.setFieldsValue({
                    allowed_employee_name: res.fullname,
                    department_name: employment.organization,
                });
            } else {

                form.setFieldsValue({
                    allowed_employee_name: res.fullname,
                });

                Report.info(
                    "Kepala satkes / Departemen kosong",
                    "Mohon hubungi tim HR / IT terkait ini",
                    "Okay",
                    () => {
                        navigate('/wp/history');
                    }
                );
            }

            setEmployeeBranch(employment.branch_id);
            setEmployeeDepartment(employment.organization_id)
            setUser(res);
        } catch (err) {
            console.error(err, "Verification");
        }
    };


    const handleJobType = (a) => {
        setJobType(a.target.labels[0].innerText);

        form.setFieldsValue({
            job_preparation: null,
            other_safety_equipment: null,
            general_preparation: null,
            job_type: "Pekerjaan " + a.target.labels[0].innerText
        });


        const formValues = form.getFieldsValue();
        const attachmentsToRemove = {};

        for (const key in formValues) {
            if (formValues.hasOwnProperty(key)) {
                if (formValues[key]?.file instanceof File) {
                    attachmentsToRemove[key] = null;
                }
            }
        }

        form.setFieldsValue(attachmentsToRemove);
    };

    const handleFileRemove = async (e) => {
        form.setFieldsValue({ [e]: null });
    };

    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    const getUsersAutoComplete = async () => {

        try {
            // Get branch DPM, BRM for default
            let employeesByBranch = await getEmployeeTalentaByBranch(employeeBranch);
            let AllSatkers = []

            employeesByBranch.forEach(employee => {
                if (employee.employment.organization_id && employee.employment.organization_name) {
                    if (parseInt(employee.employment.organization_id) === employeeDepartment) {
                        AllSatkers.push({
                            value: `${employee.personal.first_name} ${employee.personal.last_name ? employee.personal.last_name : ''}`,
                            label: `${employee.personal.first_name} ${employee.personal.last_name ? employee.personal.last_name : ''}`,
                            detail: employee,
                        });
                    }
                }
            });

            setWorkSpv(AllSatkers)
            setSatker(AllSatkers)
        } catch (err) {
            console.log(err)
            message.warning('Terjadi Kesalahan saat load data user', err.toString())
        }
    }

    useEffect(() => {

        if (accounts.length > 0) {
            RequestToken()
        }

        form.setFieldsValue({ emergency_response_team_number: "(Yoom) 62812-4036-7711 dan (Basri) 62812-4511-1651" })

    }, [user])

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Working Permit"
                navigate="/wp/history"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    scrollToFirstError={true}
                    name="service_request_form"
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='middle'
                    layout='vertical'
                >
                    <div className="container p-3" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Personal Info</b>} key="1">
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                <Form.Item
                                                    label="Nama Karyawan"
                                                    name="allowed_employee_name"
                                                    rules={[{ required: true, message: 'Nama Karyawan Wajib Isi' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                {
                                                    satkers.length > 0 ? (
                                                        <>
                                                            <Alert
                                                                message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Pastikan Kepala SATKER tidak Cuti</span>}
                                                                description={<span style={{ fontSize: '12px' }}> Mohon pastikan <b> kepala satuan kerja tidak cuti </b> dan sudah di informasikan ke Kepala Satker sebelum membuat permit</span>}
                                                                type="error"
                                                                className="mb-3"

                                                            />
                                                            <Form.Item
                                                                label="Kepala Satuan Kerja"
                                                                name="direct_supervisor"
                                                                rules={[{ required: true, message: 'Satuan Kerja Wajib Isi' }]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    placeholder="Pilih Satuan Kerja"
                                                                >
                                                                    {
                                                                        satkers
                                                                            .slice() // Membuat salinan array untuk menghindari pengaruh pada array asli
                                                                            // .sort((a, b) => b.detail.level.number - a.detail.level.number) // Mengurutkan dari atas ke bawah berdasarkan level.number
                                                                            .map((satker) => (
                                                                                <Select.Option key={satker.detail.personal.barcode} value={satker.detail.personal.barcode}>
                                                                                    {satker.label}
                                                                                </Select.Option>
                                                                            ))
                                                                    }
                                                                </Select>

                                                            </Form.Item>
                                                        </>
                                                    ) : (<center>Mohon tunggu.. <Spin className='p-3' /></center>)
                                                }
                                            </Col>

                                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                <Form.Item
                                                    label="Departemen"
                                                    name="department_name"
                                                    rules={[{ required: true, message: 'Departemen Wajib Isi' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Umum</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Jenis Izin Pekerjaan Berbahaya"
                                                    name="permit_type"
                                                    rules={[{ required: true, message: 'Jenis Izin Wajib di isi' }]}
                                                    onChange={(event) =>
                                                        handleJobType(event)
                                                    }
                                                >
                                                    <Radio.Group name='permit-type'>
                                                        {[
                                                            "Hot Space",
                                                            "Limited Work",
                                                            "Work at height",
                                                            "Electric Work",
                                                            "Excavation",
                                                            "Crane Lifting / Operation"
                                                        ].map((value, index) => (
                                                            <Radio key={value} value={value} style={{ marginBottom: 10 }}>{[
                                                                "Panas",
                                                                "Ruang Terbatas",
                                                                "Ketinggian",
                                                                "Kelistrikan",
                                                                "Penggalian",
                                                                "Pengangkatan ( Crane Operation )"
                                                            ][index]}</Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                                                <Form.Item
                                                    label="Pelaksana"
                                                    name="executor"
                                                    rules={[{ required: true, message: 'Pelaksana Wajib di isi' }]}
                                                >
                                                    <Checkbox.Group name='permit-type'>
                                                        {[
                                                            { "value": "PT CPM", "label": "PT CPM" },
                                                            { "value": "PT BRM", "label": "PT BRM" },
                                                            { "value": "PT AKM", "label": "PT AKM" },
                                                            { "value": "PT SSS", "label": "PT SSS" },
                                                            { "value": "PT ETI", "label": "PT ETI" },
                                                            { "value": "PT AED", "label": "PT AED" },
                                                            { "value": "PT PBU", "label": "PT PBU" },
                                                            { "value": "PT PPU", "label": "PT PPU" },
                                                            { "value": "PT TRISULA", "label": "PT TRISULA" },
                                                            { "value": "PT LPM", "label": "PT LPM" },
                                                            { "value": "Magang/Intership", "label": "Magang/Intership" },
                                                            { "value": "Visitor/Tamu", "label": "Visitor/Tamu" },
                                                            { "value": "Lainnya", "label": "Lainnya" },
                                                        ].map((value, index) => (
                                                            <Checkbox key={value.value} value={value.value} style={{ marginBottom: 10, marginLeft: 10 }}>{value.value}</Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10} className="mb-3">
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Pengawas"
                                                    name="work_supervisor"
                                                    rules={[{ required: true, message: 'Pengawas Wajib di isi' }]}
                                                >
                                                    <AutoComplete
                                                        options={WorkSpv}
                                                        filterOption={(inputValue, option) => {
                                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        }
                                                    >
                                                        <Input.Search
                                                            size="middle"
                                                            placeholder="Reza Muhammad"
                                                            allowClear

                                                        />
                                                    </AutoComplete>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {
                                            jobType === 'Penggalian' && (
                                                <Row gutter={10} className="mb-1">
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Nama Operator Alat Berat"
                                                            name="heavy_equipment_operator_name"
                                                            rules={[
                                                                { required: true, message: 'Wajib di isi' },
                                                                { min: 10, message: 'Minimal 10 karakter' },
                                                                { max: 180, message: 'Maksimal 180 karakter' },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Nama Pengawas Alat"
                                                            name="heavy_equipment_supervisor_name"
                                                            rules={[
                                                                { required: true, message: 'Wajib di isi' },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Nomor Registrasi Alat Berat"
                                                            name="heavy_equipment_registration_number"
                                                            rules={[
                                                                { required: true, message: 'Wajib di isi' },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Nomor SIM Perusahaan"
                                                            name="company_driving_licenses"
                                                            rules={[
                                                                { required: true, message: 'Wajib di isi' },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Jenis Pekerjaan"
                                                    name="job_type"
                                                    rules={[
                                                        { required: true, message: 'Jenis Pekerjaan anda wajib di isi' },
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={3} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Uraian Kegiatan"
                                                    name="activity_description"
                                                    rules={[
                                                        { required: true, message: 'Uraian Kegiatan anda wajib di isi' },
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={3} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Lokasi Pekerjaan ( Area )"
                                                    name="job_location"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                            type: 'array',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        size="middle"
                                                        placeholder="Select"
                                                        allowClear
                                                        mode="multiple"
                                                        value={selectedLocations}
                                                        onChange={handleLocationChange}
                                                        showSearch
                                                        optionFilterProp="children"
                                                    >
                                                        {Location.map((item) => (
                                                            <Select.Option key={item} value={item}>
                                                                {item}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="job_location_add" style={{ marginTop: -20 }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <Input
                                                            value={inputValue}
                                                            onChange={(e) => handleInputChange(e.target.value)}
                                                            placeholder="Tambahkan lokasi Lain"
                                                            size="small"
                                                        />
                                                        <Button
                                                            type="primary"
                                                            size="small"
                                                            onClick={handleAddLocation}
                                                            style={{ marginLeft: 8 }}
                                                        >
                                                            Tambah
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Perlengkapan (APD) yang diperlukan"
                                                    name="required_personal_protective_equipment"
                                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        showSearch  // Tambahkan ini
                                                        placeholder="Pilih perlengkapan (APD)"
                                                        optionFilterProp="children" // Aktifkan pencarian pada opsi
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >

                                                        {itemsWp.filter(a => a.checkbox === 'Perlengkapan (APD) yang diperlukan' && a.type === 'Pekerjaan Berbahaya').map((value) => (
                                                            <Select.Option key={value.item_id} value={"item_" + value.item_id}>
                                                                {value.value}
                                                            </Select.Option>
                                                        ))}

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Alat Keselamatan Lainnya"
                                                    name="other_safety_equipment"
                                                    rules={[
                                                        { required: false, message: 'wajib di isi' },
                                                    ]}
                                                >
                                                    <Checkbox.Group name='permit-type mt-3'>
                                                        {itemsWp.filter(a => a.checkbox === 'Alat Keselamatan Lainnya' && a.type === 'Pekerjaan Berbahaya').map((value) => (
                                                            <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, }}>{value.value}</Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>

                                {
                                    form.getFieldValue('permit_type') === "Crane Lifting / Operation" && (
                                        <>
                                            <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Crane</b>} key="1">
                                                    <Row gutter={10} className="mb-1">
                                                        <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                            <Form.Item
                                                                label="Nama Operator Crane"
                                                                name="crane_operator_name"
                                                                rules={[{ required: true, message: 'Nama Operator Crane Wajib Isi' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                            <Form.Item
                                                                label="Nama Rigger"
                                                                name="rigger_name"
                                                                rules={[{ required: true, message: 'Nama Rigger Operator Crane Wajib Isi' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                            <Form.Item
                                                                label="No. Registrasi"
                                                                name="registration_number"
                                                                rules={[{ required: true, message: 'No. Registrasi Operator Crane Wajib Isi' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10} className="mb-1">
                                                        <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                            <Form.Item
                                                                label="Jenis Crane"
                                                                name="crane_type"
                                                                rules={[{ required: true, message: 'Jenis Crane Wajib Isi' }]}
                                                            >
                                                                <AutoComplete
                                                                    dataSource={['Crane Mobile']}
                                                                    placeholder="Pilih jenis crane"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                            <Form.Item
                                                                label="Kapasitas Crane"
                                                                name="crane_capacity"
                                                                rules={[{ required: true, message: 'Kapasitas Crane Operator Crane Wajib Isi' }]}
                                                            >
                                                                <AutoComplete
                                                                    dataSource={['55 ton', '25 ton']}
                                                                    placeholder="Pilih kapasitas crane"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                            <Form.Item
                                                                label="Commissioning terakhir"
                                                                name="last_commissioning_date"
                                                                rules={[{ required: true, message: 'Commissioning terakhir Operator Crane Wajib Isi' }]}
                                                            >
                                                                <DatePicker
                                                                    format="YYYY-MM-DD"
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Panel>
                                            </Collapse>
                                            <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                <Panel style={{ background: "#fce8b6" }} header={<b>Identifikasi Pekerjaan Pengangkatan</b>} key="1">
                                                    <Row>
                                                        <Col>
                                                            <Alert
                                                                message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Note</span>}
                                                                description={<span style={{ fontSize: '12px' }}>*SWL=(total beban rigging dan barang yang diangkat : kapasitas angkat crane dalam ton) x 100% </span>}
                                                                type="info"
                                                                className="mb-3"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10} className="mb-1">
                                                        <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label="Total Beban Diangkat"
                                                                name="total_load_lifted"
                                                                rules={[{ required: true, message: 'Total Beban Diangkat Wajib Isi' }]}
                                                            >
                                                                <InputNumber step={0.1} addonAfter="ton" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label="Total Beban Rigging"
                                                                name="total_load_rigging"
                                                                rules={[{ required: true, message: 'Total Beban Rigging Wajib Isi' }]}
                                                            >
                                                                <InputNumber step={0.1} addonAfter="ton" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label="Panjang Boom"
                                                                name="boom_length"
                                                                rules={[{ required: true, message: 'Panjang Boom Rigging Wajib Isi' }]}
                                                            >
                                                                <InputNumber step={0.1} addonAfter="m" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10} className="mb-1">
                                                        <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label="Radius Kerja"
                                                                name="working_radius"
                                                                rules={[{ required: true, message: 'Radius Kerja Wajib Isi' }]}
                                                            >
                                                                <InputNumber step={0.1} addonAfter="m" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label="Perhitungan SWL*"
                                                                name="swl_calculation"
                                                                rules={[{ required: true, message: 'Perhitungan SWL* Wajib Isi' }]}
                                                            >
                                                                <InputNumber step={0.1} addonAfter="%" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10} className="mb-1">
                                                        <Form.Item
                                                            label=""
                                                            name="identification_job_lifting"
                                                            rules={[{ required: true, message: 'Wajib di isi' }]}
                                                        >
                                                            <Checkbox.Group name='permit-type'>
                                                                <>
                                                                    {itemsWp
                                                                        .filter((a) => a.checkbox === 'Identifikasi Pekerjaan Pengangkatan')
                                                                        .map((value) => (
                                                                            <Checkbox
                                                                                key={value.item_id}
                                                                                value={"item_" + value.item_id}
                                                                                style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                                                            >
                                                                                {value.value}
                                                                            </Checkbox>
                                                                        ))}
                                                                </>
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Row>
                                                </Panel>
                                            </Collapse>
                                            <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                <Panel style={{ background: "#fce8b6" }} header={<b>Identifikasi Area Kerja</b>} key="1">
                                                    <Row gutter={10} className="mb-1">
                                                        <Form.Item
                                                            label=""
                                                            name="identification_job_area"
                                                            rules={[{ required: true, message: 'Wajib di isi' }]}
                                                        >
                                                            <Checkbox.Group name='permit-type'>
                                                                <>
                                                                    {itemsWp
                                                                        .filter((a) => a.checkbox === 'Identifikasi Area Kerja')
                                                                        .map((value) => (
                                                                            <Checkbox
                                                                                key={value.item_id}
                                                                                value={"item_" + value.item_id}
                                                                                style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                                                            >
                                                                                {value.value}
                                                                            </Checkbox>
                                                                        ))}
                                                                </>
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Row>
                                                </Panel>
                                            </Collapse>
                                        </>
                                    )
                                }

                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Waktu Pelaksanaan</b>} key="1">
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                <Form.Item
                                                    label="Waktu Pelaksanaan"
                                                    name="execution_time"
                                                    rules={[
                                                        { required: true, message: 'Jam Pekerjaan Dimulai wajib diisi' },
                                                    ]}
                                                >
                                                    <DatePicker size='middle' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                <Form.Item
                                                    label="Jam Pekerjaan Dimulai (Pukul)"
                                                    name="job_start_time"
                                                    rules={[
                                                        { required: true, message: 'Jam Pekerjaan Dimulai wajib diisi' },
                                                    ]}
                                                >
                                                    <TimePicker format="HH:mm" size='middle' />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                <Form.Item
                                                    label="Jam Pekerjaan Selesai (Pukul)"
                                                    name="job_end_time"
                                                    rules={[
                                                        { required: true, message: 'Jam Pekerjaan Selesai wajib diisi' },
                                                    ]}
                                                >
                                                    <TimePicker format="HH:mm" size='middle' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Kondisi Cuaca & Pekerja</b>} key="1">
                                        <div className="mt-2">Pekerja yang Terlibat</div>
                                        <Row gutter={100} className="mt-3">
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.List name="workers" rules={[{ required: true, message: 'Wajib Di isi' }]}>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Space
                                                                    key={key}
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: "center"
                                                                    }}
                                                                    align="baseline"
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={name}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Mohon isi nama lengkap',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <AutoComplete
                                                                            options={WorkSpv}
                                                                            filterOption={(inputValue, option) => {
                                                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                            }
                                                                            }
                                                                        >
                                                                            <Input
                                                                                size="middle"
                                                                                placeholder="Reza Muhammad"
                                                                                style={{ width: 240 }}
                                                                            />
                                                                        </AutoComplete>
                                                                    </Form.Item>
                                                                    {fields.length > 0 && (
                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                    )}
                                                                </Space>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                    Add field
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>

                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label="Kondisi Cuaca"
                                                    name="weather_condition"
                                                    rules={[{ required: true, message: 'Wajib di isi' }]}
                                                >
                                                    <Input
                                                        size="middle"
                                                        placeholder="Cerah"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>

                                {
                                    form.getFieldValue('permit_type') !== "Crane Lifting / Operation" && (
                                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                            <Panel style={{ background: "#fce8b6" }} header={<b>Persiapan Pelaksanaan Pekerjaan</b>} key="1">
                                                <Row gutter={10} className="mb-1">
                                                    <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                        <Form.Item
                                                            label=""
                                                            name="job_preparation"
                                                            rules={[{ required: true, message: 'Wajib di isi' }]}
                                                        >
                                                            <Checkbox.Group name='permit-type'>
                                                                {itemsWp.filter(a => a.checkbox === 'Persiapan Pelaksanaan Pekerjaan' && a.type === 'Pekerjaan ' + jobType).map((value) => (
                                                                    <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}>{value.value}</Checkbox>
                                                                ))}
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                            </Panel>
                                        </Collapse>
                                    )
                                }

                                {
                                    ['Kelistrikan', 'Penggalian'].includes(jobType) && (
                                        <>
                                            {form.getFieldValue('permit_type') && (
                                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                    <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                                                        <Row gutter={10} className="mb-3">
                                                            <Alert
                                                                message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Pastikan Lampiran di <b>tanda tangan</b> !</span>}
                                                                description={<span style={{ fontSize: '12px' }}> Mohon pastikan Lampiran seperti JSEA/WI/SOP sudah di tanda tangan, karena administrasi wajib di penuhi.</span>}
                                                                type="error"
                                                                className="mb-4"

                                                            />
                                                            <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
                                                                <Button type="primary">
                                                                    <a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank" rel="noopener noreferrer">
                                                                        Klik ini untuk Konversi JPG ke PDF
                                                                    </a>
                                                                </Button>

                                                            </Col>
                                                        </Row>
                                                        <Row gutter={10} className="mb-1">
                                                            {
                                                                itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pekerjaan ' + jobType).map(value => {
                                                                    return (
                                                                        <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                                            <Form.Item
                                                                                key={value.item_id}
                                                                                label={value.value}
                                                                                name={"item_" + value.item_id}
                                                                                rules={[{ required: value.mandatory, message: 'Wajib di isi' }]}
                                                                            >
                                                                                <Upload
                                                                                    name={"item_" + value.item_id}
                                                                                    maxCount={1}
                                                                                    multiple
                                                                                    key={value.item_id}
                                                                                    onRemove={() => handleFileRemove("item_" + value.item_id)}
                                                                                    beforeUpload={(file) => {
                                                                                        // const isPDF = file.type === 'application/pdf';
                                                                                        // if (!isPDF) {
                                                                                        //     message.error('Hanya file PDF yang diizinkan!');
                                                                                        // }
                                                                                        return false;
                                                                                    }}

                                                                                    accept=".pdf,.jpeg,.png"
                                                                                >
                                                                                    <Button icon={<UploadOutlined />}>Unggah File PDF</Button>
                                                                                </Upload>

                                                                            </Form.Item>
                                                                        </Col>

                                                                    )
                                                                })
                                                            }
                                                        </Row>

                                                    </Panel>
                                                </Collapse>
                                            )}
                                        </>
                                    )
                                }

                                {
                                    ['Panas', 'Ketinggian', 'Ruang Terbatas'].includes(jobType) && (
                                        <>
                                            {form.getFieldValue('permit_type') && (
                                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                    <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                                                        <Row gutter={10} className="mb-4 text-center">
                                                            <Alert
                                                                message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Pastikan Lampiran di <b>tanda tangan</b> !</span>}
                                                                description={<span style={{ fontSize: '12px' }}> Mohon pastikan Lampiran seperti JSEA/WI/SOP sudah di tanda tangan, karena administrasi wajib di penuhi.</span>}
                                                                type="error"
                                                                className="mb-4"

                                                            />
                                                            <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
                                                                <Button type="primary">
                                                                    <a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank" rel="noopener noreferrer">
                                                                        Klik ini untuk Konversi JPG ke PDF
                                                                    </a>
                                                                </Button>

                                                            </Col>
                                                        </Row>
                                                        <Row gutter={10} className="mb-1">
                                                            {
                                                                itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pekerjaan Berbahaya').map(value => {
                                                                    return (
                                                                        <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                                            <Form.Item
                                                                                key={value.item_id}
                                                                                label={value.value}
                                                                                name={"item_" + value.item_id}
                                                                                rules={[{ required: value.mandatory, message: 'Wajib di isi' }]}
                                                                            >
                                                                                <Upload
                                                                                    name={"item_" + value.item_id}
                                                                                    maxCount={1}
                                                                                    key={value.item_id}
                                                                                    onRemove={() => handleFileRemove("item_" + value.item_id)}
                                                                                    beforeUpload={(file) => {
                                                                                        // const isPDF = file.type === 'application/pdf';
                                                                                        // if (!isPDF) {
                                                                                        //     message.error('Hanya file PDF yang diizinkan!');
                                                                                        // }
                                                                                        return false;
                                                                                    }}
                                                                                    multiple
                                                                                    accept=".pdf,.jpeg,.png"
                                                                                >
                                                                                    <Button icon={<UploadOutlined />}>Unggah File PDF</Button>
                                                                                </Upload>

                                                                            </Form.Item>
                                                                        </Col>

                                                                    )
                                                                })
                                                            }
                                                        </Row>

                                                    </Panel>
                                                </Collapse>
                                            )}
                                        </>
                                    )
                                }

                                {
                                    ['Pengangkatan ( Crane Operation )'].includes(jobType) && (
                                        <>
                                            {form.getFieldValue('permit_type') && (
                                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                    <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                                                        <Row gutter={10} className="mb-4 text-center">
                                                            <Alert
                                                                message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Pastikan Lampiran di <b>tanda tangan</b> !</span>}
                                                                description={<span style={{ fontSize: '12px' }}> Mohon pastikan Lampiran seperti JSEA/WI/SOP sudah di tanda tangan, karena administrasi wajib di penuhi.</span>}
                                                                type="error"
                                                                className="mb-4"

                                                            />
                                                            <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
                                                                <Button type="primary">
                                                                    <a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank" rel="noopener noreferrer">
                                                                        Klik ini untuk Konversi JPG ke PDF
                                                                    </a>
                                                                </Button>

                                                            </Col>
                                                        </Row>
                                                        <Row gutter={10} className="mb-1">
                                                            {
                                                                itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pengangkatan / Pengoprasian Crane').map(value => {
                                                                    return (
                                                                        <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                                            <Form.Item
                                                                                key={value.item_id}
                                                                                label={value.value}
                                                                                name={"item_" + value.item_id}
                                                                                rules={[{ required: value.mandatory, message: 'Wajib di isi' }]}
                                                                            >
                                                                                <Upload
                                                                                    name={"item_" + value.item_id}
                                                                                    maxCount={1}
                                                                                    key={value.item_id}
                                                                                    onRemove={() => handleFileRemove("item_" + value.item_id)}
                                                                                    beforeUpload={(file) => {
                                                                                        // const isPDF = file.type === 'application/pdf';
                                                                                        // if (!isPDF) {
                                                                                        //     message.error('Hanya file PDF yang diizinkan!');
                                                                                        // }
                                                                                        return false;
                                                                                    }}
                                                                                    multiple
                                                                                    accept=".pdf,.jpeg,.png"
                                                                                >
                                                                                    <Button icon={<UploadOutlined />}>Unggah File PDF</Button>
                                                                                </Upload>

                                                                            </Form.Item>
                                                                        </Col>

                                                                    )
                                                                })
                                                            }
                                                        </Row>

                                                    </Panel>
                                                </Collapse>
                                            )}
                                        </>
                                    )
                                }

                                {
                                    form.getFieldValue('permit_type') !== "Crane Lifting / Operation" && (
                                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                            <Panel style={{ background: "#fce8b6" }} header={<b>Persiapan Umum</b>} key="1">
                                                <Row gutter={10} className="mb-1">
                                                    <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                        <Form.Item
                                                            label=""
                                                            name="general_preparation"
                                                            rules={[{ required: true, message: 'Wajib di isi' }]}
                                                        >
                                                            <Checkbox.Group name='permit-type'>
                                                                {jobType && (
                                                                    <>
                                                                        {itemsWp
                                                                            .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === 'Pekerjaan ' + jobType)
                                                                            .map((value) => (
                                                                                <Checkbox
                                                                                    key={value.item_id}
                                                                                    value={"item_" + value.item_id}
                                                                                    style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                                                                >
                                                                                    {value.value}
                                                                                </Checkbox>
                                                                            ))}

                                                                        {itemsWp
                                                                            .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === 'Pekerjaan ' + jobType)
                                                                            .length === 0 && (
                                                                                <>
                                                                                    {itemsWp
                                                                                        .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === 'Pekerjaan Berbahaya')
                                                                                        .map((value) => (
                                                                                            <Checkbox
                                                                                                key={value.item_id}
                                                                                                value={"item_" + value.item_id}
                                                                                                style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                                                                            >
                                                                                                {value.value}
                                                                                            </Checkbox>
                                                                                        ))}
                                                                                </>
                                                                            )}
                                                                    </>
                                                                )}

                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                            </Panel>
                                        </Collapse>

                                    )
                                }

                                {
                                    ['Ruang Terbatas'].includes(jobType) && (
                                        <>
                                            <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                                <Panel style={{ background: "#fce8b6" }} header={<b>Pengukuran Gas ( Optional )</b>} key="1">
                                                    <Row gutter={10}>
                                                        <Col xs={{ span: 23 }} sm={{ span: 4 }} xl={{ span: 4 }}>
                                                            <Form.Item
                                                                label="O2 ( 19,5% - 23,5% )"
                                                                name="o2"
                                                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                                                            >
                                                                <Input placeholder="" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label="H2S ( < 10 ppm ) "
                                                                name="h2s"
                                                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                                                            >
                                                                <Input placeholder="" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label="CO ( < 30 ppm )"
                                                                name="co"
                                                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label="HCN ( < 4,7 ppm )"
                                                                name="hcn"
                                                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                                                            >
                                                                <Input placeholder="" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Panel>
                                            </Collapse>
                                        </>
                                    )
                                }
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Tambahan ( Optional )</b>} key="1">
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label="No. Tim Tanggap Darurat"
                                                    name="emergency_response_team_number"
                                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                                >
                                                    <TextArea rows={"3"} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label="No. Safety Officer"
                                                    name="safety_officer_number"
                                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label="Catatan"
                                                    name="notes"
                                                    rules={[
                                                        { required: true },
                                                        { max: 200, message: 'Maksimal 200 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={3} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <Row className='justify-content-center'>
                        <Col>
                            <Form.Item>
                                <Button type="primary" size="middle" htmlType="submit" loading={isSubmitting}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2023 Information Technology</Layout.Footer>
        </>
    );
}

export default CreateWorkingPermit