import React, { Fragment, useEffect, useState } from "react";
import { Affix, Button, Form, Input, Modal } from "antd";
import { useNavigate, useParams } from "react-router";
import { useIsAuthenticated } from "@azure/msal-react";

import Header from "../../../utils/header_osticket";
import { Loading, Report } from "notiflix";
import Navbar from "../../../atoms/navbar";
import parse from "html-react-parser";
import API from "../../../services";

import "./styles.css";
import Footer from "../../../atoms/footer";

function HistoryTicketDetail() {
  const [active, setActive] = useState(1);
  const [ticket, setTicket] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  let navigate = useNavigate();

  let { id } = useParams();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    Loading.pulse("Just a Moment");

    let data = {
      ticketNumber: ticket.ticket_number,
      username: ticket.user_name,
      message: values.message,
    };

    API.ReplyFromUser(Header(), data)
      .then((res) => {
        Loading.remove();
        Report.success(
          `Reply Success`,
          `Your reply to support request has been noted`,
          "Okay",
          function () {
            window.location.reload();
          }
        );
      })
      .catch((err) => {
        Loading.remove();
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  };

  function activeFunc(index) {
    setActive(index);
  }

  function getTicketInfo(id) {
    API.TicketInfo(Header(), id)
      .then((res) => {
        setTicket(res.ticket);
        Loading.remove();
      })
      .catch((err) => {
        Loading.remove();
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
        navigate(`/`);
      });
  }

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      navigate('/')
    }

    Loading.pulse("Just a Moment");

    setTimeout(function () {
      getTicketInfo(id);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Navbar title="Ticket Detail" back={true} navigate="/help/history-ticket" subtitle="Help Center"></Navbar>
      <div className="container white-back-cr" style={{ minHeight: 500 }}>
        <div className="container my-3 p-3" style={{ width: "100%" }}>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="btn-menu">
                <div className="row d-flex" style={{ height: 50 }}>
                  <div
                    onClick={() => activeFunc(1)}
                    className="col-md-6 col-sm-6 w-50 align-self-center btn-tab"
                  >
                    <div
                      className={
                        active === 1
                          ? "active d-flex justify-content-center"
                          : "d-flex justify-content-center"
                      }
                    >
                      <span className="fs-7 align-self-center">Detail</span>
                    </div>
                  </div>
                  <div
                    onClick={() => activeFunc(2)}
                    className="col-md-6 col-sm-6 w-50 align-self-center btn-tab"
                  >
                    <div
                      className={
                        active === 2
                          ? "active d-flex justify-content-center"
                          : "d-flex justify-content-center"
                      }
                    >
                      <span className="fs-7 align-self-center">Feedback</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {active === 1 ? (
                <div className="background-checkin p-4">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <p className="fs-6 fw-bold">Ticket Information</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fs-7 text-muted mb-2">Email</div>
                      <p className="fs-7" style={{ fontSize: 12 }}>
                        {ticket ? ticket.user_email.email : ''}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Ticket Number</div>
                      <p className="fs-7" style={{ fontSize: 12 }}>
                        {ticket ? ticket.ticket_number : ""}
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Help Topic</div>
                      <p className="fs-7" style={{ fontSize: 12 }}>{ticket ? ticket.help_topic : ""}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Full Name</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>{ticket ? ticket.user_name : ""}</p>
                    </div>
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Phone Number</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>{ticket ? ticket.user_phone : ""}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 w-50">
                      <div className="fs-7 text-muted mb-2">Issue Summary</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>{ticket ? ticket.subject : ""}</p>
                    </div>
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Handler</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>
                        {ticket
                          ? ticket.assigned_to.length > 0
                            ? ticket.assigned_to[0].name
                            : "Not Handle"
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Ticket Status</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>
                        {ticket ? ticket.ticket_status : ""}
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Close Time</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>
                        {ticket ? ticket.close_timestamp : "Still Open"}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Created Ticket</div>
                      <p className="fs-7 " style={{ fontSize: 12 }}>
                        {ticket ? ticket.create_timestamp : ""}
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 w-50">
                      <div className="fs-7 text-muted mb-2">Due Date</div>
                      <p className="fs-7  text-danger" style={{ fontSize: 12 }}>
                        {ticket ? ticket.due_timestamp : ""}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <div className=" p-4 mb-3">
                    {ticket.thread_entries.map((data, index) => {
                      if (data.user_id) {
                        return (
                          <div class="card mt-2">
                            <div class="card-header">You</div>
                            <div class="card-body p-4">
                              <p class="card-text">{parse(data.body)}</p>
                            </div>
                            <div class="card-footer">
                              <small class="text-muted">
                                Posted {data.created}
                              </small>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div class="card mt-2 border-warning">
                            <div class="card-header">
                              <span className="fs-7">{data.poster}</span>
                            </div>
                            <div class="card-body p-4">
                              <p class="card-text"><span className="fs-7" dangerouslySetInnerHTML={{ __html: data.body }} /></p>
                            </div>
                            <div class="card-footer">
                              <small class="text-muted">
                                posted {data.created}
                              </small>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="row">
                    <Affix offsetBottom={10}>
                      <div className="col-md-12 d-flex justify-content-center">
                        <Button type="primary" onClick={showModal} size="large">
                          Reply Feedback
                        </Button>
                      </div>
                    </Affix>
                  </div>
                  <Modal
                    title="Post a Reply"
                    footer={null}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <Form
                      name="basic"
                      wrapperCol={{ span: 24 }}
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <Form.Item
                        label="To best assist you, we request that you be specific and detailed"
                        name="message"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Message!",
                          },
                        ]}
                      >
                        <Input.TextArea
                          showCount
                          style={{ height: "150px", width: "100%" }}
                          minLength={50}
                        />
                      </Form.Item>
                      <Form.Item
                        className="mt-3"
                        wrapperCol={{
                          xs: {
                            offset: 8, span: 18
                          },
                          sm: {
                            offset: 7, span: 18
                          },
                          lg: { offset: 8, span: 18 }
                        }}

                      >
                        <Button type="primary" htmlType="submit">
                          Send Message
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}

export default HistoryTicketDetail;
