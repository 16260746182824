import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import API from "../../../services";
import moment from "moment";

import { Loading, Report } from "notiflix";
import { Typography, Form } from "antd";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import HeaderFarms from "../../../utils/header_farms";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';


function ViewTSPrint(props) {

    const { instance, accounts } = useMsal();
    const [, setCurrentUnit] = useState(null);
    const [dateList, setDateList] = useState([]);
    const [, setCaption] = useState({});

    const [, setTotalWeek] = useState(0);
    const [, SetTotalSick] = useState(0);
    const [, SetTotalLeave] = useState(0);
    const [, setTotalWeekOnSite] = useState(0);

    const [, setIdApprovalList] = useState(null);
    const [tsheader, setTsHeader] = useState(null)

    const isAuthenticated = useIsAuthenticated();
    const { Title } = Typography;

    let { id } = useParams();

    const getDetailTS = (token) => {
        API.viewTS(HeaderFarms(token), id).then((res => {
            loopDateFromAPI(res.data)
            setTsHeader(res.data)

            setCurrentUnit(res.data.uid.company.code)
            setIdApprovalList(res.data.approval_id_list)

            setTimeout(() => {
                window.print();
            }, 1000)


        })).catch((err) => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
            Loading.remove();
        })
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }


    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            getDetailTS(response.accessToken)
        }).catch((e) => {
            handleLogin(instance)
            console.error(e)
            Loading.remove();
        });
    }

    const loopDateFromAPI = (data) => {

        let DTO = [];

        let dataApi = data.details_old.length > 0 ? data.details_old : data.details

        dataApi.forEach((item, index) => {

            DTO.push({
                ts_row_id_dtl: item.ts_row_id_dtl,
                ts_date_dtl: item.ts_date_dtl,
                ts_loc_dtl: item.ts_loc_dtl ? index + '_' + item.ts_loc_dtl : null, //must null for counter
                ts_reason_dtl: item.ts_reason_dtl ? index + '_' + item.ts_reason_dtl : null,
                ts_note_dtl: item.ts_note_dtl, //must null for counter
            })

        })

        setDateList(DTO);
        setTotalWeek(data.total_work)
        setTotalWeekOnSite(data.total_site)
        SetTotalLeave(data.total_leave)
        SetTotalSick(data.total_sick)

        // for caption
        setCaption({ startDate: data.date_from, endDate: data.date_to });
        Loading.remove();
    };

    useEffect(() => {
        // Check already login or not ? 
        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <>
            <div className="container white-back-cr">
                <div className="container">
                    <div className="row">
                        {
                            tsheader && (
                                <div className="col-md-5 text-center mb-2">
                                    <Title level={5}>Attendance Sheet</Title>
                                </div>
                            )
                        }
                        <div className="col-md-10 mb-2">
                            {
                                tsheader && (
                                    <>
                                        <table>
                                            <tr>
                                                <td style={{ fontSize: 10 }}>
                                                    Name :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.uid.fullname}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Period :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {moment(tsheader.date_from).format(
                                                            "YYYY MMMM DD"
                                                        )} -  {moment(tsheader.date_to).format(
                                                            "YYYY MMMM DD"
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: 10 }}>
                                                    Document :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.form_submit_id}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Approver :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.approval_process.detail[0].approved_by.fullname}
                                                    </b>
                                                </td>
                                            </tr>
                                        </table>
                                    </>
                                )
                            }

                        </div>
                        <div className="col-md-12">
                            <Form size="small" onFinish={null}>
                                <div className="">
                                    {dateList && (
                                        <table >
                                            <thead className="print">
                                                <tr>
                                                    <th rowSpan={2} style={{ verticalAlign: "middle", textAlign: "center" }}>Date</th>
                                                    <th colSpan={3} style={{ textAlign: "center" }}>Presence</th>
                                                    <th rowSpan={1} colSpan={4} style={{ verticalAlign: "middle", textAlign: "center" }}>Absence</th>
                                                    <th rowSpan={2} colSpan={2} style={{ verticalAlign: "middle", textAlign: "center" }}>Note</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>BRM</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>CPM</th>
                                                    {/* <th style={{ verticalAlign: "middle", textAlign: "center" }}>GMI</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>LMR</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>DPM</th> */}
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>Other</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>BUSINESSTRIP</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>Leave</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>Sick</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center" }}>Weekend</th>
                                                </tr>
                                            </thead>
                                            <tbody className="print">
                                                {
                                                    dateList && (
                                                        dateList.map((item, i) => {
                                                            return (
                                                                <tr

                                                                >
                                                                    <td style={{ minWidth: 100, fontWeight: "bold", verticalAlign: "middle", textAlign: "center" }}>{moment(item.ts_date_dtl).format("MMM - DD")}</td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_loc_dtl === i + '_BRM' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_loc_dtl === i + '_CPM' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    {/* <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_loc_dtl === i + '_GMI' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_loc_dtl === i + '_LMR' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_loc_dtl === i + '_DPM' ? 'V' : null
                                                                        }
                                                                    </td> */}
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_loc_dtl === i + '_OTHER' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_reason_dtl === i + '_BUSINESSTRIP' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_reason_dtl === i + '_LEAVE' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_reason_dtl === i + '_SICK' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_reason_dtl === i + '_HOLIDAY' ? 'V' : null
                                                                        }
                                                                    </td>
                                                                    <td style={{ width: 200, minWidth: 150, verticalAlign: "middle", textAlign: "center", fontWeight: "bold" }}>
                                                                        {
                                                                            item.ts_note_dtl
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    )}


                                </div>
                            </Form>
                        </div>
                        <div className="col-md-12 mt-3">

                            {
                                tsheader && (
                                    <>
                                        <table>
                                            <tr>
                                                <td style={{ fontSize: 10 }}>
                                                    Total Presence:
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.total_work}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Home Base :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.total_home_base}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Site / Unit :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {
                                                            tsheader.total_site
                                                        }
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Permit :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.total_permit}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Leave :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.total_leave}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Sick :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.total_sick}
                                                    </b>
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    Day :
                                                </td>
                                                <td style={{ fontSize: 10 }}>
                                                    <b>
                                                        {tsheader.day_count}
                                                    </b>
                                                </td>
                                            </tr>
                                        </table>
                                    </>
                                )
                            }
                        </div>
                        <div className="col-md-5 mt-4 text-center">
                            Superior
                            <br />
                            <QRCodeSVG size={70} className="mb-2 mt-2" value={`${window.location.origin}/wf/approved/${tsheader && tsheader.approval_process.approval_key}`} />
                            <br />
                            {
                                tsheader && tsheader.approval_process.detail[0].approved_by.fullname
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const reduxState = (state) => ({
    getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewTSPrint);

