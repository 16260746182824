import { Form, Row, Col, Typography } from "antd";
import { useLocation, useParams } from "react-router";
import { Block, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import HeaderFarms from "../../../utils/header_farms";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";


function ViewWP() {

    let { id } = useParams();
    let location = useLocation();

    const [form] = Form.useForm();
    const { Title } = Typography;

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    const [completeLoadWP, setcompleteLoadWP] = useState(false)
    const [itemsWp, setItemsWp] = useState([]);

    const getDetailPermit = async (token) => {
        try {
            const res = await API.viewWorkingPermit(HeaderFarms(token), id);

            Block.remove('.ant-collapse');

            res.data.job_start_time = formatTimeToHHMM(new Date(res.data.job_start_time));
            res.data.job_end_time = formatTimeToHHMM(new Date(res.data.job_end_time));

            const checkAndPush = (element, data, targetArray) => {
                if (
                    element.checkbox === data &&
                    res.data[`item_${element.item_id}`] === true &&
                    !targetArray.includes(`item_${element.item_id}`)
                ) {
                    targetArray.push(`item_${element.item_id}`);
                }
            };

            const required_personal_protective_equipment = [];
            const other_safety_equipment = [];
            const general_preparation = [];
            const job_preparation = [];
            const complete_work = []

            for (const element of itemsWp) {
                checkAndPush(element, 'Perlengkapan (APD) yang diperlukan', required_personal_protective_equipment);
                checkAndPush(element, 'Alat Keselamatan Lainnya', other_safety_equipment);
                checkAndPush(element, 'Persiapan Pelaksanaan Pekerjaan', job_preparation);
                checkAndPush(element, 'Persiapan Umum', general_preparation);
                checkAndPush(element, 'Pekerjaan Selesai', complete_work);
            }

            let editJobLocation = res.data.job_location?.split(',')
            let workers = res.data.workers?.split(',')

            const formValues = {
                ...res.data,
                required_personal_protective_equipment,
                other_safety_equipment,
                general_preparation,
                job_preparation,
                complete_work
            };

            formValues.job_location = editJobLocation
            formValues.workers = workers

            formValues.execution_time = formatDateForInput(formValues.execution_time)

            form.setFieldsValue(formValues);
            setcompleteLoadWP(res.data);
        } catch (err) {
            console.error(err);
            Report.info("Working Permit", "Sorry, Working Permit not found", "Okay");
            Block.remove('.ant-collapse');
        }
    };

    async function getWorkingItems(token) {
        try {
            const res = await API.getWorkingItems(HeaderFarms(token), null);

            setItemsWp(res.data);
        } catch (err) {
            Block.remove('.ant-collapse');
            console.error(err);
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        }
    }

    function formatDateForInput(originalDateString) {
        const originalDate = new Date(originalDateString);
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because it's zero-based
        const day = originalDate.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    function RequestToken() {

        Block.hourglass('.ant-collapse');

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            if (itemsWp.length === 0) {
                getWorkingItems(response.accessToken);

            } else {
                getDetailPermit(response.accessToken);

            }

        }).catch((e) => {
            handleLogin(instance)
            console.error(e)
        });
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then(() => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function formatTimeToHHMM(dateTime) {
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${formattedHours}:${formattedMinutes}`;
    }

    function GeneralPreparationValueToLabel(value) {
        const valueToLabel = {
            "Hot Space": "Pekerjaan Berbahaya",
            "Limited Work": "Pekerjaan Berbahaya",
            "Work at height": "Pekerjaan Berbahaya",
            "Electric Work": "Pekerjaan Kelistrikan",
            "Excavation": "Pekerjaan Penggalian",
        };

        return valueToLabel[value] || value;
    }

    function preparationValueToLabel(value, preparationType) {
        const valueToLabel = {
            "Hot Space": preparationType === 'Job' ? "Pekerjaan Panas" : "Pekerjaan Berbahaya",
            "Limited Work": preparationType === 'Job' ? "Pekerjaan Ruang Terbatas" : "Pekerjaan Berbahaya",
            "Work at height": preparationType === 'Job' ? "Pekerjaan Ketinggian" : "Pekerjaan Berbahaya",
            "Electric Work": preparationType === 'Job' ? "Pekerjaan Kelistrikan" : "Pekerjaan Kelistrikan",
            "Excavation": preparationType === 'Job' ? "Pekerjaan Penggalian" : "Pekerjaan Penggalian",
        };

        return valueToLabel[value] || value;
    }

    useEffect(() => {
        // Check already login or not ? 
        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }

        const searchParams = new URLSearchParams(location.search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, itemsWp]);

    return (
        <>
            <div className="m-4 mt-3" style={{ alignSelf: "center" }}>
                <div className="row print">
                    <div className="fs-6 text-center mt-2" style={{ color: "#B7B7B7" }}>
                        <Row>
                            <Col span={4}>
                                <img className="mt-2" src="/static/media/brm-logo.16396c198e4934cc25ff.png" alt="Logo Perusahaan" width={63} />
                            </Col>
                            <Col span={16}>
                                <Title level={5}>
                                    PT Citra Palu Minerals
                                    <br />
                                    Izin Pekerjaan Berbahaya
                                </Title>
                            </Col>
                        </Row>
                    </div>

                    {completeLoadWP && (
                        <>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>ID Permit</th>
                                                <th>Status Pekerjaan</th>
                                                <th>Status Persetujuan</th>
                                                <th>Tanggal Pengajuan</th>
                                                <th>Pelaksanaan</th>
                                                <th>Waktu Mulai Pekerjaan</th>
                                                <th>Waktu Selesai Pekerjaan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{completeLoadWP.id_record}</td>
                                                <td>{completeLoadWP.status}</td>
                                                <td>{completeLoadWP.approval_process_id.status}</td>
                                                <td>{moment(completeLoadWP.created_at).format('LL')}</td>
                                                <td>{moment(completeLoadWP.execution_time).format("LL")}</td>
                                                <td>{completeLoadWP.job_start_time}</td>
                                                <td>{completeLoadWP.job_end_time}</td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className="col-sm-6">
                                    <p><b>Personal Info</b></p>
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Nama Karyawan</th>
                                                <th>Kepala Satuan Kerja</th>
                                                <th>Departemen</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{completeLoadWP.allowed_employee_name}</td>
                                                <td>{completeLoadWP.direct_supervisor}</td>
                                                <td>{completeLoadWP.department_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div><div className="col-sm-6">
                                    <p><b>Kondisi Cuaca & Pekerja</b></p>
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Pekerja yang Terlibat</th>
                                                <th>Cuaca</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{completeLoadWP.workers.split(",").join(' - ')}</td>
                                                <td>{completeLoadWP.weather_condition}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <p><b>Informasi Umum</b></p>
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Jenis Izin Pekerjaan Berbahaya</th>
                                                <th>Pelaksana</th>
                                                <th>Pengawas</th>
                                                <th>Jenis Pekerjaan</th>
                                                <th>Uraian Kegiatan</th>
                                                <th>Lokasi Pekerjaan</th>
                                                <th>Perlengkapan (APD) yang diperlukan</th>
                                                <th>Alat Keselamatan Lainnya</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{preparationValueToLabel(completeLoadWP.permit_type, 'Job')}</td>
                                                <td>{completeLoadWP.executor}</td>
                                                <td>{completeLoadWP.work_supervisor}</td>
                                                <td>{completeLoadWP.job_type}</td>
                                                <td>{completeLoadWP.activity_description}</td>
                                                <td>{completeLoadWP.job_location}</td>
                                                <td>
                                                    {itemsWp
                                                        .filter(a => a.checkbox === 'Perlengkapan (APD) yang diperlukan' && a.type === 'Pekerjaan Berbahaya')
                                                        .map((value) => (
                                                            completeLoadWP["item_" + value.item_id] && <> - {value.value}</>
                                                        ))
                                                    }
                                                </td>
                                                <td>
                                                    {itemsWp
                                                        .filter(a => a.checkbox === 'Alat Keselamatan Lainnya' && a.type === 'Pekerjaan Berbahaya')
                                                        .map((value) => (
                                                            completeLoadWP["item_" + value.item_id] && <> - {value.value}</>
                                                        ))
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {
                                ["Limited Work"].includes(completeLoadWP.permit_type) && (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p><b>Pengukuran Gas</b></p>
                                                <table className="table">
                                                    <thead style={{ backgroundColor: "#FAC20D" }}>
                                                        <tr>
                                                            <th>O2 ( 19,5% - 23,5% )</th>
                                                            <th>H2S ( Kurang Dari 10 ppm )</th>
                                                            <th>CO ( Kurang Dari 30 ppm )</th>
                                                            <th>HCN ( Kurang Dari 4,7 ppm )</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{completeLoadWP.o2}</td>
                                                            <td>{completeLoadWP.h2s}</td>
                                                            <td>{completeLoadWP.co}</td>
                                                            <td>{completeLoadWP.hcn}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            <div className="row">
                                <div className="col-sm-6">
                                    <p><b>Persiapan Pelaksanaan Pekerjaan</b></p>
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {itemsWp
                                                        .filter(a => a.checkbox === 'Persiapan Pelaksanaan Pekerjaan' && a.type === preparationValueToLabel(completeLoadWP.permit_type, 'Job'))
                                                        .map((value) => (
                                                            <span key={value.item_id}>
                                                                {completeLoadWP["item_" + value.item_id] ? <input type="checkbox" checked readOnly /> : '-'} {value.value}<br />
                                                            </span>
                                                        ))
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-6">
                                    <p><b>Persiapan Umum</b></p>
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {itemsWp
                                                        .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                                        .map((value) => (
                                                            <span key={value.item_id}>
                                                                {completeLoadWP["item_" + value.item_id] && <input type="checkbox" checked readOnly />} {value.value}<br />
                                                            </span>
                                                        ))}
                                                    {itemsWp
                                                        .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                                        .length === 0 && (
                                                            <>
                                                                {itemsWp
                                                                    .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                                                    .map((value) => (
                                                                        <span key={value.item_id}>
                                                                            {completeLoadWP["item_" + value.item_id] && <input type="checkbox" checked readOnly />} {value.value}<br />
                                                                        </span>
                                                                    ))}
                                                            </>
                                                        )}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <p><b>Informasi Tambahan</b></p>
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>No. Tim Tanggap Darurat</th>
                                                <th>No. Safety Officer</th>
                                                <th>Catatan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>(Yoom) 62812-4036-7711 dan (Basri) 62812-4511-1651</td>
                                                <td>{completeLoadWP.safety_officer_number}</td>
                                                <td> <div style={{ fontSize: 8 }} dangerouslySetInnerHTML={{ __html: completeLoadWP.notes }}></div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    completeLoadWP.status === 'Closed' && (<>
                                        <div className="col-sm-6">
                                            <p><b>Pekerjaan Selesai</b></p>
                                            <table className="table">
                                                <thead style={{ backgroundColor: "#FAC20D" }}>
                                                    <tr>
                                                        <th>Checking working location</th>
                                                        <th>Limbah yang dihasilkan</th>
                                                        <th>Catatan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {itemsWp
                                                                .filter((a) => a.checkbox === 'Pekerjaan Selesai' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                                                .map((value) => (
                                                                    <span key={value.item_id}>
                                                                        {completeLoadWP["item_" + value.item_id] && <input type="checkbox" checked readOnly />} {value.value}<br />
                                                                    </span>
                                                                ))}

                                                        </td>
                                                        <td>{completeLoadWP.waste_produced}</td>
                                                        <td>{completeLoadWP.job_completion_notes}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>)
                                }
                            </div>

                            {
                                completeLoadWP.permit_type === 'Excavation' && (<>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <table className="table">
                                                <thead style={{ backgroundColor: "#FAC20D" }}>
                                                    <tr>
                                                        <th>Nama Operator Alat Berat</th>
                                                        <th>Nama Pengawas Alat</th>
                                                        <th>Nomor Registrasi Alat Berat</th>
                                                        <th>Nomor SIM Perusahaan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{completeLoadWP.heavy_equipment_operator_name.trim() ? completeLoadWP.heavy_equipment_operator_name : "Tidak Menggunakan alat berat"}</td>
                                                        <td>{completeLoadWP.heavy_equipment_supervisor_name}</td>
                                                        <td>{completeLoadWP.heavy_equipment_registration_number}</td>
                                                        <td>{completeLoadWP.company_driving_licenses}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>)
                            }


                            {completeLoadWP && completeLoadWP.approval_process_id.approval_key && (
                                <>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table className="table text-center">
                                                <thead style={{ backgroundColor: "#FAC20D" }}>
                                                    <tr>
                                                        <th>Approval Barcode</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><QRCodeSVG size={100} style={{ border: 0, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${completeLoadWP.approval_process_id.approval_key}`} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                </div>

            </div>
        </>
    );
}

const reduxState = (state) => ({
    getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewWP);
