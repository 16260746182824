import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';

import { Button, Collapse, Descriptions, Form } from 'antd';
import { Block, Confirm, Report } from "notiflix";
import Navbar from '../../../atoms/navbar';

import HeaderFarms from '../../../utils/header_farms';
import { loginRequest } from "../../../config/api-config";
import API from '../../../services';

import numeral from "numeral";
import { QRCodeSVG } from "qrcode.react";

function ViewBillingTelkomsel() {

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  let navigate = useNavigate();
  const { Panel } = Collapse;
  let { id } = useParams();

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [view, setView] = useState(null);
  const [user, setUser] = useState(null);

  let number_row = 0

  const getViewBillingTelkomsel = (token) => {

    API.ViewBillingTelkomsel(HeaderFarms(token), id).then(res => {

      setView(res.data)
      styleStatus(res.data.process)

      Block.remove('.summary')
      Block.remove('.ant-collapse');
    }).catch(err => {
      console.error(err)
      if (err.response.status === 404) {
        Report.info(
          "Billing Telkomsel Not Found",
          err.response.data.error,
          "Okay"
        );
      } else {
        Report.info('Information', 'Mohon maaf, Terjadi kesalahan', 'ok')
      }

      Block.remove('.summary')
      Block.remove('.ant-collapse');
    })
  }

  function RequestToken() {

    Block.hourglass('.summary')
    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      getUser(response.accessToken)
      getViewBillingTelkomsel(response.accessToken)

    }).catch((e) => {
      console.error(e)
      Block.remove('.ant-collapse');
      Block.remove('.summary')
      handleLogin(instance)
    });

  }

  const styleStatus = (res) => {
    let status = res.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  const approved = () => {

    // get id approve detail process based on approver
    const getDetailProcess = view.process.detail.filter(item => item.approved_by._id === user.id)[0]._id

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      API.workflowApproved(HeaderFarms(response.accessToken), {
        approval_id_list: getDetailProcess,
      }).then((res) => {

        Report.success(
          `Approved Success`,
          `Billing Telkomsel berhasil di setujui, Terima Kasih`,
          "Okay",
          function () {
            return navigate('/wf/pending')
          })

        console.log(res)
      }).catch(err => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      })

    }).catch((err) => {
      console.error(err)
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    });
  }

  const submitApprove = () => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure will approved ?',
      'Yes',
      'No',
      () => {
        approved()
      },
    );
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
      setUser(user)
    } catch (error) {
      console.error(error)
    }
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((res) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  useEffect(() => {
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Billing Telkomsel"
        navigate="/home"
      ></Navbar>
      <div className="container white-back-cr" style={{ minHeight: 500 }}>
        <div className="container mt-3 p-3">
          <div className="row">
            <div className="col-md-10 mb-2">
              {
                view && (
                  <Collapse defaultActiveKey={['1']}>
                    <Panel header="Billing Telkomsel Information" key="1">
                      <Descriptions size="small" bordered title="Information" className="mb-4">
                        <Descriptions.Item label="Record">
                          {view.process.form_submit_id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Requestor">
                          {view.process.uid.fullname}
                        </Descriptions.Item>
                        <Descriptions.Item label="Approver">
                          {
                            // eslint-disable-next-line array-callback-return
                            view.process.detail.map(item => {
                              if (item.approved_by._id === user.id) {
                                return item.approved_by.fullname
                              }
                            })
                          }
                        </Descriptions.Item>
                        <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                          Waiting Approval
                        </Descriptions.Item>
                        <Descriptions.Item label="Submission Time">
                          November 11, 2022 6:47 PM
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions size="small" bordered title="Billing Recap" className="mb-4">
                        {
                          view.recap.total_be.map(item => {
                            return (
                              <Descriptions.Item label={item.be}>
                                {numeral(item.total).format('0,0')}
                              </Descriptions.Item>
                            )
                          })
                        }
                        <Descriptions.Item label="Total" style={{ fontWeight: "bold", backgroundColor: "rgb(197, 237, 237)" }}>
                          {numeral(view.recap.subTotal).format('0,0')}
                        </Descriptions.Item>

                      </Descriptions>
                    </Panel>

                  </Collapse>

                )

              }

            </div>
            {view && view.process.approval_key && (
              <>
                <div className="col-md-9 mb-2">
                  <Collapse defaultActiveKey={['1']}>
                    <Panel header="Approved Barcode" key="1">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <QRCodeSVG size={110} value={`https://farms.brmapps.com/wf/approved/${view.process.approval_key}`} />
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </>

            )}
            <div className="col-md-10">
              <Collapse defaultActiveKey={['1']}>
                <Panel header="Detail Transaction" key="1">
                  <div className="table-responsive summary" style={{ height: 400 }}>
                    <table className="table">
                      <thead style={{ position: "sticky", top: 0, backgroundColor: "rgb(197, 237, 237)" }}>
                        <tr>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>No</th>
                          {/* <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Prv</th> */}
                          {/* <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Invoice Number</th> */}
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Name</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>HP Number</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Roaming</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>LOCAL</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>SLJJ</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>SLI</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>SMS</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Paket Flash</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Amount</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>total / BU</th>
                          <th scope="col" style={{ fontWeight: "bold", fontSize: 11 }}>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          view && view.billing.map((item, index) => {

                            if (item.name) {
                              number_row += 1
                            }

                            return (
                              <tr style={item.name ? null : { backgroundColor: '#C4D79B', fontSize: 11 }}>
                                <td style={{ fontSize: 11 }}>{item.name ? number_row : null}</td>
                                {/* <td style={{ fontSize: 11 }}>{item.provider}</td> */}
                                {/* <td style={{ fontSize: 11 }}>{item.invoice_number}</td> */}
                                <td style={{ fontSize: 11 }}>{item.name ? item.name : item.business_entity}</td>
                                <td style={{ fontSize: 11 }}>{item.telp}</td>
                                <td style={{ fontSize: 11 }}>{item.international_roaming ? numeral(item.international_roaming).format('0,0') : '-'}</td>
                                <td style={{ fontSize: 11 }}>{item.calls_to_telkomsel_numbers ? numeral(item.calls_to_telkomsel_numbers).format('0,0') : '-'}</td>
                                <td style={{ fontSize: 11 }}>{item.calls_to_other_operators ? numeral(item.calls_to_other_operators).format('0,0') : '-'}</td>
                                <td style={{ fontSize: 11 }}>{item.idd_international_sms ? numeral(item.idd_international_sms).format('0,0') : '-'}</td>
                                <td style={{ fontSize: 11 }}>{item.domestic_sms ? numeral(item.domestic_sms).format('0,0') : '-'}</td>
                                <td style={{ fontSize: 11 }}>{item.domestic_data ? numeral(item.domestic_data).format('0,0') : '-'}</td>
                                <td style={{ fontWeight: "bold", fontSize: 11 }}>{item.amount_due_to_be_paid ? numeral(item.amount_due_to_be_paid).format('0,0') : '-'}</td>
                                <td style={{ fontWeight: "bold", fontSize: 11 }}>{item.subtotal_bu ? numeral(item.subtotal_bu).format('0,0') : ''}</td>
                                <td style={{ fontSize: 11 }}>{item.remarks}</td>
                              </tr>

                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>

          {view && user && (
            view.process.status !== 'Reject' &&
            !view.process.detail.find(a => a.approved_by._id === user.id)?.status && (
              <div className="row mt-4">
                <div className="col-md-10">
                  <Form.Item className="text-center">
                    <Button type="primary" onClick={submitApprove} className="m-3" size="middle" htmlType="submit">
                      Approve
                    </Button>
                  </Form.Item>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default ViewBillingTelkomsel;