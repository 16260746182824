import { Descriptions, Form, Radio, Input, Button, Collapse, Modal } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { Block, Confirm, Report } from "notiflix";
import { connect } from "react-redux";
import moment from "moment";

import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import HeaderFarms from "../../../utils/header_farms";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from "qrcode.react";

function ViewTSApprove() {

    const [publicHoliday, setPublicHoliday] = useState([]);
    const [, setCurrentUnit] = useState(null);
    const [dateList, setDateList] = useState([]);
    const [caption, setCaption] = useState({});

    const [outstandingLastMonth, setOutstandingLastMonth] = useState(0);
    const [outstandingThisMonth, setOutstandingThisMonth] = useState(0);
    const [totalWeek, setTotalWeek] = useState(0);
    const [totalSick, SetTotalSick] = useState(0);
    const [totalLeave, SetTotalLeave] = useState(0);
    const [totalPermit, setTotalPermit] = useState(0);
    const [totalDayOff, SetTotalDayOff] = useState(0);
    const [totalHomeBase, setTotalHomeBase] = useState(0);
    const [totalWeekOnSite, setTotalWeekOnSite] = useState(0);
    const [totalBusinessTrip, setTotalBusinessTrip] = useState(0);

    const isAuthenticated = useIsAuthenticated();
    const [tsheader, setTsHeader] = useState(null)
    const { instance, accounts } = useMsal();

    const [statusColor, setStatusColor] = useState({ background: null, color: null })
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [idApprovalList, setIdApprovalList] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    // const readOnly = true;

    const { Panel } = Collapse;

    let navigate = useNavigate();
    let { id } = useParams();

    const getAPIHoliday = () => {
        fetch("https://date.nager.at/api/v3/publicholidays/2023/ID")
            .then((response) => response.json())
            .then((data) => setPublicHoliday(data));
    };

    const security = (res) => {

        let user = currentUser && currentUser.id

        if (res.approval_process.status !== "Waiting Approval") {
            return Report.info(
                `Information`,
                `Timesheet berhasil di ${res.approval_process.status} pada periode ${moment(caption.startDate).format("YYYY-MMM-DD")} sampai ${moment(caption.endDate).format("YYYY-MMM-DD")}`,
                "Okay",
                function () {
                    // navigate('/wf/pending')
                })
        }
        if (currentUser) {
            if (res.approval_process.detail[0].approved_by._id !== user) {
                navigate('/home')
            }
        }
    }

    const getDetailTS = (token) => {
        API.viewTS(HeaderFarms(token), id).then((res => {

            security(res.data)

            loopDateFromAPI(res.data)
            setTsHeader(res.data)
            styleStatus(res.data)

            setOutstandingLastMonth(parseFloat(res.data?.outstanding_last_month || 0))
            setOutstandingThisMonth(parseFloat(res.data?.outstanding_this_month || 0))

            setCurrentUnit(res.data.uid.company.code)
            setIdApprovalList(res.data.approval_process.detail[0]._id)

            Block.remove('.ant-collapse');
            Block.remove('.table-responsive')

        })).catch((err) => {

            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );

        })
    }

    const styleStatus = (res) => {
        let status = res.approval_process.status;

        if (status === "Waiting Approval") {
            setStatusColor({
                background: "#FF9900",
                color: "#FFFFFF"
            })
        } else if (status === "Approved") {
            setStatusColor({
                background: "#1890FF",
                color: "#FFFFFF"
            })
        } else {
            setStatusColor({
                background: "#F5222D",
                color: "#FFFFFF"
            })
        }
    }

    function RequestToken() {

        Block.hourglass('.ant-collapse');
        Block.hourglass('.table-responsive')

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            getDetailTS(response.accessToken)

            if (!currentUser) {
                getUser(response.accessToken)
            }
        }).catch((e) => {
            console.error(e)
            Block.remove('.ant-collapse');
            Block.remove('.table-responsive')
            handleLogin(instance)
        });
    }

    async function getUser(token) {
        try {
            let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
            setCurrentUser(user)
        } catch (error) {
            console.error(error)
        }
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((_) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    const filterByDate = (date) => {
        let loc = null;

        publicHoliday.push({
            date: "2023-07-19",
            localName: "Tahun Baru Islam 1445 Hijriah",
            name: "Tahun Baru Islam 1445 Hijriah",
            countryCode: "ID",
            fixed: true,
            global: true,
            counties: null,
            launchYear: null,
            types: [
                "Public"
            ]
        });


        publicHoliday.forEach((item) => {
            if (date === item.date) {
                loc = String(item.localName);
            }

            // get newyear, because only check date and month, not year
            if (date.substring(5, 10) === item.date.substring(5, 10)) {
                loc = String(item.localName);
            }

        });

        return loc
    };

    const loopDateFromAPI = (data) => {

        let DTO = [];

        let dataApi = data.details_old.length > 0 ? data.details_old : data.details

        dataApi.forEach((item, index) => {

            DTO.push({
                ts_row_id_dtl: item.ts_row_id_dtl,
                ts_date_dtl: item.ts_date_dtl,
                ts_loc_dtl: item.ts_loc_dtl ? index + '_' + item.ts_loc_dtl : null, //must null for counter
                ts_reason_dtl: item.ts_reason_dtl ? index + '_' + item.ts_reason_dtl : null,
                ts_note_dtl: item.ts_note_dtl, //must null for counter
            })

        })

        countingDayOff(DTO)

        setDateList(DTO);
        setTotalWeek(data.total_work)
        setTotalWeekOnSite(data.total_site)
        SetTotalLeave(data.total_leave)
        SetTotalSick(data.total_sick)
        setTotalPermit(data.total_permit)
        setTotalHomeBase(data.total_home_base)
        setTotalBusinessTrip(data.business_trip)

        // for caption  
        setCaption({ startDate: data.date_from, endDate: data.date_to });

    };

    function countingDayOff(dateList) {
        let count = 0;
        dateList.forEach((item) => {
            if (item.ts_reason_dtl) {
                let split = item.ts_reason_dtl.split("_")[1];
                if (split === "HOLIDAY") {
                    count++;
                }
            }
        });
        SetTotalDayOff(count);
    }

    const handleFormChange = (a) => {

    };

    var get_week = function (date) {
        var dt = new Date(date);

        if (dt.getDay() === 6 || dt.getDay() === 0) {
            return true;
        }

        return false;
    };

    const Verification = (token) => {
        API.getUserCredential(HeaderFarms(token), { access_token: token }).then((res) => {

            // number phone exist but not already activation
            if (res.phone_number && !res.phone_number_activation) {
                return navigate('/verification/' + res.phone_number + '/' + token)
            }

            if (!res.phone_number) {
                return navigate('/verification/change/' + token)
            }

            return navigate('/wf/pending')

        }).catch((err) => {
            console.error(err)
        })
    }

    const approved = () => {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {

            API.workflowApproved(HeaderFarms(response.accessToken), {
                approval_id_list: idApprovalList,
            }).then((res) => {

                Report.success(
                    `Approved Success`,
                    `Timesheet berhasil di setujui pada periode ${moment(caption.startDate).format("YYYY-MMM-DD")} sampai ${moment(caption.endDate).format("YYYY-MMM-DD")}`,
                    "Okay",
                    function () {
                        Verification(response.accessToken)
                    })

            }).catch(err => {
                console.error(err)
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
            })

        }).catch((err) => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        });
    }

    const submitApprove = () => {
        Confirm.show(
            'Important Confirmation',
            'Are you sure ? please make sure timesheet is correct.',
            'Yes',
            'No',
            () => {
                approved()
            },
        );
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onReject = (values) => {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            API.workflowReject(HeaderFarms(response.accessToken), {
                approval_id_list: idApprovalList,
                msg: values.message
            }).then((res) => {

                Report.success(
                    `Reject Success`,
                    `Timesheet berhasil di tolak pada periode ${moment(caption.startDate).format("YYYY-MMM-DD")} sampai ${moment(caption.endDate).format("YYYY-MMM-DD")}`,
                    "Okay",
                    function () {
                        Verification(response.accessToken)
                    })


            }).catch((err) => {
                console.error(err)
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );

            })

        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );

        })
    }

    useEffect(() => {
        // Check already login or not ? 
        if (!isAuthenticated) {
            RequestToken()
            getAPIHoliday();
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
            getAPIHoliday();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, currentUser]);

    return (
        <>
            {isAuthenticated ? (
                <>
                    <Navbar
                        title="View"
                        back={true}
                        subtitle="Timesheet"
                        navigate="/wf/pending"
                    ></Navbar>
                    <div className="container white-back-cr" style={{ minHeight: 500 }}>
                        <div className="container mt-3 p-3">
                            <div className="row">
                                <div className="col-md-9 mb-3">
                                    <Collapse defaultActiveKey={['1']}>
                                        <Panel header="Timesheet Information" key="1">
                                            {dateList ? (
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-md-12">
                                                            {
                                                                tsheader && (
                                                                    <Descriptions size="small" bordered title="Information" className="mb-4">
                                                                        <Descriptions.Item label="Form ID">
                                                                            {tsheader.form_submit_id}
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label="Approver">
                                                                            {tsheader.approval_process.detail[0].approved_by.fullname}
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                                                            {tsheader.approval_process.status}
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label="Period">
                                                                            {moment(tsheader.date_from).format(
                                                                                "YYYY MMM DD"
                                                                            )} - {moment(tsheader.date_to).format(
                                                                                "YYYY MMM DD"
                                                                            )}
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label="Username">
                                                                            {tsheader.uid.fullname}
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label="Created at">
                                                                            {moment(tsheader.created_at).format('LLL')}
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                )
                                                            }
                                                            <Descriptions bordered title="Attendance Recap" style={{ fontSize: 10 }}>
                                                                <Descriptions.Item style={{ background: "#c5eded" }} label="Total Presence">
                                                                    <b>{totalWeek}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#c5eded" }} label="Home Base">
                                                                    <b>{totalHomeBase}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#c5eded" }} label="Business Trip">
                                                                    <b>{totalBusinessTrip}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#f0b1b1" }} label="Leave">
                                                                    <b>{totalLeave}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#f0b1b1" }} label="Sick">
                                                                    <b>{totalSick}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#f0b1b1" }} label="Permit">
                                                                    <b>{totalPermit}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#319F4F" }} label="Last Month Outstanding">
                                                                    <b>{tsheader && tsheader.outstanding_last_month}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#319F4F" }} label="This Month Outstanding">
                                                                    <b>{(outstandingLastMonth + outstandingThisMonth).toFixed(1)}</b>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item style={{ background: "#319F4F" }} label="Day Off">
                                                                    <b>{totalDayOff}</b>
                                                                </Descriptions.Item>

                                                            </Descriptions>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Panel>
                                    </Collapse>
                                </div>
                                {tsheader && tsheader.approval_process.approval_key && (
                                    <>
                                        <div className="col-md-9 mb-2">
                                            <Collapse defaultActiveKey={['1']}>
                                                <Panel header="Approved Barcode" key="1">
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${tsheader.approval_process.approval_key}`} />
                                                        </div>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </>

                                )}
                                <div className="col-md-9">
                                    <Form size="small" onFinish={null}>
                                        <div className="table-responsive">
                                            {dateList && (
                                                <table
                                                    className="table table-bordered table-striped"
                                                >
                                                    <thead className="table-primary" id="theadp">
                                                        <tr>
                                                            <th rowSpan={2} style={{ verticalAlign: "middle", textAlign: "center" }}>Date</th>
                                                            <th colSpan={4} style={{ textAlign: "center", background: "#c5eded" }}>Presence</th>
                                                            <th rowSpan={1} colSpan={3} style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Absence</th>
                                                            <th rowSpan={2} colSpan={2} style={{ verticalAlign: "middle", textAlign: "center" }}>Note</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>BRM</th>
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>CPM</th>
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>BUSINESS TRIP</th>
                                                            {/* <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>GMI</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>LMR</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>DPM</th>
                                                    <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>SHS</th> */}
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>Other</th>
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Leave</th>
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Sick</th>
                                                            <th style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Day Off</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            dateList && (
                                                                dateList.map((item, i) => {

                                                                    let backgroundColor = {};

                                                                    if (get_week(item.ts_date_dtl) || filterByDate(moment(item.ts_date_dtl).format('YYYY-MM-DD'))) {
                                                                        backgroundColor = { backgroundColor: '#34A853', color: '#FFFFFF', height: 41 };
                                                                    } else if (moment(item.ts_date_dtl).isBetween('2023-04-19', '2023-04-25', 'day', '[]')) {
                                                                        backgroundColor = { backgroundColor: '#F0B1B1', height: 41 };
                                                                    } else {
                                                                        backgroundColor = { height: 41 };
                                                                    }

                                                                    return (
                                                                        <tr style={{ verticalAlign: 'middle', textAlign: 'center', ...backgroundColor }}>
                                                                            <td style={{ fontSize: 10, minWidth: 10, fontWeight: "bold", verticalAlign: "middle", textAlign: "center" }}>{moment(item.ts_date_dtl).format("DD")}</td>
                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts" id={i + 'form-item-ts'}
                                                                                >
                                                                                    <Radio.Group
                                                                                        size="small"
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_loc_dtl"
                                                                                        value={item.ts_loc_dtl}
                                                                                    >
                                                                                        <Radio
                                                                                            style={
                                                                                                (item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : filterByDate(moment(item.ts_date_dtl).format("YYYY-MM-DD"))
                                                                                                        ? { color: "#FFFFFF" }
                                                                                                        : (moment(item.ts_date_dtl).isSame('2023-04-19', 'day') || moment(item.ts_date_dtl).isSame('2023-04-25', 'day'))
                                                                                                            ? { color: "#999999", cursor: "not-allowed" }
                                                                                                            : {}
                                                                                            }
                                                                                            value={i + "_BRM"}
                                                                                            disabled={moment(item.ts_date_dtl).isBetween('2023-04-19', '2023-04-25', 'day', '[]')}
                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts" id={i + 'form-item-ts-radio'}>
                                                                                    <Radio.Group
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_loc_dtl"
                                                                                        value={item.ts_loc_dtl}>

                                                                                        <Radio
                                                                                            style={
                                                                                                get_week(item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : filterByDate(
                                                                                                        moment(
                                                                                                            item.ts_date_dtl
                                                                                                        ).format("YYYY-MM-DD")
                                                                                                    )
                                                                                                        ? { color: "#FFFFFF" }
                                                                                                        : {}
                                                                                            }
                                                                                            value={i + "_CPM"}
                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts">
                                                                                    <Radio.Group
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_reason_dtl"
                                                                                        value={item.ts_loc_dtl}>
                                                                                        <Radio
                                                                                            style={
                                                                                                get_week(item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : {}
                                                                                            }
                                                                                            value={i + "_BUSINESSTRIP"}
                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts">
                                                                                    <Radio.Group
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_loc_dtl"
                                                                                        value={item.ts_loc_dtl}>
                                                                                        <Radio
                                                                                            style={
                                                                                                get_week(item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : filterByDate(
                                                                                                        moment(
                                                                                                            item.ts_date_dtl
                                                                                                        ).format("YYYY-MM-DD")
                                                                                                    )
                                                                                                        ? { color: "#FFFFFF" }
                                                                                                        : {}
                                                                                            }
                                                                                            value={i + "_OTHER"}
                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>

                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts">
                                                                                    <Radio.Group
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_reason_dtl"
                                                                                        value={item.ts_reason_dtl}>
                                                                                        <Radio
                                                                                            style={
                                                                                                get_week(item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : {}
                                                                                            }
                                                                                            value={i + "_LEAVE"}
                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts">
                                                                                    <Radio.Group
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_reason_dtl"
                                                                                        value={item.ts_reason_dtl}>
                                                                                        <Radio
                                                                                            style={
                                                                                                get_week(item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : {}
                                                                                            }
                                                                                            value={i + "_SICK"}
                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts">
                                                                                    <Radio.Group
                                                                                        onChange={(event) =>
                                                                                            handleFormChange(i, event)
                                                                                        }
                                                                                        name="ts_reason_dtl"
                                                                                        value={item.ts_reason_dtl}
                                                                                    >
                                                                                        <Radio
                                                                                            style={
                                                                                                get_week(item.ts_date_dtl)
                                                                                                    ? { color: "#FFFFFF" }
                                                                                                    : {}
                                                                                            }
                                                                                            value={i + "_HOLIDAY"}

                                                                                        >
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </td>
                                                                            <td style={{ width: 200, minWidth: 150, verticalAlign: "middle", textAlign: "center" }}>
                                                                                <Form.Item className="form-item-ts">
                                                                                    <input
                                                                                        className="ant-input ant-input-sm"
                                                                                        type={"text"}
                                                                                        key={i + "-ts_note_dtl"}
                                                                                        onBlur={(event) => {
                                                                                            handleFormChange(i, event)
                                                                                        }}
                                                                                        placeholder={

                                                                                            (moment(item.ts_date_dtl).isBetween('2023-04-19', '2023-04-25', 'day', '[]') &&
                                                                                                (moment(item.ts_date_dtl).isBefore('2023-04-22') || moment(item.ts_date_dtl).isAfter('2023-04-23')))
                                                                                                ? "Cuti Bersama"
                                                                                                : filterByDate(moment(item.ts_date_dtl).format("YYYY-MM-DD"))

                                                                                        }
                                                                                        defaultValue={item.ts_note_dtl}
                                                                                        name="ts_note_dtl"
                                                                                        style={{
                                                                                            color: 'grey',
                                                                                            fontStyle: 'italic'
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                        {
                                            tsheader && tsheader.approval_process.status !== 'Approved' && !tsheader.approval_process.detail[0].rejected && (
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <Form.Item className="text-center">
                                                            <Button type="primary" onClick={submitApprove} className="m-3" size="middle" htmlType="submit">
                                                                Approved
                                                            </Button>
                                                            <Button type="danger" size="middle" htmlType="submit" onClick={showModal}>
                                                                Reject
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <Modal
                                            title="Write the reason"
                                            footer={null}
                                            visible={isModalVisible}
                                            onOk={null}
                                            onCancel={handleCancel}
                                        >
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 24 }}
                                                initialValues={{ remember: true }}
                                                onFinish={onReject}
                                                autoComplete="off"
                                                layout="vertical"
                                            >
                                                <Form.Item
                                                    label=""
                                                    name="message"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your Message!",
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        showCount
                                                        style={{ height: "150px", width: "100%" }}
                                                        minLength={50}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="mt-3"
                                                    wrapperCol={{
                                                        xs: {
                                                            offset: 8, span: 18
                                                        },
                                                        sm: {
                                                            offset: 7, span: 18
                                                        },
                                                        lg: { offset: 8, span: 18 }
                                                    }}

                                                >
                                                    <Button type="primary" htmlType="submit">
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Modal>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </>
            ) :
                null
            }
        </>
    );
}

const reduxState = (state) => ({
    getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewTSApprove);

