export default function Header() {

  // Secret Token
  const token = 'CC38066AA3850902E5262D82D591BB9D';

  if (token) {
    return { 'X-API-Key': token };
  } else {
    return {};
  }
}

