import React, { Fragment, useEffect, useState } from "react";
import { Empty, Badge, Affix, Button, Select, Spin } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Loading, Report } from "notiflix";
import moment from "moment";

import ImportantInfo from "../../../atoms/important-updates";
import Navbar from "../../../atoms/navbar";
import Footer from "../../../atoms/footer";
import "./styles.css";

import { callMsGraph } from "../../../utils/graph";
import { loginRequest } from "../../../config/api-config";

import { InitializeTicket } from "../../../redux/action";
import HeaderFarms from "../../../utils/header_farms";
import { connect } from "react-redux";
import API from "../../../services";

function TAHistory() {

    let navigate = useNavigate();
    const { Option } = Select;

    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(true);
    const isAuthenticated = useIsAuthenticated();
    const [historyTA, setHistoryTA] = useState([]);

    const [filter, setFilter] = useState('Waiting Approval');

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function handleChange(value) {
        setFilter(value)
    }

    function getAllHistory(accessToken, email) {
        API.HistoryTA(HeaderFarms(accessToken), email).then(res => {
            setHistoryTA(res.data)
            setLoading(false)
        })
            .catch((err) => {
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
                Loading.remove();
            });
    }

    function goDetail(id) {
        navigate(`/ta/view/${id}`);
    }

    function RequestToken() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {
                callMsGraph(responseToken.accessToken).then((response) => {
                    getAllHistory(responseToken.accessToken, response.mail)
                });
            })
            .catch((e) => {
                handleLogin(instance)
                navigate("/");
            });
    }

    useEffect(() => {
        // Check already login or not ? 
        if (!isAuthenticated) {
            navigate('/')
        } else {
            RequestToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Navbar
                title="History"
                back={true}
                subtitle="Travel Authority"
                navigate="/home"
            ></Navbar>
            <div className="container white-back-cr" style={{ minHeight: 500 }}>
                <ImportantInfo></ImportantInfo>
                <div className="container mt-3 p-3">
                    <div className="row" style={{ marginBottom: '10px' }}>
                        <div className="col-md-12 col-sm-12">
                            <Affix offsetTop={10}>
                                <div className="col-md-6 d-flex justify-content-start">
                                    <Button type="primary" onClick={() => navigate("/ta/create")} size="middle">
                                        New Travel Authority
                                    </Button>
                                </div>
                            </Affix>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">

                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <label style={{ marginRight: 10, marginTop: 5 }}>Filter</label>
                            <Select
                                defaultValue="Waiting"
                                style={{
                                    width: 120,
                                }}
                                onChange={handleChange}
                            >
                                <Option value="Waiting Approval">Waiting</Option>
                                <Option value="Approved">Approved</Option>
                                <Option value="Reject">Reject</Option>
                                <Option value="Closed">Closed</Option>
                                <Option value="Cancel">Cancel</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {historyTA.length > 0 ? (
                                historyTA.map(function (data, index) {
                                    if (data.approval_process_id) {
                                        if (
                                            (filter === 'Waiting Approval' && data.approval_process_id.status === 'Waiting Approval') ||
                                            (filter === 'Approved' && data.approval_process_id.status === 'Approved') ||
                                            (filter === 'Reject' && data.approval_process_id.status === 'Reject') ||
                                            (filter === 'Cancel' && data.approval_process_id.status === 'Cancel') ||
                                            (filter === 'Closed' && data.approval_process_id.status === 'Closed')
                                        ) {
                                            let ribbonColor;
                                            switch (data.approval_process_id.status) {
                                                case 'Waiting Approval':
                                                    ribbonColor = '#FF9900';
                                                    break;
                                                case 'Approved':
                                                    ribbonColor = 'blue';
                                                    break;
                                                case 'Reject':
                                                    ribbonColor = 'red';
                                                    break;
                                                case 'Closed':
                                                    ribbonColor = 'green';
                                                    break;
                                                default:
                                                    ribbonColor = 'red';
                                            }

                                            return (
                                                <Fragment key={index}>
                                                    <Badge.Ribbon color={ribbonColor} text={data.approval_process_id.status}>
                                                        <div className="list-style p-3 px-4 mb-3" onClick={() => goDetail(data.id_record)}>
                                                            <div className="title mb-4">
                                                                <p className="fs-7 mb-0">
                                                                    {data.type_travel}
                                                                </p>
                                                            </div>
                                                            <div className="hour d-flex justify-content-between m-1">
                                                                <p className="fs-7" style={{ color: '#B7B7B7', fontSize: filter === 'Waiting Approval' ? 11 : undefined }}>
                                                                    {moment(data.created_at).format('LLL')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Badge.Ribbon>
                                                </Fragment>
                                            );
                                        }
                                    }
                                    return null;
                                })
                            ) : !loading ? (
                                <Empty className="mt-5" />
                            ) : null}
                            {historyTA.length > 0 && (
                                <div className="fs-6 text-center mt-5" style={{ color: '#B7B7B7' }}>
                                    No more data
                                </div>
                            )}
                            {loading && (
                                <div className="fs-6 text-center mt-5" style={{ color: '#B7B7B7' }}>
                                    <Spin size="large" />
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <Footer></Footer>
        </Fragment>
    );
}

const reduxState = (state) => ({
    initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
    setInitializeTicket: (navigate, data) =>
        dispatch(InitializeTicket(navigate, data)),
});

export default connect(reduxState, reduxDispatch)(TAHistory);