import Navbar from '../../atoms/navbar/index';

function ProjectMonitoring() {
  return (
    <>
      <Navbar
        title="Menu"
        back={true}
        subtitle=""
        navigate="/home"
      ></Navbar>
      <div className="container">
        <div className='row'>
          <div className='col'>
            <iframe title="Dashboard CPM Infrastructure Project V2"
              style={{ width: "100%", height: "100vh" }}
              src="https://app.powerbi.com/view?r=eyJrIjoiZGZiNTc2YzMtMDcxZi00NGQ1LTk0NzctNThiOGE0MDMzNThkIiwidCI6IjJjN2U5YzA2LWU0MjItNDAwYi04ZDBlLWU4MzNkMDIxMWI3YSIsImMiOjEwfQ%3D%3D"
              frameborder="0" allowFullScreen="true"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectMonitoring;
