let initialState = {
  initializeTicket: '',
  initializeTicketDetails: '',
  initializeTicketAttachments: [],
  verification: false,
  priority_desc: null,
  priority_id: null,
  deputy_by: null,
  session: null,
  session_info: null,
  mobile: '',
  topics: null
}

const reducer = (state = initialState, action) => {

  if (action.type === 'INITIALIZE_TICKET') {

    let setState = {
      ...state,
      initializeTicket: action.value,
    }

    setLocal(setState);

    return setState
  }

  if (action.type === 'INITIALIZE_TICKET_DET') {

    let setState = {
      ...state,
      initializeTicketDetails: action.value
    }

    setLocal(setState);

    return setState;
  }

  if (action.type === 'INITIALIZE_TICKET_FILE') {

    let setState = {
      ...state,
      initializeTicketAttachments: action.value
    }

    setLocal(setState);

    return setState
  }

  if (action.type === 'INITIALIZE_SESSION') {

    let setState = {
      ...state,
      session: action.value
    }


    return setState
  }

  if (action.type === 'INITIALIZE_SESSION_HEADER') {

    let setState = {
      ...state,
      session_info: action.value
    }

    return setState
  }

  if (action.type === 'INITIALIZE_MOBILE') {

    let setState = {
      ...state,
      mobile: action.value
    }

    return setState
  }

  if (action.type === 'INITIALIZE_VERIFICATION') {

    let setState = {
      ...state,
      verification: action.value
    }

    return setState
  }

  if (action.type === 'INITIALIZE_TOPICS') {

    let setState = {
      ...state,
      topics: action.value
    }

    return setState
  }

  return state;
}

function setLocal(data) {
  localStorage.setItem("data", JSON.stringify(data));
}

// function getLocal() {
//   return JSON.parse(localStorage.getItem("data"));
// }

export default reducer;