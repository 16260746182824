import React, { Fragment, useEffect } from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Form, Input, DatePicker, Button, Select } from "antd";
import { Confirm, Loading, Report, } from "notiflix";
import { useNavigate } from "react-router";

import { OsTicket, whatsappServices } from "../../config/api-config";
import { loginRequest } from "../../config/api-config";
import HeaderFarms from "../../utils/header_farms";
import Header from "../../utils/header_osticket";
import Navbar from "../../atoms/navbar";
import Footer from "../../atoms/footer";
import API from "../../services";

import zoom from './zoom.png'

function CreateZoomRequest() {

    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;
    const { Option, OptGroup } = Select;


    const { instance, accounts, inProgress } = useMsal();

    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();

    function changeNumberTo62(number) {
        if (number?.charAt(0) === '0') {
            return '62' + number.slice(1);
        }
        return number;
    }

    const getBusinessEntity = (mail) => {
        let unit = null;
        switch (mail && mail.split('@')[1]) {
            case 'brm.co.id':
                unit = 13
                break;
            case 'citrapalu.com':
                unit = 21
                break;
            case 'gorontalominerals.com':
                unit = 13
                break;
            default:
                unit = 13
        }

        return unit
    }

    const ServicesCreateTicket = async (data) => {
        try {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            const response = await instance.acquireTokenSilent(request);

            const userCredentialResponse = await API.getUserCredential(
                HeaderFarms(response.accessToken),
                { access_token: response.accessToken }
            );

            let body = {
                alert: true,
                autorespond: true,
                source: "API",
                name: userCredentialResponse.fullname,
                email: userCredentialResponse.email,
                phone: changeNumberTo62(userCredentialResponse.phone_number),
                subject: data.topic_meeting,
                message: `data:text/html, Location : ${data.location || '-'} <br> Topic : ${data.topic_meeting} <br> Date & Time Meeting : ${data.dateTimeRange[0].format("YYYY-MM-DD HH:mm")} - ${data.dateTimeRange[1].format("YYYY-MM-DD HH:mm")} ${data.timezone}`,
                attachments: null,
                topicId: getBusinessEntity(userCredentialResponse.email),
            };

            const res = await API.CreateTicket(Header(), body);

            let messageTemplate = "Information New Zoom Request " + res + " created \n \n"
            messageTemplate += "Name : " + body.name + '\n';
            messageTemplate += "Email : " + body.email + '\n';
            messageTemplate += "Phone : " + changeNumberTo62(body.phone) + '\n';
            messageTemplate += "Subject : Request Zoom Meeting \n";
            messageTemplate += "Topic : " + body.subject + '\n \n';
            messageTemplate += `Date & Time Meeting : ${data.dateTimeRange[0].format("YYYY-MM-DD HH:mm")} - ${data.dateTimeRange[1].format("HH:mm")} ${data.timezone} \n \n`;
            if (data.location) {
                messageTemplate += `Location : ${data.location} \n \n`;
            }

            messageTemplate += "Thanks for handle";

            // Send WhatsApp message to IT service group
            await API.sendGroupWa({
                id: whatsappServices.it_service_group,
                message: messageTemplate
            });

            // Send WhatsApp message to the requester
            await API.sendPersonalWa({
                number: body.phone,
                message: messageTemplate
            });

            Report.success(
                `Create Zoom Request Success`,
                `Berhasil dibuat dengan nomor ${res}, Terima kasih`,
                "Okay",
                function () {
                    Loading.remove();
                    navigate("/help/history-ticket");
                }
            );

        } catch (e) {
            Report.failure(
                `Create Zoom Request failed`,
                `${e.toString()}`,
                "Okay",
            );
            Loading.remove(); // Assuming Loading.remove() handles some UI action
        }
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {

        }).catch((e) => {
            handleLogin(instance)
        });
    }

    const onFinish = async (data) => {
        Confirm.show(
            "Create Zoom Request",
            "Are you sure topic and date correct ?",
            "Yes",
            "No",
            () => {
                Loading.hourglass("Just a Moment");
                ServicesCreateTicket(data);
            },
            {}
        );
    };

    useEffect(() => {

        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }

    }, [isAuthenticated, inProgress])

    return (
        <Fragment>
            <Navbar
                title="Zoom Request"
                subtitle={<img width={25} src={zoom} alt="" />}
                back={true}
                navigate="/home"
            ></Navbar>
            <div className="container white-back-cr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                form={form}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="on"
                                size="large"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Topic"
                                    name="topic_meeting"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your topic!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Date and Time"
                                    name="dateTimeRange"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select the date and time range!",
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Timezone"
                                    name="timezone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select the Timezone range!",
                                        },
                                    ]}
                                >
                                    <Select style={{ width: 120, marginBottom: 10 }}>
                                        <Option value="WIB">WIB</Option>
                                        <Option value="WITA">WITA</Option>
                                        <Option value="WIT">WIT</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={<span>
                                        Location
                                        <span style={{ fontSize: '12px', color: '#888' }}> (Tidak Wajib)</span>
                                    </span>}
                                    name="location"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please select the Location!",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="combobox"
                                        style={{ width: 200, marginBottom: 10 }}
                                        placeholder="Select Location"
                                    >
                                        <OptGroup label="GM">
                                            <Option value="GM - Office Leppin">Office Leppin</Option>
                                            <Option value="GM - Office Tombulilato">Office Tombulilato</Option>
                                            <Option value="GM - Camp Sungai Mak">Camp Sungai Mak</Option>
                                            <Option value="GM - Camp Cabang Kiri">Camp Cabang Kiri</Option>
                                        </OptGroup>
                                        <OptGroup label="CPM">
                                            <Option value="CPM - Banua 1">Banua 1</Option>
                                            <Option value="CPM - Banua 2">Banua 2</Option>
                                            <Option value="CPM - Banua 3">Banua 3</Option>
                                            <Option value="CPM - Kantor Geology">Kantor Geology</Option>
                                            <Option value="CPM - Kantor PPD">Kantor PPD</Option>
                                            <Option value="CPM - Kantor Representative">Kantor Representative</Option>
                                        </OptGroup>
                                        <OptGroup label="BRM">
                                            <Option value="BRM - Diamond">Diamond</Option>
                                            <Option value="BRM - Gold">Gold</Option>
                                            <Option value="BRM - Cooper">Cooper</Option>
                                            <Option value="BRM - Zinc">Zinc</Option>
                                            <Option value="BRM - Silver">Silver</Option>
                                            <Option value="BRM - Nicker">Nicker</Option>
                                        </OptGroup>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 0,
                                        span: 24,
                                    }}
                                >
                                    <Button className="mt-3" type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </Fragment>
    );
}

export default CreateZoomRequest;
