import { Descriptions, PageHeader, Result } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Loading, Report } from 'notiflix';
import moment from 'moment';

import HeaderFarms from '../../utils/header_farms';
import Footer from '../../atoms/footer';
import API from '../../services';


function Approved() {
    let { id } = useParams();
    const [data, setData] = useState(null);
    let navigate = useNavigate();

    const getApproved = () => {
        Loading.pulse("Just a Moment");
        API.workflowApprovedDetails(HeaderFarms(), id).then(res => {
            setData(res.data)
            Loading.remove();
        }).catch(err => {
            console.log(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
            Loading.remove();
        })

        Loading.remove();
    }

    useEffect(() => {
        getApproved()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                data && (
                    <>
                        <div className='container' style={{ minHeight: "700px" }}>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <PageHeader
                                        ghost={true}
                                        title="Approved Detail"
                                        onBack={() => navigate(`/`)}
                                        backIcon={<ArrowLeftOutlined />}
                                    >
                                    </PageHeader>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Result

                                        status="success"
                                    // title={`Your document has been officially approved by ${data && data.approver[0].fullname}`}
                                    />
                                </div>
                            </div>
                            <div className='row p-4'>
                                <div className='col-md-12'>
                                    <Descriptions bordered title="Document Information">
                                        <Descriptions.Item label="Name" style={{ backgroundColor: "#DDF4FF" }}><b>{data && data.form_id.name}</b></Descriptions.Item>
                                        <Descriptions.Item label="Owned by"><b>{data && data.uid.fullname}</b></Descriptions.Item>
                                        <Descriptions.Item label="Form ID" style={{ backgroundColor: "#DDF4FF" }}><b>{data && data.form_submit_id}</b></Descriptions.Item>
                                        <Descriptions.Item label="Created at"><b>{moment(data.detail[0].created_at).format('YYYY-MMMM-DD - HH:mm')}</b></Descriptions.Item>
                                    </Descriptions>
                                    <div className='col-md-12 mt-4'>
                                        <Descriptions bordered title="Approval Information">
                                            {
                                                data && data.detail.map(item => {
                                                    return (
                                                        <>
                                                            <Descriptions.Item label="Approved by" style={{ backgroundColor: "#DDF4FF" }}><b>{item.approved_by.fullname}</b></Descriptions.Item>
                                                            <Descriptions.Item label="Approved at"><b>{moment(item.approved_at).format('YYYY-MMMM-DD - HH:mm')}</b></Descriptions.Item>
                                                        </>
                                                    )
                                                })
                                            }

                                        </Descriptions>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer></Footer>
                    </>
                )
            }
        </>
    );
}
export default Approved