import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { initializeHeaderInfo } from "../../redux/action";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { loginRequest } from "../../config/api-config";
import React, { useEffect, useState } from "react";
import { callMsGraph } from "../../utils/graph";
import { useNavigate } from "react-router";
import { PageHeader } from "antd";
import { Confirm } from "notiflix";
import { connect } from "react-redux";
import "./styles.css";

import { Detector } from "react-detect-offline";

function Navbar(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  let navigate = useNavigate();

  function Logout() {
    Confirm.show(
      "Logout",
      "Are you sure want Logout Session?",
      "Yes",
      "No",
      () => {
        handleLogout(instance)
      },
      {}
    );
  }

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      callMsGraph(response.accessToken).then(response_graph => {
        props.setSession(response_graph)
      })
    }).catch((e) => {
      handleLogin(instance)
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((res) => { })
      .catch(e => {
        console.error(e);
      });
  }

  function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      let getSession = props.session ? props.session : {};
      if (Object.keys(getSession).length === 0 &&
        Object.getPrototypeOf(getSession) === Object.prototype) {
        RequestToken();
      }
    }

  }, [isAuthenticated, props.session,]);

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <PageHeader
          className="site-page-header"
          onBack={() => navigate(`${props.navigate}`)}
          title={props.title}
          subTitle={props.subtitle}
          backIcon={props.back ? <ArrowLeftOutlined /> : ''}
        />
        <div className="uinfo" style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} onClick={() => Logout()}>
          <Detector
            render={({ online }) => (
              <div style={{ width: '15px', height: '15px', borderRadius: '20%', backgroundColor: online ? 'green' : 'red', marginRight: '30px' }}></div>
            )}
          />
          <div>
            {props.session && props.session.displayName}<br />
            {props.session && props.session.userPrincipalName}
          </div>
        </div>
      </div>
    </nav>
  );
}

const reduxState = (state) => ({
  session: state.session_info,
});

const reduxDispatch = (dispatch) => ({
  setSession: (data) =>
    dispatch(initializeHeaderInfo(data)),
});

export default connect(reduxState, reduxDispatch)(Navbar);
