// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./containers/app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig } from "./config/api-config";
import Aos from "aos";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

Aos.init();

function MainApp() {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
    }
  }, [isAuthenticated]);

  return <App />;
}

root.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <MainApp />
    </MsalProvider>
  </Router>
);

reportWebVitals();
