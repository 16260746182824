import React, { useEffect } from 'react';

import { Layout, Button, Collapse, Form, Input, message, Row, Col } from "antd";

import { CheckCircleOutlined } from '@ant-design/icons';
import Navbar from '../../../atoms/navbar';

import { Confirm, Loading, Report } from 'notiflix';
import HeaderFarms from '../../../utils/header_farms';
import { useNavigate } from 'react-router';
import API from '../../../services';

function ResetPasswordOperator() {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { Panel } = Collapse;

    const onFinish = (data) => {
        Confirm.show(
            'Konfirmasi Penting',
            'Apakah Anda yakin?',
            'Ya',
            'Tidak',
            () => {
                Loading.hourglass("Mohon tunggu sedang diproses..");

                // Panggil API ResetPasswordOperator dengan menggunakan token yang diperoleh
                API.ResetPasswordOperator(HeaderFarms(), data)
                    .then((response) => {
                        Loading.remove();
                        if (response && response.code === 200) {
                            navigate("/200")
                        } else if (response && response.code === 400) {
                            Report.info(
                                "Operator sudah terdaftar!",
                                "Employee ID yang Anda masukkan sudah terdaftar.",
                                "Okay"
                            );
                        }
                        else if (response && response.code === 404) {
                            Report.info(
                                "Operator tidak terdaftar!",
                                "Employee ID yang Anda masukkan mungkin salah.",
                                "Okay"
                            );
                        }
                        else {
                            Report.warning(
                                "Oops.. terjadi kesalahan",
                                "Maaf, terjadi kesalahan saat membuat operator.",
                                "Okay"
                            );
                        }
                    })
                    .catch((error) => {
                        Loading.remove();
                        console.error(error);
                        Report.warning(
                            "Oops.. terjadi kesalahan",
                            "Maaf, aplikasi ini mengalami masalah",
                            "Okay"
                        );

                    });
            }
        );
    };




    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Fuel System"
                navigate="/fms/operator/register"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="operator_register"
                    initialValues={{
                        remember: true,
                    }}
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='middle'
                    layout='vertical'
                >
                    <div className="p-2" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Reset Password Operator</b>} key="2">
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="ID Karyawan"
                                                    name="employee_id"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col>
                                                <Form.Item className='mt-3'>
                                                    <Button type="primary" size="large" htmlType="submit" icon={<CheckCircleOutlined />}>
                                                        Reset
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2024 Information Technology</Layout.Footer>
        </>
    );
}
export default ResetPasswordOperator