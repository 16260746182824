import {
  Collapse, Form, Input, Row, Col, InputNumber,
  Radio, Descriptions, Comment, Avatar, Button, Affix, Timeline, Alert, Image
} from "antd";
import { useNavigate, useParams } from "react-router";
import { Block, Confirm, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import HeaderFarms from "../../../utils/header_farms";
import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { CheckOutlined, SettingOutlined } from "@ant-design/icons";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";

function ViewRequestFuel() {

  const [form] = Form.useForm();

  const { Panel } = Collapse;
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [RequestDetail, setRequestDetail] = useState(false)
  const [user, setUser] = useState(null);

  let navigate = useNavigate();
  let { id } = useParams();

  const getDetailRequest = async (token) => {
    try {
      const res = await API.ViewRequestFuel(HeaderFarms(token), id);

      Block.remove('.ant-collapse');

      res.data.date = moment(res.data.date).format('YYYY-MM-DD')
      res.data.time = moment(res.data.time).format('HH:mm')

      form.setFieldsValue(res.data);

      setRequestDetail(res.data);
      styleStatus(res.data);
      // only one call
      user || getUser(token)

    } catch (err) {
      console.error(err);
      Report.info("Working Permit", "Sorry, Fuel Request not found", "Okay");
      Block.remove('.ant-collapse');
    }
  };

  const styleStatus = (res) => {
    let status = res.approval_process.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  const handleAdjustment = () => {
    return navigate('/fms/request/adjustment/' + id)
  }

  function RequestToken() {

    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      getDetailRequest(response.accessToken);
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((_) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
      setUser(user)
    } catch (error) {
      console.error(error)
    }
  }

  const submitApprove = () => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ?.',
      'Yes',
      'No',
      () => {
        approved()
      },
    );
  }

  const approved = () => {

    // check approver or not
    let getDetailProcess = RequestDetail.approval_process.detail.filter(item => item.approved_by._id === user.id
      && item.status === false)[0]?._id

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    if (!getDetailProcess) {
      getDetailProcess = RequestDetail.approval_process.detail[0]._id
      request.deputy = true
    } else {
      request.deputy = false
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      // Note : if deputy true, will get parameter deputy from nik session login farms.
      API.workflowApproved(HeaderFarms(response.accessToken), {
        approval_id_list: getDetailProcess,
        deputy: request.deputy // Use the deputy value from the request object
      }).then((res) => {

        Report.success(
          `Approved Success`,
          `Berhasil di setujui, Terima Kasih`,
          "Okay",
          function () {
            return navigate('/fms/menu')
          })

      }).catch(err => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      })

    }).catch((err) => {
      console.error(err)
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    });

  }

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Fuel System"
        navigate="/fms/menu"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="fuel_request"
          initialValues={{
            remember: true,
          }}
          encType='multipart/form-data'
          autoComplete="off"
          size='middle'
          layout='vertical'
        >
          <div className="container-fluid p-2" style={{ alignSelf: "center" }}>
            <div className="row">
              <div className="col-md-12 mb-2">
                <Collapse defaultActiveKey={['1']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Approval Process</b>} key="1">
                    {RequestDetail ? (
                      <>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            {
                              RequestDetail && (
                                <Descriptions size="small" bordered title="Information" className="mb-4">
                                  <Descriptions.Item label="Form ID">
                                    {RequestDetail.record_id}
                                  </Descriptions.Item>
                                  <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                    {RequestDetail.approval_process.status}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Created at">
                                    {moment(RequestDetail.created_at).format('LLL')}
                                  </Descriptions.Item>
                                </Descriptions>
                              )
                            }
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-5'>
                            <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                            <Timeline style={{ marginTop: 30 }}>
                              {
                                RequestDetail.approval_process.detail.map(item => {
                                  return (
                                    <Timeline.Item key={item._id}>{item.deputy_approver ? `Deputy (${item.deputy_approver})` : item.approved_by.fullname}
                                      <Alert
                                        message={item.status ? `Approved - ${moment(item.approved_at).format('YYYY-MM-DD HH:mm')}` : `Waiting Approval`}
                                        className='mt-1 p-1'
                                        type={RequestDetail.approval_process.detail.find(a => a.status === false) ? 'warning' : 'success'}
                                        showIcon
                                      />
                                    </Timeline.Item>
                                  )
                                })
                              }
                            </Timeline>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Panel>
                </Collapse>
              </div>
              {RequestDetail && RequestDetail.approval_process.approval_key && (
                <>
                  <div className="col-md-12">
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Approved Barcode" key="1">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${RequestDetail.approval_process.approval_key}`} />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </>

              )}
              <div className="col-md-12">
                {
                  RequestDetail && RequestDetail.approval_process.comments.length > 0 && (
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Comments" key="1">
                        {
                          RequestDetail && RequestDetail.comments.map(data => {
                            return (
                              <Comment
                                key={data._id}
                                author={<p>{data.uid.fullname}</p>}
                                avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                content={
                                  <p>
                                    {data.text_plain}
                                  </p>
                                }
                              />
                            )
                          })
                        }
                      </Panel>
                    </Collapse>
                  )
                }
              </div>
              <div className='col-md-12'>
                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Request Fuel Detail {RequestDetail.adjustment_id && "( Edited )"}</b>} key="2">
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Date"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: 'Please input date',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label="Time"
                          name="time"
                          rules={[
                            {
                              required: true,
                              message: 'Please input time',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Fuel Truck"
                          name="fuel_truck"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Lokasi"
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Nomor Lambung"
                          name="hull_number"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Nama alat berat"
                          name="equipment_name"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Owner"
                          name="equipment_owner"
                          rules={[
                            {
                              required: false,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item
                          style={{ display: 'none' }}
                          label=""
                          name="category"
                          rules={[
                            {
                              required: false,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input hidden />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Kilometer"
                          name="kilometer"
                          rules={[
                            {
                              required: false,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <InputNumber type={"number"} step={0.1} addonAfter="KM" />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label="Hourmeter"
                          name="hourmeter"
                          rules={[
                            {
                              required: false,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <InputNumber type={"number"} step={0.1} addonAfter="HM" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                        <Form.Item
                          label="Nama Supir / Operator"
                          name="operator_id"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      {
                        RequestDetail.adjustment_id && (
                          <Col xs={{ span: 24 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                            <Form.Item
                              label="Catatan Penyesuaian"
                              name="adjustment_note"
                              rules={[
                                {
                                  required: true,
                                  message: 'Wajib Isi dengan minimal 20 karakter',
                                  min: 20,
                                },
                              ]}
                            >
                              <Input.TextArea readOnly rows={2} />
                            </Form.Item>
                          </Col>
                        )
                      }
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jenis Bahan Bakar"
                          name="fuel_type"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="Solar">Solar</Radio>
                            <Radio value="Benzine">Bensin</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jumlah diajukan"
                          name="amount_submitted"

                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')} addonAfter="L" />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jumlah dikeluarkan"
                          name="amount_issued"
                          rules={[
                            {
                              required: true,
                              message: 'Wajib Isi',
                            },
                          ]}
                        >
                          <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')} addonAfter="L" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className='col-md-12'>
              {
                RequestDetail && (
                  <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                    <Panel style={{ background: "#fce8b6" }} header={<b>Attachments</b>} key="2">

                      <div className="row">
                        <div className="col">
                          <label style={{ marginBottom: '10px', display: 'block' }}>Flow Meter</label>
                          {RequestDetail.record_id && (
                            <Image
                              width={100}
                              src={"https://farms-fms-flowmeter.s3.ap-southeast-3.amazonaws.com/" + RequestDetail.record_id}
                              fallback={"https://via.placeholder.com/201"}
                            />
                          )}
                        </div>
                        <div className="col">
                          <label style={{ marginBottom: '10px', display: 'block' }}>Vehicle Meter</label>
                          {RequestDetail.record_id && (
                            <Image
                              width={100}
                              src={"https://farms-fms-vehicle-meter.s3.ap-southeast-3.amazonaws.com/" + RequestDetail.record_id}
                              fallback={"https://via.placeholder.com/200"}
                            />
                          )}
                        </div>
                        <div className="col">
                          <label style={{ marginBottom: '10px', display: 'block' }}>Operator</label>
                          {RequestDetail.record_id && (
                            <Image
                              width={100}
                              src={"https://farms-fms-operator.s3.ap-southeast-3.amazonaws.com/" + RequestDetail.record_id}
                              fallback={"https://via.placeholder.com/200"}
                            />
                          )}
                        </div>
                        <div className="col">
                          <label style={{ marginBottom: '10px', display: 'block' }}>Kendaraan</label>
                          {RequestDetail.record_id && (
                            <Image
                              width={100}
                              src={"https://farms-fms-vehicle.s3.ap-southeast-3.amazonaws.com/" + RequestDetail.record_id}
                              fallback={"https://via.placeholder.com/200"}
                            />
                          )}
                        </div>
                      </div>

                    </Panel>
                  </Collapse>
                )
              }

            </div>
            <div className="row mt-4">
              <Row gutter={[16, 16]}>

                <Col xs={{ span: 12 }} sm={{ span: 12 }}>
                  <Affix offsetBottom={10} style={{ marginRight: '16px' }}>
                    <Form.Item className="text-end">
                      <Button
                        disabled={RequestDetail && user && RequestDetail.approval_process.status === 'Waiting Approval' &&
                          RequestDetail.approval_process.form_id.form_setting.approved_by.find(pic => pic === user.id) ? false : true}
                        type="primary"
                        onClick={submitApprove}
                        className="m-3"
                        size="middle"
                        htmlType="submit"
                        icon={<CheckOutlined />}
                      >
                        Approved
                      </Button>
                    </Form.Item>
                  </Affix>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }}>
                  <Affix offsetBottom={10}>
                    <Form.Item>
                      <Button
                        onClick={handleAdjustment}
                        type="primary"
                        className="m-3"
                        size="middle"
                        icon={<SettingOutlined />}
                        disabled={(RequestDetail && user) && RequestDetail.approval_process.form_id.form_setting.approved_by.find(pic => pic === user.id) ? false : true}
                      >
                        Adjustment
                      </Button>
                    </Form.Item>
                  </Affix>
                </Col>
              </Row>

            </div>
          </div>
        </Form>
      </div>

      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewRequestFuel);
