import { openDB } from 'idb';

const dbName = 'workbox-background-sync';
const storeName = 'requests';

let dbPromise = null;

async function getDB() {
    if (!dbPromise) {
        dbPromise = openDB(dbName, 3, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath: 'id' });
                }
            },
        });
    }
    return dbPromise;
}

export async function readFromIndexDB() {
    const db = await getDB();
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    return store.getAll();
}

export async function deleteFromIndexDB(id) {
    const db = await getDB();
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    await store.delete(id);

    await tx.complete;
}

export async function addToIndexDB(request) {
    const db = await getDB();
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    await store.add(request);

    await tx.complete;
}
