import redirectUri from './redirects';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: redirectUri
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ["User.Read", "User.Read.All"],
};

export const OsTicket = process.env.REACT_APP_OS_TICKET;

export const whatsappServices = {
  waendpoint: process.env.REACT_APP_WA_ENDPOINT,
  api_key: {
    "x-api-key": process.env.REACT_APP_WA_API_KEY,
  },
  it_service_group: process.env.REACT_APP_WA_IT_SERVICE_GROUP,
};

export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_GRAPH_ME_ENDPOINT,
};

export const farms_backend = {
  endpoint: process.env.REACT_APP_FARMS_BACKEND_ENDPOINT,
  api_key: process.env.REACT_APP_FARMS_BACKEND_API_KEY,
};

export const fuel_service = {
  endpoint: process.env.REACT_APP_FUEL_ENDPOINT,
  api_key: process.env.REACT_APP_FUEL_API_KEY,
};

export const booking_driver = {
  endpoint: process.env.REACT_APP_BOOKING_DRIVER_ENDPOINT,
  api_key: process.env.REACT_APP_FUEL_API_KEY,
};
