import React, { Fragment, useEffect, useState } from "react";
import { Badge, Affix, Button, Select, Spin, DatePicker } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Loading, Report } from "notiflix";
import moment from "moment";

import ImportantInfo from "../../../atoms/important-updates";
import Navbar from "../../../atoms/navbar";
import Footer from "../../../atoms/footer";
import "./styles.css";

import { callMsGraph } from "../../../utils/graph";
import { loginRequest } from "../../../config/api-config";

import { InitializeTicket } from "../../../redux/action";
import HeaderFarms from "../../../utils/header_farms";
import { connect } from "react-redux";
import API from "../../../services";


function WPHistory() {

    let navigate = useNavigate();
    const { Option } = Select;

    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(true)
    const isAuthenticated = useIsAuthenticated();
    const [historyWP, setHistoryWP] = useState([]);
    const [historyMonitor, setHistoryMonitor] = useState([]);
    const [filter, setFilter] = useState('Waiting Approval')

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());

    function handleStartDateChange(date) {
        setStartDate(date);
        sessionStorage.setItem('startDate', date);
    }

    function handleEndDateChange(date) {
        setEndDate(date);
        sessionStorage.setItem('endDate', date);
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function handleChange(value) {

        setHistoryMonitor([])

        if (value !== "Monitoring") {
            RequestToken()
        }

        setFilter(value)
    }

    function getAllHistoryByMail(accessToken, email) {

        API.historyWorkingPermit(HeaderFarms(accessToken), { startDate, endDate, email }).then(res => {
            setHistoryWP(res.data)
            setLoading(false)
        })
            .catch((err) => {
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
                Loading.remove();
            });
    }

    function getAllHistory(accessToken, startDate, endDate) {

        API.historyWorkingPermitMonitor(HeaderFarms(accessToken), { startDate: startDate, endDate: endDate }).then(res => {
            setHistoryMonitor(res.data)
            setLoading(false)
        })
            .catch((err) => {
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
                Loading.remove();
            });

    }

    function search() {

        setLoading(true)

        if (filter === "Monitoring") {
            handleFilterByDate()
        } else {
            RequestToken()
        }
    }

    function handleFilterByDate() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                callMsGraph(responseToken.accessToken).then((_) => {

                    // Convert start and end dates to JavaScript Date objects
                    const startDateFormat = new Date(startDate);
                    const endDateFormat = new Date(endDate);

                    // Set the time part of the dates to 00:00:00 for start date and 23:59:59 for end date
                    startDateFormat.setHours(0, 0, 0, 0);
                    endDateFormat.setHours(23, 59, 59, 999);

                    getAllHistory(responseToken.accessToken, startDateFormat, endDateFormat)
                });
            })
            .catch((e) => {
                handleLogin(instance)
            });
    }

    function goDetail(id) {
        if (filter === "Monitoring") {
            navigate(`/wf/wp/view/${id}?monitoring=1`);
        } else {
            navigate(`/wp/view/${id}`);
        }
    }

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                callMsGraph(responseToken.accessToken).then((response) => {
                    getAllHistoryByMail(responseToken.accessToken, response.mail)
                });
            })
            .catch((e) => {
                handleLogin(instance)
            });
    }

    useEffect(() => {

        // Check already login or not ? 
        if (filter !== "Monitoring") {
            RequestToken();
        }

        // Retrieve startDate and endDate from sessionStorage
        const savedEndDate = sessionStorage.getItem('endDate');
        const savedStartDate = sessionStorage.getItem('startDate');

        // Set startDate and endDate if they exist in sessionStorage
        if (savedStartDate) {
            setStartDate(new Date(savedStartDate));
        }
        if (savedEndDate) {
            setEndDate(new Date(savedEndDate));
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, loading,]);

    return (
        <Fragment>
            <Navbar
                title="History"
                back={true}
                subtitle="Working Permit"
                navigate="/home"

            ></Navbar>
            <div className="container white-back-cr" style={{ minHeight: 500 }}>
                <ImportantInfo></ImportantInfo>
                <div className="container mt-3 p-3">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <Affix offsetTop={10}>
                                <div className="col-md-6 d-flex justify-content-start">
                                    <Button type="primary" onClick={() => navigate("/wp/create")} size="middle">
                                        New Working Permit
                                    </Button>
                                </div>
                            </Affix>
                        </div>
                    </div>
                    <div className="row mb-5 mt-3">

                        <div className="col">
                            <DatePicker
                                size="small"
                                className="m-1"
                                onChange={handleStartDateChange}
                                placeholder="Select Date"
                            // value={moment(startDate)}
                            />
                            <DatePicker
                                size="small"
                                className="m-1"
                                onChange={handleEndDateChange}
                                placeholder="End Date"
                            // value={moment(endDate)}
                            />
                            <Button className="mt-2 m-1" type="primary" onClick={search} size="small">
                                Cari
                            </Button>
                            {/* <Button className="mt-2 m-1" type="primary" onClick={searchToday} size="small">
                                Hari ini
                            </Button> */}
                        </div>

                        <div className="col-md-12 d-flex justify-content-end mt-4">
                            <label style={{ marginRight: 10, marginTop: 5 }}>Filter</label>
                            <Select
                                defaultValue="Waiting"
                                style={{
                                    width: 120,
                                }}
                                onChange={handleChange}
                            >
                                <Option value="Waiting Approval">Waiting</Option>
                                <Option value="Approved">Approved</Option>
                                <Option value="Reject">Reject</Option>
                                <Option value="Closed">Closed</Option>
                                <Option value="Cancel">Cancel</Option>
                                <Option value="Monitoring">Monitoring</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            {/* Monitor */}
                            {
                                historyMonitor.length > 0 &&
                                historyMonitor.map(function (data) {
                                    console.log(data)
                                    if (data.approval_process_id?.status === 'Approved' && data.status === "Open") {

                                        return (

                                            <Fragment key={data.id_record}>
                                                <Badge.Ribbon color="#C7DDFF" text={<Badge status="processing" text="In Progress" />}>
                                                    <div
                                                        className="list-style p-3 px-4"
                                                        onClick={() => goDetail(data.id_record)}
                                                    >
                                                        <div className="title mb-4">
                                                            <p className="fs-7 mb-0">{data.id_record}</p>
                                                        </div>
                                                        <div className="hour d-flex justify-content-between m-1">
                                                            <p className="fs-7">
                                                                <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment.utc(data.created_at).format('YYYY-MM-DD')}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Badge.Ribbon>
                                            </Fragment>
                                        )
                                    } else {
                                        return (
                                            <Fragment key={data.id_record}>
                                                <Badge.Ribbon text={data.approval_process_id.status}>
                                                    <div
                                                        className="list-style p-3 px-4 mb-3"
                                                        onClick={() => goDetail(data.id_record)}
                                                    >
                                                        <div className="title mb-4">
                                                            <p className="fs-7 mb-0">{data.id_record} {data.approval_process_id.status === "Waiting Approval" && data.status === "Closed" ? '( Completed Work)' : ''}</p>
                                                        </div>
                                                        <div className="hour d-flex justify-content-between m-1">
                                                            <p className="fs-7">
                                                                <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment.utc(data.created_at).format('YYYY-MM-DD')}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Badge.Ribbon>
                                            </Fragment>
                                        )
                                    }

                                })
                            }

                            {
                                historyWP.length > 0 &&
                                historyWP.map(function (data) {
                                    if (filter === 'Waiting Approval') {
                                        if (data.approval_process_id?.status === 'Approved' && data.status === "Open") {

                                            return (
                                                <Fragment key={data.id_record}>
                                                    <Badge.Ribbon color="#C7DDFF" text={<Badge status="processing" text="In Progress" />}>
                                                        <div
                                                            className="list-style p-3 px-4"
                                                            onClick={() => goDetail(data.id_record)}
                                                        >
                                                            <div className="title mb-4">
                                                                <p className="fs-7 mb-0">{data.id_record}</p>
                                                            </div>
                                                            <div className="hour d-flex justify-content-between m-1">
                                                                <p className="fs-7">
                                                                    <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment.utc(data.created_at).format('YYYY-MM-DD')}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Badge.Ribbon>
                                                </Fragment>
                                            )
                                        }
                                    }

                                })
                            }

                            {

                                historyWP.length > 0 ?
                                    historyWP.map(function (data) {
                                        if (filter === 'Waiting Approval') {
                                            if (data.approval_process_id?.status === 'Waiting Approval') {

                                                return (
                                                    <Fragment key={data.id_record}>
                                                        <Badge.Ribbon color="#FF9900" text={data.approval_process_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.id_record)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.id_record} {data.approval_process_id.status === "Waiting Approval" && data.status === "Closed" ? '( Completed Work)' : ''}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7">
                                                                        <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment.utc(data.created_at).format('YYYY-MM-DD')}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Approved') {
                                            if (data.approval_process_id.status === 'Approved') {
                                                return (
                                                    <Fragment key={data.id_record}>
                                                        <Badge.Ribbon color="blue" text={data.approval_process_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.id_record)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.id_record}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7">
                                                                        <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment(data.created_at).format('LLL')}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Reject') {
                                            if (data.approval_process_id.status === 'Reject') {
                                                return (
                                                    <Fragment key={data.id_record}>
                                                        <Badge.Ribbon color="red" text={data.approval_process_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.id_record)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.id_record}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7">
                                                                        <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment(data.created_at).format('LLL')}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Cancel') {
                                            if (data.approval_process_id.status === 'Cancel') {
                                                return (
                                                    <Fragment key={data.id_record}>
                                                        <Badge.Ribbon color="red" text={data.approval_process_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.id_record)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.id_record}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7">
                                                                        <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment(data.created_at).format('LLL')}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Closed') {
                                            if (data.status === 'Closed') {
                                                return (
                                                    <Fragment key={data.id_record}>
                                                        <Badge.Ribbon color="red" text={data.approval_process_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.id_record)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.id_record}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7">
                                                                        <span style={{ color: "#1890FF", fontSize: 13, fontWeight: "bold" }}>{data.status}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}>{moment(data.created_at).format('LLL')}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }
                                        return null;
                                    })
                                    :
                                    !loading && (
                                        <>
                                            {/* <Empty className="mt-5" /> */}
                                        </>
                                    )
                            }

                            {
                                historyWP.length === 0 &&
                                (
                                    <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                        No more data
                                    </div>
                                )

                            }
                            {
                                loading && (
                                    <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                        <Spin size="large" />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </Fragment>
    );
}

const reduxState = (state) => ({
    initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
    setInitializeTicket: (navigate, data) =>
        dispatch(InitializeTicket(navigate, data)),
});

export default connect(reduxState, reduxDispatch)(WPHistory);
