import React, { useEffect } from 'react';

import { Layout, Button, Collapse, Form, Input, message, Row, Col, Select } from "antd";

import { CheckCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import Navbar from '../../../atoms/navbar';

import { Confirm, Loading, Report } from 'notiflix';
import HeaderFarms from '../../../utils/header_farms';
import { useNavigate } from 'react-router';
import API from '../../../services';

function CreateOperator() {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { Panel } = Collapse;

    const onFinish = (data) => {
        Confirm.show(
            'Konfirmasi Penting',
            'Apakah Anda yakin?',
            'Ya',
            'Tidak',
            () => {
                Loading.hourglass("Mohon tunggu sedang diproses..");

                // Panggil API CreateOperator dengan menggunakan token yang diperoleh
                API.CreateOperator(HeaderFarms(), data)
                    .then((response) => {
                        Loading.remove();
                        if (response && response.code === 200) {
                            navigate("/200")
                        } else if (response && response.code === 400) {
                            Report.info(
                                "Employee ID sudah terdaftar!",
                                "Employee ID yang Anda masukkan sudah terdaftar.",
                                "Okay"
                            );
                        } else {
                            Report.warning(
                                "Oops.. terjadi kesalahan",
                                "Maaf, terjadi kesalahan saat membuat operator.",
                                "Okay"
                            );
                        }
                    })
                    .catch((error) => {
                        Loading.remove();
                        console.error(error);
                        Report.warning(
                            "Oops.. terjadi kesalahan",
                            "Maaf, aplikasi ini mengalami masalah",
                            "Okay"
                        );

                    });
            }
        );
    };

    const resetPage = () => {
        navigate("/fms/operator/reset")
    }

    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    useEffect(() => {

    }, []);

    return (
        <>
            {/* <Navbar
                title="Create"
                back={true}
                subtitle="Fuel System"
                navigate="/fms/menu"
            ></Navbar> */}
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="operator_register"
                    initialValues={{
                        remember: true,
                    }}
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='middle'
                    layout='vertical'
                >
                    <div className="p-2" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Register Operator</b>} key="2">
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="ID Karyawan"
                                                    name="employee_id"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="ID Simper"
                                                    name="company_driver_license"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Nama Lengkap"
                                                    name="fullname"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Perusahaan"
                                                    name="company"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Select>
                                                        <Select.Option value="SUMAGUD SAPTA SINA">SUMAGUD SAPTA SINA</Select.Option>
                                                        <Select.Option value="CITRA PALU MINERALS">CITRA PALU MINERALS</Select.Option>
                                                        <Select.Option value="MACMAHON MINNING SERVICE">MACMAHON MINNING SERVICE</Select.Option>
                                                        <Select.Option value="ADIJAYA KARYA MAKMUR">ADIJAYA KARYA MAKMUR</Select.Option>
                                                        <Select.Option value="PETRAMAS HARJA SEJAHTERA">PETRAMAS HARJA SEJAHTERA</Select.Option>
                                                        <Select.Option value="PESONA PRIMA UTAMA">PESONA PRIMA UTAMA</Select.Option>
                                                        <Select.Option value="KOPERASI MOSINGGANI">KOPERASI MOSINGGANI</Select.Option>
                                                        <Select.Option value="PARTS SENTRA MANDIRI">PARTS SENTRA MANDIRI</Select.Option>
                                                        <Select.Option value="Other">Lain Lain</Select.Option>
                                                    </Select>
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Nomor Handphone"
                                                    name="phonenumber"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                    normalize={(value) => {
                                                        // Normalisasi nilai input
                                                        if (value.startsWith('0')) {
                                                            return value.replace(/^0/, '62');
                                                        }
                                                        return value;
                                                    }}
                                                >
                                                    <Input placeholder="Contoh: 081234567890" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Email ( Email pribadi / kantor )"
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Input type={"email"} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col>
                                                <Form.Item className='mt-3'>
                                                    <Button type="primary" size="large" htmlType="submit" icon={<CheckCircleOutlined />}>
                                                        Register
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            {/* <Col style={{ marginLeft: 12 }}>
                                                <Form.Item className='mt-3'>
                                                    <Button type="primary" onClick={resetPage} size="large" icon={<RetweetOutlined />}>
                                                        Lupa Password
                                                    </Button>
                                                </Form.Item>
                                            </Col> */}
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            {/* <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2024 Information Technology</Layout.Footer> */}
        </>
    );
}
export default CreateOperator