import React, { useState, useEffect } from 'react';
import { Typography, Col, Row } from "antd";
import { QRCodeSVG } from 'qrcode.react';
import { Report } from "notiflix";
import "./styles.css";

import HeaderFarms from "../../../utils/header_farms";
import { useParams } from "react-router";
import API from "../../../services";
import numeral from 'numeral';
import moment from "moment";


const ViewTAPrint = () => {

    const [, setStatusColor] = useState({ background: null, color: null })
    const [TA, setTA] = useState(null);
    const { Title } = Typography;
    let { id } = useParams();

    const getDetailTA = (token) => {
        API.PrintTA(HeaderFarms(token), id).then(((res) => {
            setTA(res)
            styleStatus(res.data)

            setTimeout(() => {
                window.print();
            }, 1000)

        })).catch((err) => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const styleStatus = (res) => {
        let status = res.approval_process_id.status;

        if (status === "Waiting Approval") {
            setStatusColor({
                background: "#FF9900",
                color: "#FFFFFF"
            })
        } else if (status === "Approved") {
            setStatusColor({
                background: "#1890FF",
                color: "#FFFFFF"
            })
        } else {
            setStatusColor({
                background: "#F5222D",
                color: "#FFFFFF"
            })
        }
    }

    function getUserList() {
        const userList = TA && TA.data.t_ta_user_dtl.map((user) => {
            let { expense_type, reason_for_travel, departure_date_end, departure_date_start, fieldbreak_date_end, fieldbreak_date_start } = user;
            const { fullname, employee_status } = user.user_id;

            const level = user.user_id.level && user.user_id.level.fullname

            const { code } = user.user_id.company;

            departure_date_start = moment(departure_date_start || fieldbreak_date_start).format('DD-MMM-YY');
            departure_date_end = moment(departure_date_end || fieldbreak_date_end).format('DD-MMM-YY');

            return { code, fullname, level, employee_status, expense_type, reason_for_travel, departure_date_end, departure_date_start };
        });

        return userList;
    }

    function removeDuplicatesByProps(array, props) {
        if (!array) {
            return [];
        }

        return array.filter((item, index, self) =>
            index === self.findIndex((el) => (
                props.every((prop) => el[prop] === item[prop])
            ))
        );
    }

    function formatFlight(flight) {
        const from_date = moment(flight.from_date).format('DD-MMM-YY');
        const from = flight.from;
        const to = flight.to;
        const time = flight.time;
        const airline = flight.airline;
        const _id = flight._id;
        const id_record = flight.t_ta_user_dtl_id;
        const e_ticket = flight.e_ticket;
        const flight_id = flight.flight_id;
        const ticket_price = flight.ticket_price;
        const seat_class = flight.seat_class;

        return {
            from_date,
            from,
            to,
            time,
            airline,
            _id,
            id_record,
            e_ticket,
            flight_id,
            ticket_price,
            seat_class
        };
    }

    function formatAccommodation(accommodations) {
        const dateIn = moment(accommodations.accomodation_date_in).format('DD-MMM-YY');
        const dateOut = moment(accommodations.accomodation_date_out).format('DD-MMM-YY');
        const accommodation = accommodations.accomodation;
        const _id = accommodations._id
        return { dateIn, dateOut, accommodation, _id };
    }

    function formatCostEst(costEst) {
        const { item, total, _id } = costEst;
        const formated = numeral(total).format('0,0');
        return { item, total, formated, _id };
    }

    function getFlightList() {
        const flights = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
            user.t_ta_rsv_dst_dtl.map(formatFlight)
        );

        const uniqueFlights = removeDuplicatesByProps(flights, ['from_date', 'from', 'to', 'time', 'airline', '_id', 'id_record', 'e_ticket', 'flight_id', 'ticket_price', 'seat_class']);

        return uniqueFlights;
    }

    function getAccommodationDates() {
        const accommodationDates = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
            user.t_ta_rsv_hotel_dtl.map(formatAccommodation)
        );

        const uniqueAccommodationDates = removeDuplicatesByProps(accommodationDates, ['dateIn', 'dateOut', 'accommodation', '_id']);

        return uniqueAccommodationDates;
    }

    function getAccommodationMessDates() {

        const accommodationMessDates = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
            user.t_ta_rsv_mess_dtl.map(accomodation => {
                const traveller = user.user_id.fullname
                const messName = accomodation.mess_name

                return { traveller, messName };
            })
        );

        const uniqueAccommodationMess = removeDuplicatesByProps(accommodationMessDates, ['traveller', 'messName'])

        return uniqueAccommodationMess;
    }

    function getCostEstlist() {
        const costEstList = TA && TA.data.t_ta_user_dtl.flatMap((userDtl) =>
            userDtl.t_ta_cost_est_dtl.map(formatCostEst)
        );

        const uniqueCostEstList = removeDuplicatesByProps(costEstList, ['item', 'total', 'formated', '_id']);

        return uniqueCostEstList;
    }

    function getObjectiveList() {
        const objectiveList = [];

        if (TA && TA.data.t_ta_user_dtl) {
            const userDtlList = TA.data.t_ta_user_dtl;
            for (let i = 0; i < userDtlList.length; i++) {
                const userDtl = userDtlList[i];
                if (userDtl.objective) {
                    const fullName = userDtl.user_id.fullname;
                    const objective = userDtl.objective;
                    objectiveList.push({ fullName, objective });
                }
            }
        }

        return objectiveList;
    }

    useEffect(() => {
        getDetailTA(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="container">
                <div className="container mt-3 " style={{ alignSelf: "center" }}>
                    <div className="row print">
                        {
                            TA && (
                                <div className="fs-6 text-center mt-2" style={{ color: "#B7B7B7" }}>
                                    <Row>
                                        <Col span={4}>
                                            <img src="/static/media/brm-logo.16396c198e4934cc25ff.png" alt="Logo Perusahaan" width={50} />
                                        </Col>
                                        <Col span={16}>
                                            <Title level={5}>
                                                PT BUMI RESOURCES MINERALS Tbk
                                                <br />
                                                TRAVEL AUTHORITY FORM
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }

                        {getUserList() && getUserList().length > 0 && (
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Level</th>
                                                <th>Status</th>
                                                <th>Expense Type</th>
                                                <th>Reason</th>
                                                <th>Departure Start</th>
                                                <th>Departure End</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getUserList().map(user => (
                                                <tr key={user.code}>
                                                    <td>{user.code}</td>
                                                    <td>{user.fullname}</td>
                                                    <td>{user.level}</td>
                                                    <td>{user.employee_status}</td>
                                                    <td>{user.expense_type}</td>
                                                    <td>{user.reason_for_travel}</td>
                                                    <td>{user.departure_date_start}</td>
                                                    <td>{user.departure_date_end}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {getFlightList() && getFlightList().length > 0 && (
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <table className="table" style={{ marginTop: -10 }}>
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>From Date</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Time</th>
                                                <th>Airline</th>
                                                <th>Class</th>
                                                <th>Ticket Price</th>
                                                <th>Flight ID</th>
                                                <th>E - Ticket</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getFlightList().map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.from_date}</td>
                                                    <td>{item.from}</td>
                                                    <td>{item.to}</td>
                                                    <td>{item.time}</td>
                                                    <td>{item.airline}</td>
                                                    <td>{item.seat_class}</td>
                                                    <td>{numeral(item.ticket_price).format('0,0')}</td>
                                                    <td>{item.flight_id}</td>
                                                    <td className="text-center">
                                                        {
                                                            item.e_ticket && (
                                                                <QRCodeSVG size={60} style={{ border: 0, borderRadius: 2, borderStyle: "solid" }} value={`https://farms-travel-authority.s3.ap-southeast-3.amazonaws.com/${item.e_ticket}`} />
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {getAccommodationMessDates() && getAccommodationMessDates().length > 0 && (
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Mess Name</th>
                                                <th>Traveller</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAccommodationMessDates().map(item => (
                                                <tr key={item.traveller}>
                                                    <td>{item.messName}</td>
                                                    <td>{item.traveller}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {getAccommodationDates() && getAccommodationDates().length > 0 && (
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Hotel Name</th>
                                                <th>Date In</th>
                                                <th>Date Out</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAccommodationDates().map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.accommodation}</td>
                                                    <td>{item.dateIn}</td>
                                                    <td>{item.dateOut}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {getCostEstlist() && getCostEstlist().length > 0 && (
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Item</th>
                                                <th>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getCostEstlist().map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.item}</td>
                                                    <td>{numeral(item.total).format('0,0')}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td>Total Cost</td>
                                                <td>{numeral(getCostEstlist().reduce((accumulator, currentItem) => accumulator + currentItem.total, 0)).format('0,0')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {TA && (
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead style={{ backgroundColor: "#FAC20D" }}>
                                            <tr>
                                                <th>Author</th>
                                                <th>Objective</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getObjectiveList().map(item => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.fullName}</td>
                                                        <td>{item.objective}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {TA && TA.data.approval_process_id.approval_key && (
                            <>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <QRCodeSVG size={100} style={{ border: 0, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${TA.data.approval_process_id.approval_key}`} />
                                            <br></br>
                                            <span style={{ fontWeight: "bold" }}>Approval Barcode</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

export default ViewTAPrint;
