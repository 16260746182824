import { useNavigate, useParams } from 'react-router';
import { PageHeader, Result, Button } from 'antd';
import React, { useEffect } from 'react';
import { connect } from "react-redux";

import { initializeVerification } from '../../redux/action';
import { Loading, Notify, Report } from 'notiflix';

import HeaderFarms from '../../utils/header_farms';
import Footer from '../../atoms/footer';
import WaLogo from './change/walogo.png'
import API from '../../services';
import './style.css'

function Verification() {

    let navigate = useNavigate();
    let { id, token } = useParams();

    const setVerification = () => {
        Loading.hourglass('Just a moment');
        API.waVerification(HeaderFarms(token), {
            number: id
        }).then(res => {
            Loading.remove()
            Report.success(
                `Activation Success`,
                ``,
                "Okay",
                function () {
                    navigate('/home')
                }
            );
        }).catch(err => {

            if (err.response.status === 401) {
                Notify.warning('Waktu verifikasi telah habis.', {
                    timeout: 10000,
                    position: 'center-top'
                });
                navigate('/home')
            } else {
                Loading.remove()
                Notify.warning('Nomor whatsapp tidak di temukan mohon untuk mengisi kembali nomor whatsapp anda', {
                    timeout: 10000,
                    position: 'center-top'
                });
                navigate('/verification/change/' + token)
            }
        })
    }

    const changeNumber = () => {
        navigate('/verification/change/' + token)
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <div className='container' style={{ minHeight: "700px" }}>
                <div className='row'>
                    <div className='col-md-12'>
                        <PageHeader
                            ghost={true}
                            title="Whatsapp Verification"
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Result
                            status="info"
                            icon={<img src={WaLogo} width="120px" alt='' />}
                            title={`${(id)} Apakah nomor ini adalah Whatsapp pribadi anda`}
                            extra={<><Button className='mt-5' size='large' onClick={() => setVerification()} type="primary">Benar</Button><Button className='mt-5' size='large' onClick={() => changeNumber()} type="link">Bukan</Button></>}
                        />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

const reduxState = (state) => ({
    // initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
    setInitializeVerification: (data) =>
        dispatch(initializeVerification(data)),
});

export default connect(reduxState, reduxDispatch)(Verification);
