const redirectUris = [
    {
        domain: 'farms.brmapps.com',
        uri: 'https://farms.brmapps.com/home'
    },
    {
        domain: 'farms.citrapalu.net',
        uri: 'https://farms.citrapalu.net/home'
    },
    {
        domain: 'farms.gorontalominerals.com',
        uri: 'https://farms.gorontalominerals.com/home'
    },
    {
        domain: 'farms.lingemineral.com',
        uri: 'https://farms.lingemineral.com/home'
    },
    {
        domain: 'farms.sumaheksa.com',
        uri: 'https://farms.sumaheksa.com/home'
    },
    {
        domain: 'farmsdev.gorontalominerals.com',
        uri: 'https://farmsdev.gorontalominerals.com/home'
    },
    {
        domain: 'farmsdev.brmapps.com',
        uri: 'https://farmsdev.brmapps.com/home'
    },
    {
        domain: 'farmsdev.citrapalu.net',
        uri: 'https://farmsdev.citrapalu.net/home'
    },
    {
        domain: 'farmsdev.lingemineral.com',
        uri: 'https://farmsdev.lingemineral.com/home'
    },
    {
        domain: 'farmsdev.sumaheksa.com',
        uri: 'https://farmsdev.sumaheksa.com/home'
    },
    {
        domain: 'localhost',
        uri: 'http://localhost:3000/home'
    },
    {
        domain: 'master.d2agatbkcbzfun.amplifyapp.com',
        uri: 'https://farms.brmapps.com/home'
    },
    {
        domain: 'hias.citrapalu.net',
        uri: 'https://farms.citrapalu.net/home'
    },
    {
        domain: 'hias.gorontalominerals.com',
        uri: 'https://farms.gorontalominerals.com/home'
    },
    {
        domain: 'operator.citrapalu.net',
        uri: 'https://farms.citrapalu.net/home'
    },
    {
        domain: 'fuel.citrapalu.net',
        uri: 'https://fuel.citrapalu.net/fms/menu'
    },
    {
        domain: 'backdaterf.citrapalu.net',
        uri: 'https://backdaterf.citrapalu.net/fms/menu'
    },
    {
        domain: 'backdatedr.citrapalu.net',
        uri: 'https://backdatedr.citrapalu.net/fms/menu'
    },
    {
        domain: 'https://master.d1ss9qclzpb1pu.amplifyapp.com',
        uri: ' https://master.d1ss9qclzpb1pu.amplifyapp.com'
    },
];

let redirectUri = null;

redirectUris.forEach(redirect => {
    if (window.location.hostname === redirect.domain) {
        redirectUri = redirect.uri;
    }
});

if (!redirectUri) {
    redirectUri = 'http://localhost:3000/home';
}

export default redirectUri;
