import { DatePicker, Descriptions, Empty, Form, Radio, Button } from "antd";
import Notiflix, { Confirm, Loading, Report } from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { useNavigate } from "react-router";
import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import HeaderFarms from "../../../utils/header_farms";
import { callMsGraph } from "../../../utils/graph";

function TSCreate() {
  const [publicHoliday, setPublicHoliday] = useState(null);
  const [workPublciHoliday, setWorkPublciHoliday] = useState(0);

  // hardcode
  const [currentUnit, setCurrentUnit] = useState(null);
  const [listDatePeriodCutoffPayroll, setListDatePeriodCutoffPayroll] =
    useState([]);
  const [captionPeriod, setCaptionPeriod] = useState(null);

  const [totalBusinessTrip, setTotalBusinessTrip] = useState(0);
  const [totalPublicHoliday, setTotalPublicHoliday] = useState(0);
  const [totalWeekOnSite, setTotalWeekOnSite] = useState(0);
  const [totalHomeBase, setTotalHomeBase] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalLeave, SetTotalLeave] = useState(0);
  const [totalSick, SetTotalSick] = useState(0);
  const [totalDayOff, SetTotalDayOff] = useState(0);

  const [outstandingLastMonth, setOutstandingLastMonth] = useState(0);
  const [outstandingThisMonth, setOutstandingThisMonth] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(0);
  const [hiringPlace, setHiringPlace] = useState(0);
  const [jobLevel, setJobLevel] = useState(null);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [monthApproved, setMonthApproved] = useState(null);
  const monthPick = useRef(null);

  let navigate = useNavigate();

  const onChangePeriode = (_, dateStringFromOnChange) => {
    let { firstDate, endDate } = getDatePeriodCutOffPayroll(
      dateStringFromOnChange
    );

    renderDatePeriod(firstDate, endDate);

    resetAttandanceRecap();
  };

  const getDatePeriodCutOffPayroll = (dateStringFromOnChange) => {
    const cutOffPayrollDateStart = "17";
    const cutOffPayrollDateEnd = "16";

    let changeMonth = new Date(
      new Date(dateStringFromOnChange).setMonth(
        new Date(dateStringFromOnChange).getMonth() - 1
      )
    );

    const firstDate = `${changeMonth.getFullYear()}-${(
      "0" +
      (changeMonth.getMonth() + 1)
    ).slice(-2)}-${cutOffPayrollDateStart}`;

    const endDate = `${dateStringFromOnChange}-${cutOffPayrollDateEnd}`;

    return { firstDate, endDate };
  };

  const resetAttandanceRecap = () => {
    setTotalBusinessTrip(0);
    setTotalWeekOnSite(0);
    setTotalHomeBase(0);
    setTotalPresent(0);
    SetTotalDayOff(0);
    SetTotalLeave(0);
    SetTotalSick(0);
  };

  const getAPIHoliday = (token) => {
    let urlAPIHoliday = API.getListHoliday(HeaderFarms(token));
    urlAPIHoliday.then((res) => setPublicHoliday(res.data));
    console.log(publicHoliday);
  };

  const setHolidayByAPI = (date) => {
    let loc = null;

    publicHoliday.forEach((item) => {
      if (date === item.date) {
        loc = String(item.localName);
      }

      // get newyear, because only check date and month, not year
      if (date.substring(5, 10) === item.date.substring(5, 10)) {
        loc = String(item.localName);
      }
    });

    return loc;
  };

  const setHolidayDate = (datePeriod, id) => {
    for (let i = 0; i < publicHoliday.length; i++) {
      if (publicHoliday[i].date === datePeriod) {
        return id - 1 + "_HOLIDAY";
      }
    }
    return null;
  };

  const renderDatePeriod = (startDate, endDate) => {
    let dateFrom = new Date(startDate);
    let dateTo = new Date(endDate);
    let dateLoop = [];
    let id = 1;

    for (let i = dateFrom; i <= dateTo; i.setDate(i.getDate() + 1)) {
      let date_item = moment(new Date(i)).format("YYYY-MM-DD");

      let holiday_declare = null;
      let currentUnitEmp = null;

      // Set weekend & Holiday value
      if (setWeekendDate(date_item)) {
        holiday_declare = id - 1 + "_HOLIDAY";
      } else {
        let holidayName = setHolidayDate(date_item, id);
        holiday_declare = holidayName;
      }

      let data = {
        ts_row_id_dtl: id,
        ts_date_dtl: date_item,
        ts_loc_dtl: currentUnitEmp,
        ts_reason_dtl: holiday_declare,
        ts_note_dtl: null,
      };

      dateLoop.push(data);
      id++;
    }

    setListDatePeriodCutoffPayroll(dateLoop);
    setCaptionPeriod({ startDate, endDate });

    getAPIHoliday(startDate, endDate)

    countingPublicHolidayPeriod(dateLoop);
    countingEffectiveDate(dateLoop);
    monthPick.current.blur();
  };

  const getUserCompany = (token) => {
    API.getUserCredential(HeaderFarms(token), { access_token: token })
      .then((res) => {
        setCurrentUnit(res.company.code);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFormChange = (index, event) => {
    // copy data from state
    let data = [...listDatePeriodCutoffPayroll];

    if (event.target.name === "ts_loc_dtl") {
      // remove value if select Presence
      data[index]["ts_reason_dtl"] = null;
    } else {
      //fix on input change
      if (event.target.name !== "ts_note_dtl") {
        // remove value if select Absence
        data[index]["ts_loc_dtl"] = null;
      }
    }

    // change item
    data[index][event.target.name] = event.target.value;

    // Save again and then count
    setListDatePeriodCutoffPayroll(data);
    // COUNT UPDATE Attendance Recap EVERY CHANGE THE RADIO BUTTON
    sumAll();
  };
  
  let sumAll = () => {
    // Count After save
    countingDayBusinessTripAndHeadOffice();
    countingDayTotalPresent();
    countingDayHomebase();
    countingDaySick();
    countingDaySite();
    countingDayLeave();
    countingDayOff();
  };

  let setWeekendDate = function (date) {
    let dt = new Date(date);
    if (dt.getDay() === 6 || dt.getDay() === 0) {
      return true;
    }

    return false;
  };

  function countingDayOff() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      if (item.ts_reason_dtl) {
        let split = item.ts_reason_dtl.split("_")[1];
        if (split === "HOLIDAY") {
          count++;
        }
      }
    });
    SetTotalDayOff(count);
  }

  function countingPublicHolidayPeriod(listDatePeriodCutoffPayroll) {
    let count = 0;
    if (listDatePeriodCutoffPayroll)
      listDatePeriodCutoffPayroll.forEach((date) => {
        publicHoliday.forEach((dateHoliday) => {
          if (date.ts_date_dtl === dateHoliday.date.substring(0, 10)) count++;
        });
      });
    setTotalPublicHoliday(count);
    // console.log(count, 'public holiday')
  }

  function countingEffectiveDate(listDatePeriodCutoffPayroll) {
    let effectiveDate = 0;
    listDatePeriodCutoffPayroll.forEach((date) => {
      if (date.ts_reason_dtl === null) {
        effectiveDate++;
      }
    });
    setEffectiveDate(effectiveDate);
  }

  function countingOutstandingThisMonth(totalPresent) {
    let getWorkPublciHoliday = 0
    listDatePeriodCutoffPayroll.forEach((date) => {
      publicHoliday.forEach((dateHoliday) => {
        if (date.ts_date_dtl === dateHoliday.date.substring(0, 10)){
          if(date.ts_loc_dtl !== null) getWorkPublciHoliday++;
        }
      })
    })
    setWorkPublciHoliday(getWorkPublciHoliday)
    let getCountSickThisMonth = listDatePeriodCutoffPayroll
      .filter(item => item.ts_reason_dtl?.split("_")[1] === 'SICK')

    let getCountHolidayThisMonth = listDatePeriodCutoffPayroll
      .filter(item => item.ts_reason_dtl?.split("_")[1] === 'HOLIDAY')
    if (hiringPlace === 'Lokal' && jobLevel === 'Officer') {
      setOutstandingThisMonth(
        parseFloat((totalPresent + getCountSickThisMonth.length) - effectiveDate)
      )
    } else if (hiringPlace === 'Non Lokal') {
      let count = 0;
      count = (totalPresent * 2) / 5;
      count = (count - getCountHolidayThisMonth.length + getWorkPublciHoliday).toFixed(2);
      setOutstandingThisMonth(parseFloat(count));
    } else {
      setOutstandingThisMonth(0);
    }
    
  }

  function countingDayTotalPresent() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      // if location select != null, count all
      if (item.ts_loc_dtl) {
        count++;
      }
    });
    setTotalPresent(count);
    countingOutstandingThisMonth(count);
  }

  function countingDaySite() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      if (item.ts_loc_dtl) {
        let split = item.ts_loc_dtl.split("_")[1];
        if (split !== currentUnit) {
          count++;
        }
      }
    });
    setTotalWeekOnSite(count);
  }

  function countingDayHomebase() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      if (item.ts_loc_dtl) {
        let split = item.ts_loc_dtl.split("_")[1];
        if (split === currentUnit) {
          count++;
        }
      }
    });
    setTotalHomeBase(count);
  }

  function countingDayBusinessTripAndHeadOffice() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      if (item.ts_loc_dtl) {
        let split = item.ts_loc_dtl.split("_")[1];
        if (split === "BUSINESSTRIP" || split === "BRM") {
          count++;
        }
      }
    });

    setTotalBusinessTrip(count);
  }

  function countingDayLeave() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      if (item.ts_reason_dtl) {
        let split = item.ts_reason_dtl.split("_")[1];
        if (split === "LEAVE") {
          count++;
        }
      }
    });
    SetTotalLeave(count);
  }

  function countingDaySick() {
    let count = 0;
    listDatePeriodCutoffPayroll.forEach((item) => {
      if (item.ts_reason_dtl) {
        let split = item.ts_reason_dtl.split("_")[1];
        if (split === "SICK") {
          count++;
        }
      }
    });
    SetTotalSick(count);
  }

  const sendTSToServer = (data) => {
    let DTO = [];
    listDatePeriodCutoffPayroll.forEach((item) => {
      DTO.push({
        ts_row_id_dtl: item.ts_row_id_dtl,
        ts_date_dtl: item.ts_date_dtl,
        ts_loc_dtl: item.ts_loc_dtl ? item.ts_loc_dtl.split("_")[1] : null,
        ts_reason_dtl: item.ts_reason_dtl
          ? item.ts_reason_dtl.split("_")[1]
          : null,
        ts_note_dtl: item.ts_note_dtl,
      });
    });

    let DATA = {
      // email: data.email,
      year: moment(captionPeriod.startDate).format("YYYY"),
      date_from: moment(captionPeriod.startDate).format("YYYY-MM-DD"),
      date_to: moment(captionPeriod.endDate).format("YYYY-MM-DD"),
      day_count: listDatePeriodCutoffPayroll.length,
      total_work: totalPresent,
      total_site: totalWeekOnSite,
      total_sick: totalSick,
      total_leave: totalLeave,
      total_home_base: totalHomeBase,
      total_permit: totalBusinessTrip,
      outstanding_this_month: outstandingThisMonth,
      business_trip: totalBusinessTrip,
      outstanding_last_month: outstandingLastMonth,
      ttimesheetdetails: DTO,
    };


    API.createTimeSheet(HeaderFarms(data.new_token), DATA)
      .then((res) => {
        Report.success(
          `Create Timesheet Success`,
          `Timesheet anda berhasil di simpan`,
          "Okay",
          function () {
            navigate("/ts/history");
          }
        );
        Loading.remove();
      })
      .catch((err) => {
        console.error(err);

        if (err.response.data.code === 401) {
          return Report.warning(
            "Approval Line belum tersedia",
            "Mohon hubungi departement HR",
            "Okay"
          );
        }

        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
        Loading.remove();
      });
  };

  const getEmployeeByEmployeeId = async (token, employee_id) => {
    try {
      const employee = await API.getEmployeeTalentaByEmployeeId(
        HeaderFarms(token),
        employee_id
      );
      return employee;
    } catch (err) {
      console.error(err);

      Report.warning(
        "Oops.. something wrong",
        "Sorry, failed get talenta by employee id",
        "Okay"
      );
    }
  };

  const setStatusHiringPlaceAndJobLevel = async (token) => {
    try {
      const getSession = JSON.parse(localStorage.getItem("session_signin")).nik;

      let idRowEmployee = await getEmployeeByEmployeeId(token, getSession);

      const resultDetailResponse = await API.getEmployeeTalentaByid(
        HeaderFarms(token),
        idRowEmployee.employee.data.employment.id
      );

      const getHiringPlace =
        resultDetailResponse.employee.data.employee.custom_field[4].value;

      const getJobLevel =
        resultDetailResponse.employee.data.employee.employment.job_level;
      setHiringPlace(getHiringPlace);
      setJobLevel(getJobLevel);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, setStatusHiringPlaceAndJobLevel error",
        "Okay"
      );
    }
  };

  const FormValidation = () => {
    let error = "";

    listDatePeriodCutoffPayroll.forEach((item) => {
      if (
        moment(item.ts_date_dtl).isBetween(
          "2023-04-19",
          "2023-04-25",
          "2023-06-02",
          null,
          "[]"
        )
      ) {
        // Skip validation for April 19 to 25
        return;
      }

      if (!item.ts_loc_dtl && !item.ts_reason_dtl) {
        error +=
          "<li>" +
          moment(item.ts_date_dtl).format("MMM - DD") +
          " -> Not Selected</li>";
      }
    });

    Notiflix.Report.init({
      plainText: false,
      messageMaxLength: 50000,
    });

    if (error.length > 0) {
      Report.warning(
        "Timesheet submit failed",
        `<p>Please check your input</p>
        <ol>${error}</ol>`,
        "Okay"
      );
      return false;
    } else return true;
  };

  const onFinish = (data) => {
    if (FormValidation())
      Confirm.show(
        "Important Confirmation",
        "Are you sure ? please make sure your timesheet is correct.",
        "Yes",
        "No",
        () => {
          Loading.hourglass("Just a Moment");
          // get new token
          setTimeout(() => {
            RequestToken();
          }, 2000);
        }
      );
  };

  function requestTokenLogin() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        callMsGraph(responseToken.accessToken).then((response) => {
          // send token for submit ts
          getUserCompany(responseToken.accessToken);
        });
      })
      .catch((err) => {
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
        Loading.remove();
      });
  }

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        callMsGraph(responseToken.accessToken).then((response) => {
          // send token for submit ts
          sendTSToServer({
            new_token: responseToken.accessToken,
            email: response.mail,
          });
        });
      })
      .catch((err) => {
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
        Loading.remove();
      });
  }

  function RequestTokenForDate() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        callMsGraph(responseToken.accessToken).then((response) => {
          getAllHistory(responseToken.accessToken, response.mail);
          setStatusHiringPlaceAndJobLevel(responseToken.accessToken);
          getLastOutstanding(responseToken.accessToken);
          getAPIHoliday(responseToken.accessToken);

        });
      })
      .catch((err) => {
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  }

  function getAllHistory(accessToken, email) {
    let date = [];
    API.historyTimeSheet(HeaderFarms(accessToken), email)
      .then((res) => {
        // get YEAR-MONTH based on status Approved
        res.forEach((item) => {
          if (
            item.approval_process.status === "Approved" ||
            item.approval_process.status === "Waiting Approval"
          ) {
            date.push(moment(item.date_to).format("YYYY-MM"));
          }
        });
        setMonthApproved(date);
      })
      .catch((err) => {
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  }

  function getLastOutstanding(accessToken, email) {
    API.getLastOutstanding(HeaderFarms(accessToken), email)
      .then((res) => {
        setOutstandingLastMonth(parseFloat(res.data?.balance || 0));
      })
      

      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, failed get outstading",
          "Okay"
        );
      });
  }

  const disabledDate = (current) => {
    let month = monthApproved ? monthApproved : [];
    let index = month.findIndex(
      (date) => date === moment(current).format("YYYY-MM")
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    // Check already login or not ?
    if (!isAuthenticated) {
      navigate("/");
    } else {
      if (!monthApproved) {
        RequestTokenForDate();
      }
    }

    Loading.remove();

    // for get api holiday
    getAPIHoliday();

    // request Token for login get User company
    requestTokenLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobLevel, hiringPlace]);

  return (
    <>
      <Navbar
        title="Create"
        back={true}
        subtitle="Timesheet"
        navigate="/ts/history"
      ></Navbar>
      <div className="container white-back-cr" style={{ minHeight: 500 }}>
        <div className="container mt-3 p-3">
          <div className="row mb-1">
            <div className="col-md-12">
              <Form
                name="basic"
                layout="inline"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <Form.Item label="Periode" name="period">
                  <DatePicker
                    ref={monthPick}
                    disabled={disabledDate ? false : true}
                    picker="month"
                    disabledDate={disabledDate}
                    onChange={onChangePeriode}
                  />
                </Form.Item>
              </Form>
              <hr></hr>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <Form size="small" onFinish={onFinish}>
                <div className="table-responsive">
                  {listDatePeriodCutoffPayroll.length > 0 ? (
                    <table className="table table-bordered table-striped">
                      <caption
                        style={{
                          captionSide: "top",
                          marginBottom: 15,
                          color: "#212529",
                        }}
                      >
                        {moment(captionPeriod.startDate).format("YYYY MMMM DD")}

                        {" > "}

                        {moment(captionPeriod.endDate).format("YYYY MMMM DD")}
                      </caption>

                      <thead
                        className="table-secondary table-sticky"
                        id="theadp"
                      >
                        <tr>
                          <th
                            rowSpan={2}
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                            }}
                          >
                            Date
                          </th>
                          <th
                            colSpan={4}
                            style={{
                              textAlign: "center",
                              background: "#c5eded",
                            }}
                          >
                            Presence
                          </th>
                          <th
                            rowSpan={1}
                            colSpan={3}
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#f0b1b1",
                            }}
                          >
                            Absence
                          </th>
                          <th
                            rowSpan={2}
                            colSpan={2}
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                            }}
                          >
                            Note
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#c5eded",
                            }}
                          >
                            BRM
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#c5eded",
                            }}
                          >
                            CPM
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#c5eded",
                            }}
                          >
                            Business Trip
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#c5eded",
                            }}
                          >
                            Other
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#f0b1b1",
                            }}
                          >
                            Leave
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#f0b1b1",
                            }}
                          >
                            Sick
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center",
                              background: "#f0b1b1",
                            }}
                          >
                            {hiringPlace === "Lokal" ? "Dayoff" : "Fieldbreak"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listDatePeriodCutoffPayroll.length > 0 &&
                          listDatePeriodCutoffPayroll.map((item, i) => {
                            let backgroundColor = {};

                            if (
                              setWeekendDate(item.ts_date_dtl) ||
                              setHolidayByAPI(
                                moment(item.ts_date_dtl).format("YYYY-MM-DD")
                              )
                            ) {
                              backgroundColor = {
                                backgroundColor: "#34A853",
                                color: "#FFFFFF",
                                height: 41,
                              };
                            }

                            return (
                              <tr
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                  ...backgroundColor,
                                }}
                              >
                                <td
                                  style={{
                                    fontSize: 10,
                                    minWidth: 10,
                                    fontWeight: "bold",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  {moment(item.ts_date_dtl).format("DD")}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      size="small"
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      value={item.ts_loc_dtl}
                                      name="ts_loc_dtl"
                                    >
                                      <Radio
                                        style={
                                          item.ts_date_dtl
                                            ? { color: "#FFFFFF" }
                                            : setHolidayByAPI(
                                              moment(item.ts_date_dtl).format(
                                                "YYYY-MM-DD"
                                              )
                                            )
                                              ? { color: "#FFFFFF" }
                                              : moment(item.ts_date_dtl).isSame(
                                                "2023-09-28",
                                                "day"
                                              )
                                                ? {
                                                  color: "#999999",
                                                  cursor: "not-allowed",
                                                }
                                                : {}
                                        }
                                        value={i + "_BRM"}
                                        disabled={moment(
                                          item.ts_date_dtl
                                        ).isSame(("2023-09-28", "day"))}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      name="ts_loc_dtl"
                                      value={item.ts_loc_dtl}
                                    >
                                      <Radio
                                        style={
                                          setWeekendDate(item.ts_date_dtl)
                                            ? { color: "#FFFFFF" }
                                            : setHolidayByAPI(
                                              moment(item.ts_date_dtl).format(
                                                "YYYY-MM-DD"
                                              )
                                            )
                                              ? { color: "#FFFFFF" }
                                              : {}
                                        }
                                        value={i + "_CPM"}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      name="ts_loc_dtl"
                                      value={item.ts_loc_dtl}
                                    >
                                      <Radio
                                        style={
                                          setWeekendDate(item.ts_date_dtl)
                                            ? { color: "#FFFFFF" }
                                            : {}
                                        }
                                        value={i + "_BUSINESSTRIP"}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      name="ts_loc_dtl"
                                      value={item.ts_loc_dtl}
                                    >
                                      <Radio
                                        style={
                                          setWeekendDate(item.ts_date_dtl)
                                            ? { color: "#FFFFFF" }
                                            : setHolidayByAPI(
                                              moment(item.ts_date_dtl).format(
                                                "YYYY-MM-DD"
                                              )
                                            )
                                              ? { color: "#FFFFFF" }
                                              : {}
                                        }
                                        value={i + "_OTHER"}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      name="ts_reason_dtl"
                                      value={item.ts_reason_dtl}
                                    >
                                      <Radio
                                        style={
                                          setWeekendDate(item.ts_date_dtl)
                                            ? { color: "#FFFFFF" }
                                            : {}
                                        }
                                        value={i + "_LEAVE"}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      name="ts_reason_dtl"
                                      value={item.ts_reason_dtl}
                                    >
                                      <Radio
                                        style={
                                          setWeekendDate(item.ts_date_dtl)
                                            ? { color: "#FFFFFF" }
                                            : {}
                                        }
                                        value={i + "_SICK"}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <Radio.Group
                                      onChange={(event) =>
                                        handleFormChange(i, event)
                                      }
                                      name="ts_reason_dtl"
                                      value={item.ts_reason_dtl}
                                    >
                                      <Radio
                                        style={
                                          setWeekendDate(item.ts_date_dtl)
                                            ? { color: "#FFFFFF" }
                                            : {}
                                        }
                                        value={i + "_HOLIDAY"}
                                      ></Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td
                                  style={{
                                    width: 200,
                                    minWidth: 150,
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Form.Item className="form-item-ts">
                                    <input
                                      className="ant-input ant-input-sm"
                                      type={"text"}
                                      key={i + "-ts_note_dtl"}
                                      onBlur={(event) => {
                                        handleFormChange(i, event);
                                      }}
                                      placeholder={
                                        setHolidayByAPI(
                                          moment(item.ts_date_dtl).format(
                                            "YYYY-MM-DD"
                                          )
                                        )
                                      }
                                      name="ts_note_dtl"
                                      style={{
                                        color: "grey",
                                        fontStyle: "italic",
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  ) : (
                    <Empty
                      style={{ marginTop: "20vh" }}
                      description={<span>Pilih Periode</span>}
                    />
                  )}
                </div>
                {listDatePeriodCutoffPayroll.length > 0 ? (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Descriptions bordered title="Attendance Recap">
                          <Descriptions.Item
                            style={{ background: "#c5eded" }}
                            label="Total Present"
                          >
                            <b>{totalPresent}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#c5eded" }}
                            label="Home Base / Site"
                          >
                            <b>{totalHomeBase}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#c5eded" }}
                            label="Business Trip"
                          >
                            <b>{totalBusinessTrip}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#f0b1b1" }}
                            label="Leave"
                          >
                            <b>{totalLeave}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#f0b1b1" }}
                            label="Sick"
                          >
                            <b>{totalSick}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#319F4F", color: "#FFFFFF" }}
                            label="Outstanding Last Month"
                          >
                            <b>{outstandingLastMonth}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#319F4F", color: "#FFFFFF" }}
                            label="Work public holiday"
                          >
                            <b>{workPublciHoliday}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#319F4F", color: "#FFFFFF" }}
                            label="Outstanding This Month"
                          >
                            <b>{(outstandingLastMonth + outstandingThisMonth + totalLeave).toFixed(1)}</b>
                          </Descriptions.Item>
                          <Descriptions.Item
                            style={{ background: "#319F4F", color: "#FFFFFF" }}
                            label={
                              hiringPlace === "Lokal" ? "Dayoff" : "Fieldbreak"
                            }
                          >
                            <b>{totalDayOff}</b>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <Form.Item className="text-center">
                          <Button
                            type="primary"
                            className="text-center"
                            size="middle"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(TSCreate);
