import React, { useEffect, useRef, useState } from 'react';

import { Layout, Collapse, Form, Select, message, Row, Col, DatePicker, TimePicker, Radio, InputNumber, Button, AutoComplete, Modal, Alert, Upload, Input, Checkbox } from "antd";

import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Navbar from '../../../atoms/navbar';

import API from '../../../services';
import HeaderFarms from '../../../utils/header_farms';
import { loginRequest } from '../../../config/api-config';

import { Confirm, Loading, Report } from 'notiflix';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import PinInput from 'react-pin-input';
import bcrypt from 'bcryptjs';

function CreateRequestDeliveryFuel() {

    let navigate = useNavigate();
    const [form] = Form.useForm();
    const { instance, accounts } = useMsal();

    const { Panel } = Collapse;
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;

    const pinInputRef = useRef(null);
    const operatorInputRef = useRef(null);

    const [Operators, setOperators] = useState([]);
    const [PinModal, setPinModal] = useState(false);
    const [currentOperator, setCurrentOperator] = useState(null)
    const [passwordStatus, setPasswordStatus] = useState(false)
    const [fileListOperator, setFileListOperator] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [fileListMeter, setFileListMeter] = useState([]);
    const [FT, _] = useState(['TS-9657-MMS', 'FT-002', 'FT-003', 'FT-1003-CPM', 'Tangki A', 'Tangki B'])
    const [fuelLoss, setFuelLoss] = useState(false);
    const [BackDate, setBackDate] = useState(false);

    const handleAmountSubmittedChange = value => {
        form.setFieldsValue({
            check_flowmeter_total: value
        })
    };

    const handleUploadMeter = (info) => {
        let fileListMeter = [...info.fileList];
        // Limit to only one file
        fileListMeter = fileListMeter.slice(-1);
        // Update state
        setFileListMeter(fileListMeter);
    };

    const handleUploadOperator = (info) => {
        let fileListOperator = [...info.fileList];
        // Limit to only one file
        fileListOperator = fileListOperator.slice(-1);
        // Update state
        setFileListOperator(fileListOperator);
    };

    const getOperator = (token) => {
        API.Operators(HeaderFarms(token), null).then(res => {
            setOperators(res.data)
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const showModal = () => {
        setPinModal(true);
    };

    const closePinModal = () => {
        setPinModal(false);
    };

    const handleChangeOperator = (data) => {
        // Set operator_id to null and blur operator input
        // form.setFieldsValue({ operator_id: null });
        // operatorInputRef.current.blur();

        // Find the current operator
        const currentOperator = Operators.find(operator => operator.employee_id === data);
        setCurrentOperator(currentOperator);

        // Show the modal and focus on pin input after a short delay
        // showModal(true);
        // setTimeout(() => pinInputRef.current.focus(), 100);
    };

    const validationPin = async (value) => {
        try {
            // Compare the PIN entered with the current operator's password
            const match = await bcrypt.compare(value, currentOperator.password);
            setPasswordStatus(!match); // Set password status based on the match result

            // Clear the PIN input regardless of match status
            pinInputRef.current.clear();

            // Set operator_id and close the PIN modal if the PIN is correct
            form.setFieldsValue({ operator_id: match ? currentOperator.employee_id : null });
            if (match) {
                closePinModal();
            }
        } catch (error) {
            console.error('Error while verifying password:', error);
        }
    };

    const infoCompleteAndRedirect = () => {
        Report.success(
            `Has been recorded.`,
            "Berhasil di tersimpan",
            "Okay",
            function () {
                navigate(`/fms/menu`);
            }
        );
    }

    const saveDelivery = async (data) => {
        try {
            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            const responseToken = await instance.acquireTokenSilent(request);

            const formData = new FormData();

            // Append file attachments to FormData
            if (data.flow_attachment?.fileList[0]) {
                formData.append('flow_attachment', data.flow_attachment.fileList[0].originFileObj);
            }
            if (data.attachment_operator?.fileList[0]) {
                formData.append('attachment_operator', data.attachment_operator.fileList[0].originFileObj);
            }

            // Separate other data and convert it to JSON
            const jsonData = {};
            Object.keys(data).forEach((key) => {
                if (
                    key !== 'flow_attachment'
                    && key !== 'attachment_operator') {
                    jsonData[key] = data[key];
                }
            });

            // Convert JSON data to string and append it to FormData
            formData.append('json_data', JSON.stringify(jsonData));

            await API.CreateRequestDelivery
                (
                    HeaderFarms(responseToken.accessToken),
                    formData
                );

            infoCompleteAndRedirect();
        } catch (err) {
            console.error("saveDelivery error:", err);

            if (!navigator.onLine) {
                console.warn("Network error detected: offline or network issues");
            }

            infoCompleteAndRedirect();
        } finally {
            Loading.remove();
            setIsSubmitting(false); // Set status pengiriman kembali ke false setelah selesai
        }
    };


    const onFinish = async (data) => {

        if (isSubmitting) return;

        setIsSubmitting(true);

        Confirm.show(
            'Important Confirmation',
            'Are you sure?',
            'Yes',
            'No',
            async () => {
                try {
                    Loading.hourglass("Mohon tunggu sedang di proses.. ");
                    await saveDelivery(data);
                } catch (error) {
                    console.error("onFinish error:", error);
                } finally {
                    Loading.remove();
                    setIsSubmitting(false);
                }
            },
            () => {
                setIsSubmitting(false);
            }
        );
    };

    const getLastDeliveryFuelTruck = (ft) => {
        API.DeliveryLatest(HeaderFarms(), ft)
            .then(latest => {
                if (latest.data) {
                    const latestDate = moment(latest.data.date); // Ambil tanggal terbaru dari respon API
                    const currentDate = moment(); // Ambil tanggal saat ini

                    form.setFieldsValue({
                        issued_period: [latestDate, currentDate],
                        issued_period_before: latest.data.last_total_amount_issued,
                        remaining_fuel_period: latest.data.remaining_fuel_period
                    });

                    if (latest.data.remaining_fuel_period < 0) {
                        setFuelLoss(true)
                        form.setFieldsValue({
                            fueloss: latest.data.remaining_fuel_period
                        })
                    } else {
                        setFuelLoss(false)
                        form.setFieldsValue({
                            fueloss: latest.data.remaining_fuel_period
                        })
                    }

                } else {
                    form.setFieldsValue({
                        issued_period: [moment(), moment()],
                        issued_period_before: null,
                        remaining_fuel_period: null
                    });
                }
            })
            .catch(err => {
                console.error(err);
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, Failed load latest delivery",
                    "Okay"
                );
            });
    }

    const handleAmountQuantity = (value) => {

        let remaining_fuel_period = parseInt(
            form.getFieldsValue()['remaining_fuel_period']
        )

        if (parseInt(form.getFieldsValue()['remaining_fuel_period']) < 0) {
            remaining_fuel_period = 0
        }

        form.setFieldsValue({
            flowmeter_total: value,
            issued_total:
                form.getFieldsValue()['quantity_request'] + remaining_fuel_period
        })

    };

    const fuelLostHandle = (event) => {
        setFuelLoss(event.target.checked);
    }

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                const token = responseToken.accessToken;
                getOperator(token)

            })
            .catch((e) => {
            });
    }

    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    useEffect(() => {

        if (accounts.length > 0) {

            RequestToken()

            form.setFieldsValue({
                // tank_kondition: "Filled",
                fuel_type_delivery: "Bio Solar B35"
            })

            if (PinModal && pinInputRef.current) {
                pinInputRef.current.focus();
            }
        }

        // Patent Redirect for domain spesific
        const currentURL = window.location.href;
        const url = new URL(currentURL);

        switch (url.hostname) {
            case "backdaterf.citrapalu.net":
            case "backdatedr.citrapalu.net":
                setBackDate(true)
            default:
        }


    }, [PinModal, currentOperator]);

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Fuel System"
                navigate="/fms/menu"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="fuel_delivery"
                    initialValues={{
                        remember: true,
                    }}
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='large'
                    layout='vertical'
                >
                    <div className="container-fluid p-2" style={{ alignSelf: "center" }}>
                        <Modal
                            title="Masukan PIN Operator"
                            footer={null}
                            centered
                            open={PinModal}
                            onCancel={closePinModal}
                            className="p-2"
                            transitionName=""

                        >
                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col>
                                    <PinInput
                                        length={4}
                                        initialValue=""
                                        secret={false}
                                        type="numeric"
                                        style={{ textAlign: 'center', marginBottom: 20, fontSize: 30, fontWeight: "bold" }}
                                        inputStyle={{ borderColor: '#1890FF', borderRadius: 10, borderWidth: 2 }}
                                        inputFocusStyle={{ borderColor: 'blue' }}
                                        onComplete={(value, index) => {
                                            validationPin(value)
                                        }}
                                        ref={pinInputRef}
                                        autoSelect={false}
                                        onKeyUp={() => setPasswordStatus(false)}
                                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                    />
                                    {
                                        passwordStatus && (
                                            <Alert message="PIN Salah" type="error" showIcon />
                                        )
                                    }
                                </Col>
                            </Row>
                        </Modal>

                        <div className="row">
                            <div className='col-md-12'>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b style={{ fontSize: '18px', fontWeight: 'bold' }}>Informasi Penyerahan</b>} key="2">
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Date</span>}
                                                    name="date"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input date',
                                                        },
                                                    ]}
                                                    initialValue={moment()}
                                                >
                                                    <DatePicker style={{ pointerEvents: BackDate ? 'auto' : 'none' }} showTime format="YYYY-MM-DD" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Time</span>}
                                                    name="time"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input time',
                                                        },
                                                    ]}
                                                    initialValue={moment()}
                                                >
                                                    <TimePicker style={{ pointerEvents: BackDate ? 'auto' : 'none' }} showNow={false} format="HH:mm" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Fuel Truck</span>}
                                                    name="fuel_truck"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <AutoComplete
                                                        placeholder="Select"
                                                        allowClear
                                                        onChange={getLastDeliveryFuelTruck}
                                                        options={FT.map((item) => ({ value: item }))}
                                                        filterOption={(inputValue, option) => {
                                                            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Tangki Penyimpanan</span>}
                                                    name="location_storage"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <AutoComplete
                                                        placeholder="Select"
                                                        allowClear
                                                        options={
                                                            ['T-201-A', 'T-201-B', 'T-20-KL', 'T-16-KL', 'T-8-KL']
                                                                .map((item) => ({ value: item }))}
                                                        filterOption={(inputValue, option) => {
                                                            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 11 }} xl={{ span: 11 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Nama Supir / Operator</span>}
                                                    name="operator_id"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="Select"
                                                        allowClear
                                                        showSearch
                                                        optionFilterProp="children"
                                                        onChange={handleChangeOperator}
                                                        ref={operatorInputRef}
                                                        clearIcon={false}
                                                        suffixIcon={form.getFieldsValue()['operator_id'] ? <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} /> : <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />}
                                                    >
                                                        {Operators.map((item) => (
                                                            <Select.Option key={item.employee_id} value={item.employee_id}>
                                                                {item.fullname} - {item.employee_id}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Foto Operator</span>}
                                                    name="attachment_operator"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        listType="picture"
                                                        accept="image/*"
                                                        fileList={fileListOperator}
                                                        onChange={handleUploadOperator}
                                                        beforeUpload={() => false} // Prevent upload
                                                    >
                                                        {fileListOperator.length === 0 && (
                                                            <Button icon={<PlusOutlined />}>
                                                                Upload
                                                            </Button>
                                                        )}
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Jenis Bahan Bakar</span>}
                                                    name="fuel_type_delivery"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group defaultValue="Bio Solar B35">
                                                        <Radio value="Bio Solar B35">Bio Solar B35</Radio>
                                                        <Radio value="Benzine">Bensin</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Quantity yang diminta</span>}
                                                    name="quantity_request"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} onChange={handleAmountQuantity} step={0.1} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b style={{ fontSize: '18px', fontWeight: 'bold' }}>Pemeriksaan</b>} key="2">
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Kondisi Tangki</span>}
                                                    name="tank_kondition"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value="Blank">Kosong</Radio>
                                                        <Radio value="Filled">Terisi</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 15 }} xl={{ span: 15 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Periode Penyaluran</span>}
                                                    name="issued_period"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input date',
                                                        },
                                                    ]}
                                                    initialValue={moment()}
                                                >
                                                    <RangePicker format="YYYY-MM-DD HH:mm" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Penyaluran Sebelumnya</span>}
                                                    name="issued_period_before"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} onChange={handleAmountSubmittedChange} step={0.1} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 11 }} xl={{ span: 11 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Sisa Minyak Dalam Tangki</span>}
                                                    name="remaining_fuel_period"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} addonAfter="L"
                                                        addonBefore={<><Checkbox onChange={fuelLostHandle} checked={fuelLoss} style={{ fontSize: '16px', fontWeight: 'bold', color: 'red' }}>
                                                            Fuel Loss
                                                        </Checkbox></>} />
                                                </Form.Item>
                                            </Col>
                                            {
                                                fuelLoss && (
                                                    <>
                                                        <Col xs={{ span: 24 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Description Fueloss</span>}
                                                                name="fueloss_description"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea rows={2} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="fueloss"
                                                                hidden
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Sounding Awal</span>}
                                                    name="sounding_before"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Sounding Akhir</span>}
                                                    name="sounding_after"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Angka Flowmeter</span>}
                                                    name="flowmeter_total"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Lampiran Flowmeter</span>}
                                                    name="flow_attachment"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        listType="picture"
                                                        accept="image/*"
                                                        fileList={fileListMeter}
                                                        onChange={handleUploadMeter}
                                                        beforeUpload={() => false} // Prevent upload
                                                    >
                                                        {fileListMeter.length === 0 && (
                                                            <Button icon={<PlusOutlined />}>
                                                                Upload
                                                            </Button>
                                                        )}
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Setelah Pengisian</span>}
                                                    name="issued_total"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col>
                                                <Form.Item className='mt-3'>
                                                    <Button loading={isSubmitting} disabled={isSubmitting} type="primary" size="large" htmlType="submit" icon={<CheckCircleOutlined />}>
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>

                            </div>

                        </div>
                    </div>
                </Form>
            </div>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2024 Information Technology</Layout.Footer>
        </>
    );
}
export default CreateRequestDeliveryFuel