import { Collapse, Form, Input, Row, Col, Checkbox, message, Descriptions, Comment, Avatar, Button, Affix, Modal, Timeline, Alert, Radio, Select, Spin, InputNumber } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { Block, Confirm, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { CheckCircleOutlined, DownloadOutlined, IssuesCloseOutlined, PrinterOutlined } from "@ant-design/icons";
import HeaderFarms from "../../../utils/header_farms";
import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";


function ViewWP() {

  let { id } = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const { TextArea } = Input;

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisibleTask, setIsModalVisibleTask] = useState(false)
  const [isModalVisibleValidation, setIsModalVisibleValidation] = useState(false)

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [completeLoadWP, setcompleteLoadWP] = useState(false)
  const [itemsWp, setItemsWp] = useState([]);

  const [satkers, setSatker] = useState([]);
  const [back, setBack] = useState(false)

  const getDetailPermit = async (token) => {
    try {
      const res = await API.viewWorkingPermit(HeaderFarms(token), id);

      Block.remove('.ant-collapse');

      res.data.job_start_time = formatTimeToHHMM(new Date(res.data.job_start_time));
      res.data.job_end_time = formatTimeToHHMM(new Date(res.data.job_end_time));
      res.data.last_commissioning_date = moment(res.data.last_commissioning_date).format("YYYY-MMM-DD")

      const checkAndPush = (element, data, targetArray) => {
        if (
          element.checkbox === data &&
          res.data[`item_${element.item_id}`] === true &&
          !targetArray.includes(`item_${element.item_id}`)
        ) {
          targetArray.push(`item_${element.item_id}`);
        }
      };

      const required_personal_protective_equipment = [];
      const identification_job_lifting = []
      const identification_job_area = []
      const other_safety_equipment = [];
      const general_preparation = [];
      const job_preparation = [];
      const complete_work = []

      for (const element of itemsWp) {
        checkAndPush(element, 'Perlengkapan (APD) yang diperlukan', required_personal_protective_equipment);
        checkAndPush(element, 'Persiapan Umum', general_preparation);
        checkAndPush(element, 'Pekerjaan Selesai', complete_work);
        checkAndPush(element, 'Alat Keselamatan Lainnya', other_safety_equipment);
        checkAndPush(element, 'Persiapan Pelaksanaan Pekerjaan', job_preparation);
        checkAndPush(element, 'Identifikasi Pekerjaan Pengangkatan', identification_job_lifting);
        checkAndPush(element, 'Identifikasi Area Kerja', identification_job_area);

      }

      let editJobLocation = res.data.job_location?.split(',')
      let workers = res.data.workers?.split(',')

      const formValues = {
        ...res.data,
        identification_job_area,
        identification_job_lifting,
        required_personal_protective_equipment,
        other_safety_equipment,
        general_preparation,
        job_preparation,
        complete_work
      };

      formValues.job_location = editJobLocation
      formValues.workers = workers

      formValues.execution_time = formatDateForInput(formValues.execution_time)

      form.setFieldsValue(formValues);
      setcompleteLoadWP(res.data);
      styleStatus(res.data);
    } catch (err) {
      console.error(err);
      Report.info("Working Permit", "Sorry, Working Permit not found", "Okay");
      Block.remove('.ant-collapse');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // show modal and handleCancel for completed_task modal
  const showModalTask = () => {

    //get usersatker
    UsersSatker()

    setIsModalVisibleTask(true);
  }

  const handleCancelTask = () => {
    setIsModalVisibleTask(false);
  }

  const showModalValidation = () => {
    //get usersatker
    UsersSatker()

    setIsModalVisibleValidation(true);
  }

  const handleCancelTaskValidation = () => {
    setIsModalVisibleValidation(false);
  }

  const styleStatus = (res) => {
    let status = res.approval_process_id.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  const UsersSatker = (token) => {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {

      API.getUsersSR(HeaderFarms(response.accessToken), null).then(res => {

        let AllSatkers = []

        res.map(element => {
    
          if (element.department && completeLoadWP.user.department)
            if (element.department._id === completeLoadWP.user.department) {
              AllSatkers.push({
                value: element.fullname,
                label: element.fullname,
                detail: element,
              })
            }
        });
        setSatker(AllSatkers)

      }).catch((err) => {
        console.log(err)
        message.warning('Terjadi Kesalahan saat load data user')
      });

    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });

  }

  async function getWorkingItems(token) {
    try {
      const res = await API.getWorkingItems(HeaderFarms(token), null);

      setItemsWp(res.data);
    } catch (err) {
      Block.remove('.ant-collapse');
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  }

  function formatDateForInput(originalDateString) {
    const originalDate = new Date(originalDateString);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because it's zero-based
    const day = originalDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function RequestToken() {

    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      if (itemsWp.length === 0) {
        getWorkingItems(response.accessToken);

      } else {
        getDetailPermit(response.accessToken);

      }

    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then(() => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  const submitEndTask = (values) => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ?',
      'Yes',
      'No',
      () => {
        endTask(values)
      },
    );
  }

  const submitValidationPermit = (values) => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ?',
      'Yes',
      'No',
      () => {
        createValidationPermit(values)
      },
    );
  }

  const createValidationPermit = (values) => {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      setIsModalVisibleTask(false)

      values.id_permit = completeLoadWP.id_record;

      Block.remove('.ant-collapse');
      API.createValidation(HeaderFarms(response.accessToken), values).then((_) => {

        Report.success(
          `Berhasil`,
          `Validation permit berhasil di buat`,
          "Okay", () => {
            return navigate('/wp/history')
          })


      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })

    }).catch((e) => {
      console.error(e)
      Block.remove('.ant-collapse');
    });
  }

  const endTask = (values) => {

    const completeWorkObject = {};

    if (values.complete_work) {
      values.complete_work.forEach((item) => {
        completeWorkObject[item] = true;
      });
    }

    completeWorkObject.approver_header_id = completeLoadWP.approval_process_id.id;
    completeWorkObject.job_completion_notes = values.job_completion_notes;
    completeWorkObject.permit_type = completeLoadWP.permit_type;
    completeWorkObject.direct_supervisor = values.direct_supervisor;
    completeWorkObject.waste_produced = values.waste_produced;
    completeWorkObject.id_record = completeLoadWP.id_record;
    completeWorkObject.nik = completeLoadWP.user.nik

    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      setIsModalVisibleTask(false)

      Block.remove('.ant-collapse');
      API.WorkingWorkEnd(HeaderFarms(response.accessToken), completeWorkObject).then((_) => {

        Report.success(
          `Berhasil`,
          `Pekerjaan berhasil di selesaikan`,
          "Okay", () => {
            return navigate('/wp/history')
          })


      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })

    }).catch((e) => {
      console.error(e)
      Block.remove('.ant-collapse');
    });
  }

  const cancel = (values) => {
    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      setIsModalVisible(false)
      Block.remove('.ant-collapse');
      API.workflowCancel(HeaderFarms(response.accessToken), {
        approval_id: completeLoadWP.approval_process_id._id,
        msg: values.message
      }).then((_) => {

        Report.success(
          `Cancel Success`,
          `Working Permit berhasil di batalkan`,
          "Okay", () => {
            return navigate('/wp/history')
          })


      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })

    }).catch((e) => {
      console.error(e)
      Block.remove('.ant-collapse');
    });
  }

  const cancelSR = (values) => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ?',
      'Yes',
      'No',
      () => {
        cancel(values)
      },
    );
  }

  function formatTimeToHHMM(dateTime) {
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${formattedHours}:${formattedMinutes}`;
  }

  function GeneralPreparationValueToLabel(value) {
    const valueToLabel = {
      "Hot Space": "Pekerjaan Berbahaya",
      "Limited Work": "Pekerjaan Berbahaya",
      "Work at height": "Pekerjaan Berbahaya",
      "Electric Work": "Pekerjaan Kelistrikan",
      "Excavation": "Pekerjaan Penggalian",
    };

    return valueToLabel[value] || value;
  }

  function preparationValueToLabel(value, preparationType) {
    const valueToLabel = {
      "Hot Space": preparationType === 'Job' ? "Pekerjaan Panas" : "Pekerjaan Berbahaya",
      "Limited Work": preparationType === 'Job' ? "Pekerjaan Ruang Terbatas" : "Pekerjaan Berbahaya",
      "Work at height": preparationType === 'Job' ? "Pekerjaan Ketinggian" : "Pekerjaan Berbahaya",
      "Electric Work": preparationType === 'Job' ? "Pekerjaan Kelistrikan" : "Pekerjaan Kelistrikan",
      "Excavation": preparationType === 'Job' ? "Pekerjaan Penggalian" : "Pekerjaan Penggalian",
    };

    return valueToLabel[value] || value;
  }

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

    const searchParams = new URLSearchParams(location.search);
    setBack(searchParams.get('id'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, itemsWp]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Working Permit"
        navigate={back ? '/wf/vwp/view/' + back : '/wp/history'}
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="working_permit_form"
          onFinish={null}
          onFinishFailed={() => message.warning('Mohon lengkapi formulir.')}
          autoComplete="off"
          size='middle'
          layout='vertical'
        >
          <div className="container p-3" style={{ alignSelf: "center" }}>

            <div className="row">
              <div className="col-md-9 mb-2">
                <Button type="primary" onClick={() => navigate('/wp/print/' + id)} title="Print" icon={<PrinterOutlined />}>Print</Button>
              </div>
              <div className="col-md-12 mb-2">
                <Collapse defaultActiveKey={['1']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Working Permit Information</b>} key="1">
                    {completeLoadWP ? (
                      <>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            {
                              completeLoadWP && (
                                <Descriptions size="small" bordered title="Information" className="mb-4">
                                  <Descriptions.Item label="Form ID">
                                    {completeLoadWP.id_record}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Working Status">
                                    {completeLoadWP.status}
                                  </Descriptions.Item>
                                  <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                    {completeLoadWP.approval_process_id.status}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Created at">
                                    {moment(completeLoadWP.created_at).format('LLL')}
                                  </Descriptions.Item>
                                </Descriptions>
                              )
                            }
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-5'>
                            <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                            <Timeline style={{ marginTop: 30 }}>
                              {
                                completeLoadWP.approval_process_id.detail.map(item => {
                                  return (
                                    <Timeline.Item key={item._id}>{item.deputy_approver ? `Deputy (${item.deputy_approver})` : item.approved_by.fullname}
                                      <Alert
                                        message={item.status ? `Approved - ${moment(item.approved_at).format('YYYY-MM-DD HH:mm')}` : `Waiting Approval`}
                                        className='mt-1 p-1'
                                        type={completeLoadWP.approval_process_id.detail.find(a => a.status === false) ? 'warning' : 'success'}
                                        showIcon
                                      />
                                    </Timeline.Item>
                                  )
                                })
                              }
                            </Timeline>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Panel>
                </Collapse>
                {
                  completeLoadWP && completeLoadWP.validation.length > 0 && (
                    <Collapse defaultActiveKey={['0']} className="mt-2">
                      <Panel
                        style={{ background: "#fce8b6" }}
                        header={<b>Validation Permit</b>}
                      >
                        <Collapse defaultActiveKey={['0']} className="p-0">
                          {
                            completeLoadWP &&
                            completeLoadWP.validation.map((validationItem, index) => (
                              <Panel
                                header={<b>{validationItem.approval_process.status}</b>}
                                key={validationItem.id_record}
                                className="p-0"

                              >
                                <div className="row mt-3">
                                  <div className="col-md-12">
                                    {
                                      validationItem && (
                                        <Descriptions size="small" bordered title="Information" className="mb-4">
                                          <Descriptions.Item label="Form ID">
                                            {validationItem.id_record}
                                          </Descriptions.Item>
                                          <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                            {validationItem.approval_process.status}
                                          </Descriptions.Item>
                                          <Descriptions.Item label="Created at">
                                            {moment(validationItem.created_at).format('LLL')}
                                          </Descriptions.Item>
                                        </Descriptions>
                                      )
                                    }
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                                    <Timeline style={{ marginTop: 30 }}>
                                      {validationItem.approval_process.detail.map(item => (
                                        <Timeline.Item key={item._id}>{item.deputy_approver ? `Safety Team (${item.deputy_approver})` : item.approved_by.fullname}
                                          <Alert
                                            message={item.status ? 'Approved' : 'Waiting Approval'}
                                            className='mt-1 p-1'
                                            type={item.status ? 'success' : 'warning'}
                                            showIcon
                                          />
                                        </Timeline.Item>
                                      ))}
                                    </Timeline>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-4">
                                      <label className="mb-2">Material yang terlibat:</label>
                                      <Input readOnly value={validationItem.involved_materials} />
                                    </div>
                                    <div className="mb-4">
                                      <label className="mb-2">Peralatan Keselamatan:</label>
                                      <Input readOnly value={validationItem.safety_equipment} />
                                    </div>
                                    <div className="mb-4">
                                      <label className="mb-2">Notes :</label>
                                      <Input readOnly value={validationItem.notes} />
                                    </div>
                                  </div>
                                </div>

                                {/* Comments for this validation */}
                                {validationItem.comments && validationItem.comments.length > 0 && (
                                  <Collapse defaultActiveKey={['1']}>
                                    <Panel header="Comments" key="1">
                                      {validationItem.comments
                                        .map(data => (
                                          <Comment
                                            key={data._id}
                                            author={<p>{data.uid.fullname}</p>}
                                            avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                            content={
                                              <p>
                                                {data.text_plain}
                                              </p>
                                            }
                                          />
                                        ))}
                                    </Panel>
                                  </Collapse>

                                )}

                                {validationItem.approval_process && validationItem.approval_process.approval_key && (
                                  <div className="col-md-12 ">
                                    <Collapse defaultActiveKey={['0']}>
                                      <Panel header={<b>Validation Approval Barcode </b>} key="0">
                                        <div className="row">
                                          <div className="col-md-12 text-center">
                                            <QRCodeSVG size={70} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${validationItem.approval_process.approval_key}`} />
                                          </div>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </div>
                                )}
                              </Panel>
                            ))}
                        </Collapse>

                      </Panel>
                    </Collapse>
                  )
                }
              </div>

              {completeLoadWP && completeLoadWP.approval_process_id.approval_key && (
                <>
                  <div className="col-md-12 ">
                    <Collapse defaultActiveKey={['1']}>
                      <Panel style={{ background: "#fce8b6" }} header={<b>Approval Barcode  </b>} key="1">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${completeLoadWP.approval_process_id.approval_key}`} />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </>

              )}

              <div className="col-md-12 mb-2">
                {
                  completeLoadWP.comments && completeLoadWP.comments.length > 0 && (
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Message" key="1">
                        {
                          completeLoadWP.comments && completeLoadWP.comments.map(data => {
                            return (
                              <Comment
                                key={data._id}
                                author={<p>{data.uid.fullname}</p>}
                                avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                content={
                                  <p>
                                    {data.text_plain}
                                  </p>
                                }
                              />
                            )
                          })
                        }
                      </Panel>
                    </Collapse>
                  )
                }
              </div>

              <div className='col-md-12'>
                <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']} onChange={null}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Personal Info</b>} key="1">
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Nama Karyawan"
                          name="allowed_employee_name"
                          rules={[{ required: false, message: 'Nama Karyawan Wajib Isi' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Kepala Satuan Kerja"
                          name="direct_supervisor"
                          rules={[{ required: false, message: 'Nama Supervisor Wajib Isi' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Departemen"
                          name="department_name"
                          rules={[{ required: false, message: 'Departemen Wajib Isi' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>

                </Collapse>
                <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Umum</b>} key="1">
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jenis Izin Pekerjaan Berbahaya"
                          name="permit_type"
                          rules={[{ required: false, message: 'Jenis Izin Wajib di isi' }]}
                        >
                          <Radio.Group name='permit-type'>
                            {[
                              "Hot Space",
                              "Limited Work",
                              "Work at height",
                              "Electric Work",
                              "Excavation",
                              "Crane Lifting / Operation"
                            ].map((value, index) => (
                              <Radio key={value} value={value} style={{ marginBottom: 10 }}>{[
                                "Panas",
                                "Ruang Terbatas",
                                "Ketinggian",
                                "Kelistrikan",
                                "Penggalian",
                                "Pengangkatan ( Crane Operation )"
                              ][index]}</Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Pelaksana"
                          name="executor"
                          rules={[{ required: false, message: 'Pelaksana Wajib di isi' }]}
                        >
                          <Checkbox.Group name='permit-type'>
                            {[
                              { "value": "PT CPM", "label": "PT CPM" },
                              { "value": "PT BRM", "label": "PT BRM" },
                              { "value": "PT AKM", "label": "PT AKM" },
                              { "value": "PT SSS", "label": "PT SSS" },
                              { "value": "PT ETI", "label": "PT ETI" },
                              { "value": "PT AED", "label": "PT AED" },
                              { "value": "PT PBU", "label": "PT PBU" },
                              { "value": "PT PPU", "label": "PT PPU" },
                              { "value": "PT TRISULA", "label": "PT TRISULA" },
                              { "value": "PT LPM", "label": "PT LPM" },
                              { "value": "Magang/Intership", "label": "Magang/Intership" },
                              { "value": "Visitor/Tamu", "label": "Visitor/Tamu" },
                              { "value": "Lainnya", "label": "Lainnya" },
                            ].map((value, index) => (
                              <Checkbox key={value.value} value={value.value} style={{ marginBottom: 10, marginLeft: 10 }}>{value.value}</Checkbox>
                            ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-3">
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Pengawas"
                          name="work_supervisor"
                          rules={[{ required: true, message: 'Pengawas Wajib di isi' }]}
                        >
                          <Input
                            size="middle"
                            placeholder="Reza Muhammad"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {
                      form.getFieldValue('permit_type') === 'Excavation' && (
                        <Row gutter={10} className="mb-1">
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nama Operator Alat Berat"
                              name="heavy_equipment_operator_name"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nama Pengawas Alat"
                              name="heavy_equipment_supervisor_name"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nomor Registrasi Alat Berat"
                              name="heavy_equipment_registration_number"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                                { min: 10, message: 'Minimal 10 karakter' },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nomor SIM Perusahaan"
                              name="company_driving_licenses"
                              rules={[
                                { required: true, message: 'Wajib di isi' },
                              ]}
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      setcompleteLoadWP && setcompleteLoadWP.permit_type === 'excavation' && (
                        <Row gutter={10} className="mb-1">
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nama Operator Alat Berat"
                              name="heavy_equipment_operator_name"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                                { min: 10, message: 'Minimal 10 karakter' },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nama Pengawas Alat"
                              name="heavy_equipment_supervisor_name"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                                { min: 10, message: 'Minimal 10 karakter' },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nomor Registrasi Alat Berat"
                              name="heavy_equipment_registration_number"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                                { min: 10, message: 'Minimal 10 karakter' },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                              label="Nomor SIM Perusahaan"
                              name="company_driving_licenses"
                              rules={[
                                { required: false, message: 'Wajib di isi' },
                              ]}
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    }

                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jenis Pekerjaan"
                          name="job_type"
                          rules={[
                            { required: false, message: 'Jenis Pekerjaan anda wajib di isi' },
                            { min: 10, message: 'Minimal 10 karakter' },
                            { max: 180, message: 'Maksimal 180 karakter' },
                          ]}
                        >
                          <TextArea rows={3} readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Uraian Kegiatan"
                          name="activity_description"
                          rules={[
                            { required: false, message: 'Uraian Kegiatan anda wajib di isi' },
                            { min: 10, message: 'Minimal 10 karakter' },
                            { max: 180, message: 'Maksimal 180 karakter' },
                          ]}
                        >
                          <TextArea rows={3} readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Lokasi Pekerjaan"
                          name="job_location"
                          rules={[{ required: false, message: 'Wajib Isi' }]}
                        >

                          <Select
                            size='middle'
                            placeholder="Select"
                            allowClear={true}
                            mode="multiple"
                          />

                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                          label="Perlengkapan (APD) yang diperlukan"
                          name="required_personal_protective_equipment"
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            placeholder="Pilih perlengkapan (APD)"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {itemsWp.filter(a => a.checkbox === 'Perlengkapan (APD) yang diperlukan' && a.type === 'Pekerjaan Berbahaya').map((value) => (
                              <Select.Option key={value.item_id} value={"item_" + value.item_id}>
                                {value.value}
                              </Select.Option>
                            ))}

                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
                        <Form.Item
                          label="Alat Keselamatan Lainnya"
                          name="other_safety_equipment"
                          rules={[{ required: false, message: 'Wajib di isi' }]}

                        >
                          <Checkbox.Group name='permit-type'>
                            {itemsWp.filter(a => a.checkbox === 'Alat Keselamatan Lainnya' && a.type === 'Pekerjaan Berbahaya').map((value) => (
                              <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, }}>{value.value}</Checkbox>
                            ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>

                {
                  form.getFieldValue('permit_type') === "Crane Lifting / Operation" && (
                    <>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Crane</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Nama Operator Crane"
                                name="crane_operator_name"
                                rules={[{ required: true, message: 'Nama Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Nama Rigger"
                                name="rigger_name"
                                rules={[{ required: true, message: 'Nama Rigger Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="No. Registrasi"
                                name="registration_number"
                                rules={[{ required: true, message: 'No. Registrasi Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Jenis Crane"
                                name="crane_type"
                                rules={[{ required: true, message: 'Jenis Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Kapasitas Crane"
                                name="crane_capacity"
                                rules={[{ required: true, message: 'Kapasitas Crane Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Commissioning terakhir"
                                name="last_commissioning_date"
                                rules={[{ required: true, message: 'Commissioning terakhir Operator Crane Wajib Isi' }]}
                              >
                                <Input type="text" readOnly />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Identifikasi Pekerjaan Pengangkatan</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Total Beban Diangkat"
                                name="total_load_lifted"
                                rules={[{ required: true, message: 'Total Beban Diangkat Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="ton" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Total Beban Rigging"
                                name="total_load_rigging"
                                rules={[{ required: true, message: 'Total Beban Rigging Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="ton" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Panjang Boom"
                                name="boom_length"
                                rules={[{ required: true, message: 'Panjang Boom Rigging Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="m" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Radius Kerja"
                                name="working_radius"
                                rules={[{ required: true, message: 'Radius Kerja Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="m" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Perhitungan SWL*"
                                name="swl_calculation"
                                rules={[{ required: true, message: 'Perhitungan SWL* Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="%" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Form.Item
                              label=""
                              name="identification_job_lifting"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                <>
                                  {itemsWp
                                    .filter((a) => a.checkbox === 'Identifikasi Pekerjaan Pengangkatan')
                                    .map((value) => (
                                      <Checkbox
                                        key={value.item_id}
                                        value={"item_" + value.item_id}
                                        style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                      >
                                        {value.value}
                                      </Checkbox>
                                    ))}
                                </>
                              </Checkbox.Group>
                            </Form.Item>
                          </Row>
                        </Panel>
                      </Collapse>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Identifikasi Area Kerja</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Form.Item
                              label=""
                              name="identification_job_area"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                <>
                                  {itemsWp.filter(a => a.checkbox === 'Identifikasi Area Kerja').map((value) => (
                                    <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}>{value.value}</Checkbox>
                                  ))}
                                </>
                              </Checkbox.Group>
                            </Form.Item>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )
                }

                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Waktu Pelaksanaan</b>} key="1">
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Waktu Pelaksanaan"
                          name="execution_time"
                          rules={[
                            { required: true, message: 'Jam Pekerjaan Dimulai wajib diisi' },
                          ]}
                        >
                          <Input type="text" readOnly className="w-50" />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Jam Pekerjaan Dimulai (Pukul)"
                          name="job_start_time"
                          rules={[
                            { required: false, message: 'Jam Pekerjaan Dimulai wajib diisi' },
                          ]}
                        >
                          <Input type={"time"} readOnly />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Jam Pekerjaan Selesai (Pukul)"
                          name="job_end_time"
                          rules={[
                            { required: false, message: 'Jam Pekerjaan Selesai wajib diisi' },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>

                <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Kondisi Cuaca & Pekerja</b>} key="1">
                    <div className="mt-2">Pekerja yang Terlibat</div>
                    <Row gutter={100} className="mt-3">
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Pekerja"
                          name="workers"
                          rules={[{ required: false, message: 'Wajib Isi' }]}
                        >
                          <Select
                            size='middle'
                            placeholder="Select"
                            allowClear={true}
                            mode="multiple"
                          />

                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Kondisi Cuaca"
                          name="weather_condition"
                          rules={[{ required: true, message: 'Wajib di isi' }]}
                        >
                          <Input
                            size="middle"
                            placeholder="Cerah"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
                {
                  form.getFieldValue('permit_type') &&
                  form.getFieldValue("permit_type") !==
                  "Crane Lifting / Operation" && (
                    <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                      <Panel style={{ background: "#fce8b6" }} header={<b>Persiapan Pelaksanaan Pekerjaan</b>} key="1">
                        <Row gutter={10} className="mb-1">
                          <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                              label=""
                              name="job_preparation"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                {itemsWp.filter(a => a.checkbox === 'Persiapan Pelaksanaan Pekerjaan' && a.type === preparationValueToLabel(form.getFieldValue('permit_type'), 'Job')).map((value) => (
                                  <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}>{value.value}</Checkbox>
                                ))}
                              </Checkbox.Group>

                            </Form.Item>
                          </Col>
                        </Row>

                      </Panel>
                    </Collapse>
                  )
                }

                {
                  ['Electric Work', 'Excavation'].includes(form.getFieldValue('permit_type')) && (
                    <>
                      {form.getFieldValue('permit_type') && (
                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                          <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                            <Row gutter={10} className="mb-1">
                              {itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === preparationValueToLabel(form.getFieldValue('permit_type'), 'Job')).map(value => (
                                <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                  <Form.Item
                                    key={value.item_id}
                                    label={value.value}
                                    className="label_attachment"
                                    name={"item_" + value.item_id}
                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                  >
                                    <div className="mt-2">
                                      {completeLoadWP['item_' + value.item_id] === false ? (
                                        "Tidak Dilampirkan"
                                      ) : (
                                        <a
                                          href={`https://farms-working-permit.s3.ap-southeast-3.amazonaws.com/${completeLoadWP.id_record}-item_${value.item_id}.pdf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <DownloadOutlined /> Download PDF
                                        </a>
                                      )}
                                    </div>
                                  </Form.Item>
                                </Col>
                              ))}
                            </Row>
                          </Panel>
                        </Collapse>
                      )}
                    </>
                  )
                }

                {
                  ["Hot Space", "Limited Work", "Work at height"].includes(form.getFieldValue('permit_type')) && (
                    <>
                      {form.getFieldValue('permit_type') && (
                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                          <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                            <Row gutter={10} className="mb-1">
                              {itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pekerjaan Berbahaya').map(value => (
                                <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                  <Form.Item
                                    key={value.item_id}
                                    label={value.value}
                                    className="label_attachment"
                                    name={"item_" + value.item_id}
                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                  >
                                    <div className="mt-2">
                                      {completeLoadWP['item_' + value.item_id] === false ? (
                                        "Tidak Dilampirkan"
                                      ) : (
                                        <a
                                          href={`https://farms-working-permit.s3.ap-southeast-3.amazonaws.com/${completeLoadWP.id_record}-item_${value.item_id}.pdf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <DownloadOutlined /> Download PDF
                                        </a>
                                      )}
                                    </div>
                                  </Form.Item>
                                </Col>
                              ))}
                            </Row>
                          </Panel>
                        </Collapse>
                      )}
                    </>
                  )
                }

                {
                  ["Crane Lifting / Operation"].includes(form.getFieldValue('permit_type')) && (
                    <>
                      {form.getFieldValue('permit_type') && (
                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                          <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                            <Row gutter={10} className="mb-1">
                              {itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pengangkatan / Pengoprasian Crane').map(value => (
                                <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                  <Form.Item
                                    key={value.item_id}
                                    label={value.value}
                                    className="label_attachment"
                                    name={"item_" + value.item_id}
                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                  >
                                    <div className="mt-2">
                                      {completeLoadWP['item_' + value.item_id] === false ? (
                                        "Tidak Dilampirkan"
                                      ) : (
                                        <a
                                          href={`https://farms-working-permit.s3.ap-southeast-3.amazonaws.com/${completeLoadWP.id_record}-item_${value.item_id}.pdf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <DownloadOutlined /> Download PDF
                                        </a>
                                      )}
                                    </div>
                                  </Form.Item>
                                </Col>
                              ))}
                            </Row>
                          </Panel>
                        </Collapse>
                      )}
                    </>
                  )
                }

                {
                  form.getFieldValue('permit_type') &&
                  form.getFieldValue("permit_type") !==
                  "Crane Lifting / Operation" && (
                    <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                      <Panel style={{ background: "#fce8b6" }} header={<b>Persiapan Umum</b>} key="1">
                        <Row gutter={10} className="mb-1">
                          <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                              label=""
                              name="general_preparation"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                {form.getFieldValue('permit_type') && (
                                  <>
                                    {itemsWp
                                      .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                      .map((value) => (
                                        <Checkbox
                                          key={value.item_id}
                                          value={"item_" + value.item_id}
                                          style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                        >
                                          {value.value}
                                        </Checkbox>
                                      ))}

                                    {itemsWp
                                      .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                      .length === 0 && (
                                        <>
                                          {itemsWp
                                            .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                            .map((value) => (
                                              <Checkbox
                                                key={value.item_id}
                                                value={"item_" + value.item_id}
                                                style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                              >
                                                {value.value}
                                              </Checkbox>
                                            ))}
                                        </>
                                      )}
                                  </>
                                )}

                              </Checkbox.Group>
                            </Form.Item>
                          </Col>
                        </Row>

                      </Panel>
                    </Collapse>

                  )
                }

                {
                  ["Limited Work"].includes(form.getFieldValue('permit_type')) && (
                    <>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Pengukuran Gas</b>} key="1">
                          <Row gutter={10}>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="O2 ( 19,5% - 23,5% )"
                                name="o2"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="H2S ( < 10 ppm ) "
                                name="h2s"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="CO ( < 30 ppm )"
                                name="co"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="HCN ( < 4,7 ppm )"
                                name="hcn"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )
                }

                <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Tambahan</b>} key="1">
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="No. Tim Tanggap Darurat"
                          name="emergency_response_team_number"
                          rules={[{ required: false, message: 'Wajib di isi' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="No. Safety Officer"
                          name="safety_officer_number"
                          rules={[{ required: false, message: 'Wajib di isi' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Row gutter={10} className="p-2">
                          <div style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: completeLoadWP.notes }}></div>
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>

                {
                  completeLoadWP.status === 'Closed' && (
                    <>
                      <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Pekerjaan Selesai</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                              <Form.Item
                                name={'complete_work'}
                                rules={[{ required: false, message: 'Wajib di isi' }]}
                              >
                                <Checkbox.Group>
                                  {itemsWp
                                    .filter((a) => a.checkbox === 'Pekerjaan Selesai' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                    .map((value) => (
                                      <Checkbox
                                        key={value.item_id}
                                        value={"item_" + value.item_id}
                                        style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                      >
                                        {value.value}
                                      </Checkbox>
                                    ))}
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          {["Hot Space", "Limited Work", "Work at height", "Excavation"].includes(form.getFieldValue('permit_type')) && (
                            <Row gutter={10} className="mb-1">
                              <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item
                                  label="Limbah yang dihasilkan Kg ( Jika Ada )"
                                  name="waste_produced"
                                  className="mt-3"
                                >
                                  <Input type={"text"} className="w-50" />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}

                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                              <Form.Item
                                label="Catatan"
                                name="job_completion_notes"
                                rules={[
                                  { required: false },
                                  { max: 180, message: 'Maksimal 180 karakter' },
                                ]}
                              >
                                <TextArea rows={3} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )
                }

              </div>
            </div>
          </div>
        </Form>

        {

          completeLoadWP && completeLoadWP.approval_process_id.status === 'Waiting Approval' && completeLoadWP.status === 'Open' && (
            <div className="row mt-4">
              <div className="col-md-12">
                <Affix offsetBottom={10}>
                  <Form.Item className="text-center">
                    <Button type="danger" onClick={showModal} className="m-3" size="large" htmlType="submit">
                      Batalkan
                    </Button>
                  </Form.Item>
                </Affix>
              </div>
            </div>
          )
        }

        {
          completeLoadWP &&
          completeLoadWP.approval_process_id.status === 'Approved' &&
          completeLoadWP.status === 'Open' && (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <Affix offsetBottom={10}>
                  <Form.Item className="text-center">

                    <Button type="primary" onClick={showModalTask} className="m-3" size="middle" htmlType="submit">
                      <CheckCircleOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Selesai
                    </Button>
                    {
                      completeLoadWP.validation.filter(item => item.approval_process.status === 'Waiting Approval').length === 0 && (
                        <Button type="danger" onClick={showModalValidation} size="middle" htmlType="submit">
                          <IssuesCloseOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Validasi
                        </Button>
                      )
                    }

                  </Form.Item>
                </Affix>
              </div>
            </div>
          )
        }


        {

          completeLoadWP && completeLoadWP.approval_process_id.status === 'Reject' && completeLoadWP.status !== 'Open' && (
            <div className="row mt-4">
              <div className="col-md-12">
                <Affix offsetBottom={10}>
                  <Form.Item className="text-center">
                    <Button type="primary" onClick={showModalTask} className="m-3" size="large" htmlType="submit">
                      Pengajuan Ulang
                    </Button>
                  </Form.Item>
                </Affix>
              </div>
            </div>
          )
        }


      </div>
      <Modal
        title="Write the reason"
        footer={null}
        visible={isModalVisible}
        onOk={null}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={cancelSR}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label=""
            name="message"
            rules={[
              {
                required: false,
                message: "Please input your reason",
              },
            ]}
          >
            <Input.TextArea
              showCount
              style={{ height: "150px", width: "100%" }}
              minLength={50}
            />
          </Form.Item>
          <Form.Item
            className="mt-3"
            wrapperCol={{
              xs: {
                offset: 8, span: 18
              },
              sm: {
                offset: 7, span: 18
              },
              lg: { offset: 8, span: 18 }
            }}

          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Pekerjaan Selesai"
        footer={null}
        visible={isModalVisibleTask}
        onOk={null}
        onCancel={handleCancelTask}
      >
        <Form
          name="complete_work_permit"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={submitEndTask}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={10} className="mb-1">
            <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
              {
                satkers.length > 0 ? (
                  <>
                    <Alert
                      message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Pastikan Kepala SATKER tidak Cuti</span>}
                      description={<span style={{ fontSize: '12px' }}> Mohon pastikan <b> kepala satuan kerja tidak cuti </b> dan sudah di informasikan ke Kepala Satker sebelum membuat laporan pekerjaan selesai</span>}
                      type="error"
                      className="mb-3"

                    />
                    <Form.Item
                      label="Kepala Satuan Kerja"
                      name="direct_supervisor"
                      rules={[{ required: true, message: 'Satuan Kerja Wajib Isi' }]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Pilih Satuan Kerja"
                      >
                        {
                          satkers
                            .slice() // Membuat salinan array untuk menghindari pengaruh pada array asli
                            // .sort((a, b) => b.detail.level.number - a.detail.level.number) // Mengurutkan dari atas ke bawah berdasarkan level.number
                            .map((satker) => (
                              <Select.Option key={satker.detail.nik} value={satker.detail.nik}>
                                {satker.label}
                              </Select.Option>
                            ))
                        }
                      </Select>

                    </Form.Item>
                  </>
                ) : (<center>Mohon tunggu.. <Spin className='p-3' /></center>)
              }
            </Col>
          </Row>

          <Row gutter={10} className="mb-1">
            <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                label=""
                name="complete_work"
                rules={[
                  {
                    required: false,
                    message: "Please input",
                  },
                ]}
              >
                <Checkbox.Group>
                  {itemsWp
                    .filter((a) => a.checkbox === 'Pekerjaan Selesai' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                    .map((value) => (
                      <Checkbox
                        key={value.item_id}
                        value={"item_" + value.item_id}
                        style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                      >
                        {value.value}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          {["Hot Space", "Limited Work", "Work at height", "Excavation"].includes(form.getFieldValue('permit_type')) && (
            <Row gutter={10} className="mb-1">
              <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
                <Form.Item
                  label="Limbah yang dihasilkan Kg ( Jika Ada )"
                  name="waste_produced"
                  className="mt-3"
                >
                  <Input type={"text"} className="w-50" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={10} className="mb-1">
            <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                label="Catatan"
                name="job_completion_notes"
                rules={[
                  { required: false },
                  { max: 180, message: 'Maksimal 180 karakter' },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            className="mt-3"
            wrapperCol={{
              xs: {
                offset: 8, span: 18
              },
              sm: {
                offset: 7, span: 18
              },
              lg: { offset: 8, span: 18 }
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>

      </Modal>
      <Modal
        title="Validasi Kerja"
        footer={null}
        visible={isModalVisibleValidation}
        onCancel={handleCancelTaskValidation}
        onOk={null}
      >
        <Form
          name="validation_working_permit"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={submitValidationPermit}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={10} className="mb-1">
            <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
              {
                satkers.length > 0 ? (
                  <>
                    <Alert
                      message={<span style={{ fontSize: '14px', fontWeight: "bold" }}>Pastikan Kepala SATKER tidak Cuti</span>}
                      description={<span style={{ fontSize: '12px' }}> Mohon pastikan <b> kepala satuan kerja tidak cuti </b> dan sudah di informasikan ke Kepala Satker sebelum membuat validation</span>}
                      type="error"
                      className="mb-3"

                    />
                    <Form.Item
                      label="Kepala Satuan Kerja"
                      name="direct_supervisor"
                      rules={[{ required: true, message: 'Satuan Kerja Wajib Isi' }]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Pilih Satuan Kerja"
                      >
                        {
                          satkers
                            .slice() // Membuat salinan array untuk menghindari pengaruh pada array asli
                            // .sort((a, b) => b.detail.level.number - a.detail.level.number) // Mengurutkan dari atas ke bawah berdasarkan level.number
                            .map((satker) => (
                              <Select.Option key={satker.detail.nik} value={satker.detail.nik}>
                                {satker.label}
                              </Select.Option>
                            ))
                        }
                      </Select>

                    </Form.Item>
                  </>
                ) : (<center>Mohon tunggu.. <Spin className='p-3' /></center>)
              }
            </Col>

          </Row>
          <Row gutter={10} className="mb-1">
            <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                label="Material yang terlibat"
                name="involved_materials"
                rules={[
                  { required: true },
                  { max: 180, message: 'Maksimal 180 karakter' },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="mb-1">
            <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                label="Peralatan Keselamatan"
                name="safety_equipment"
                rules={[
                  { required: true },
                  { max: 180, message: 'Maksimal 180 karakter' },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Alert
            message="Keterangan:"
            description={
              <div>
                <p>
                  1. Formulir Validasi Izin Kerja ini tidak dapat dipisahkan dari Formulir Izin Kerja Pekerjaan Berbahaya / Pengangkatan yang telah disetujui oleh Kepala Teknik Tambang
                </p>
                <p>
                  2. Validasi ini berlaku maksimal 6 x 24 jam dan wajib dilakukan verifikasi lapangan setiap hari sebelum kegiatan dimulai.
                </p>
                <p>
                  3. Validasi Izin Kerja tidak berlaku apabila terdapat perbedaan pekerjaan dari yang tertulis dalam formulir Izin Kerja Pekerjaan Berbahaya / Pengangkatan:
                </p>
                <ul style={{ listStyleType: 'lower-alpha' }}>
                  <li>Tidak dilakukan persetujuan verifikasi.</li>
                  <li>Tidak melakukan validasi izin harian.</li>
                  <li>Izin kerja Pekerjaan Berbahaya / Pengangkatan tidak disetujui oleh KTT.</li>
                  <li>Terjadi ketidaksesuaian, kecelakaan, kejadian lingkungan, dan kejadian berbahaya selama proses kegiatan.</li>
                </ul>
              </div>
            }
            type="info"
            showIcon
          />
          <Form.Item
            className="mt-3"
            wrapperCol={{
              xs: {
                offset: 10, span: 18
              },
              sm: {
                offset: 10, span: 18
              },
              lg: { offset: 10, span: 18 }
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewWP);
