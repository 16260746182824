import { Descriptions, Form, Radio, Collapse, Button, Comment, Avatar } from "antd";
import { useNavigate, useParams } from "react-router";
import { PrinterOutlined } from "@ant-design/icons";
import { Block, Loading, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import HeaderFarms from "../../../utils/header_farms";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';

function ViewTS() {

  const [publicHoliday, setPublicHoliday] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [, setCurrentUnit] = useState(null);
  const [, setCaption] = useState({});

  const [outstandingLastMonth, setOutstandingLastMonth] = useState(0);
  const [outstandingThisMonth, setOutstandingThisMonth] = useState(0);
  const [totalWeekOnSite, setTotalWeekOnSite] = useState(0);
  const [totalHomeBase, setTotalHomeBase] = useState(0);
  const [totalPermit, setTotalPermit] = useState(0);
  const [totalLeave, SetTotalLeave] = useState(0);
  const [totalWeek, setTotalWeek] = useState(0);
  const [totalSick, SetTotalSick] = useState(0);

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [, setIdApprovalList] = useState(null);
  const [tsheader, setTsHeader] = useState(null)
  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();
  const { Panel } = Collapse;
  // const readOnly = true;

  let navigate = useNavigate();
  let { id } = useParams();

  const getAPIHoliday = () => {
    fetch("https://date.nager.at/api/v3/publicholidays/2023/ID")
      .then((response) => response.json())
      .then((data) => setPublicHoliday(data));
  };
  
  const styleStatus = (res) => {
    let status = res?.approval_process.status 
    && res.approval_process_id.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  const getDetailTS = (token) => {
    API.viewTS(HeaderFarms(token), id).then((res => {
      loopDateFromAPI(res.data)
      setTsHeader(res.data)
      styleStatus(tsheader)

      console.log(res.data)
      setOutstandingLastMonth(parseFloat(res.data?.outstanding_last_month || 0))
      setOutstandingThisMonth(parseFloat(res.data?.outstanding_this_month || 0))
      setCurrentUnit(res.data.uid.company.code)
      setIdApprovalList(res.data.approval_id_list)

      Block.remove('.ant-collapse');
      Block.remove('.table-responsive')

    })).catch((err) => {
      console.error(err)
      Report.info(
        "Timesheet Not Found",
        "Sorry, Timesheet not founding",
        "Okay"
      );
      Block.remove('.ant-collapse');
      Block.remove('.table-responsive')
    })
  }

  function RequestToken() {

    Block.hourglass('.ant-collapse');
    Block.hourglass('.table-responsive')

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      getDetailTS(response.accessToken)
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
      Block.remove('.ant-collapse');
      Block.remove('.table-responsive')
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((res) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  const filterByDate = (date) => {
    let loc = null;

    publicHoliday.push({
      date: "2023-07-19",
      localName: "Tahun Baru Islam 1445 Hijriah",
      name: "Tahun Baru Islam 1445 Hijriah",
      countryCode: "ID",
      fixed: true,
      global: true,
      counties: null,
      launchYear: null,
      types: [
        "Public"
      ]
    });

    
    publicHoliday.forEach((item) => {
      if (date === item.date) {
        loc = String(item.localName);
      }

      // get newyear, because only check date and month, not year
      if (date.substring(5, 10) === item.date.substring(5, 10)) {
        loc = String(item.localName);
      }

    });

    return loc
  };

  const loopDateFromAPI = (data) => {

    let DTO = [];
    let dataApi = data.details_old.length > 0 ? data.details_old : data.details

    dataApi.forEach((item, index) => {

      DTO.push({
        ts_row_id_dtl: item.ts_row_id_dtl,
        ts_date_dtl: item.ts_date_dtl,
        ts_loc_dtl: item.ts_loc_dtl ? index + '_' + item.ts_loc_dtl : null, //must null for counter
        ts_reason_dtl: item.ts_reason_dtl ? index + '_' + item.ts_reason_dtl : null,
        ts_note_dtl: item.ts_note_dtl, //must null for counter
      })

    })

    setDateList(DTO);
    setTotalWeek(data.total_work)
    setTotalWeekOnSite(data.total_site)
    SetTotalLeave(data.total_leave)
    SetTotalSick(data.total_sick)
    setTotalPermit(data.total_permit)
    setTotalHomeBase(data.total_home_base)

    // for caption
    setCaption({ startDate: data.date_from, endDate: data.date_to });
    Loading.remove();
  };

  const handleFormChange = (index, event) => {


  };

  var get_week = function (date) {
    var dt = new Date(date);

    if (dt.getDay() === 6 || dt.getDay() === 0) {
      return true;
    }

    return false;
  };


  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
        getAPIHoliday();
      } else {
        handleLogin(instance)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Timesheet"
        navigate="/ts/history"
      ></Navbar>
      <div className="container white-back-cr" style={{ minHeight: 500 }}>
        <div className="container mt-3 p-3">
          <div className="row">
            <div className="col-md-9 mb-2">
              <Collapse defaultActiveKey={['1']}>
                <Panel header="Timesheet Information" key="1">
                  {dateList ? (
                    <>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          {
                            tsheader && (
                              <Descriptions size="small" bordered title="Information" className="mb-4">
                                <Descriptions.Item label="Form ID">
                                  {tsheader.form_submit_id}
                                </Descriptions.Item>
                                <Descriptions.Item label="Approver">
                                  {tsheader.approval_process.detail[0].approved_by.fullname}
                                </Descriptions.Item>
                                <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                  {tsheader.approval_process.status}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date From">
                                  {moment(tsheader.date_from).format(
                                    "YYYY MMM DD"
                                  )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date To">
                                  {moment(tsheader.date_to).format(
                                    "YYYY MMM DD"
                                  )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Created at">
                                  {moment(tsheader.created_at).format('LLL')}
                                </Descriptions.Item>
                              </Descriptions>
                            )
                          }
                          <Descriptions bordered title="Attendance Recap" style={{ fontSize: 10 }}>
                            <Descriptions.Item style={{ background: "#c5eded" }} label="Total Presence">
                              <b>{totalWeek}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#c5eded" }} label="Home Base">
                              <b>{totalHomeBase}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#c5eded" }} label="Site / Unit">
                              <b>{totalWeekOnSite}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#f0b1b1" }} label="Leave">
                              <b>{totalLeave}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#f0b1b1" }} label="Sick">
                              <b>{totalSick}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#f0b1b1" }} label="Business Trip">
                              <b>{totalPermit}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#319F4F" }} label="Last Month Outstanding">
                              <b>{outstandingLastMonth}</b>
                            </Descriptions.Item>
                            <Descriptions.Item style={{ background: "#319F4F" }} label="This Month Outstanding">
                              <b>{(outstandingLastMonth + outstandingThisMonth).toFixed(1)}</b>
                            </Descriptions.Item>
                            
                          </Descriptions>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Panel>
              </Collapse>
            </div>
            {tsheader && tsheader.approval_process.approval_key && (
              <>
                <div className="col-md-9 mb-2">
                  <Collapse defaultActiveKey={['1']}>
                    <Panel header="Approved Barcode" key="1">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${tsheader.approval_process.approval_key}`} />
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </>

            )}
            <div className="col-md-9 mb-3">
              {
                tsheader && tsheader.comments.length > 0 && (
                  <Collapse defaultActiveKey={['1']}>
                    <Panel header="Comments" key="1">
                      {
                        tsheader && tsheader.comments.map(data => {
                          return (
                            <Comment
                              author={<p>{data.uid.fullname}</p>}
                              avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                              content={
                                <p>
                                  {data.text_plain}
                                </p>
                              }
                            />
                          )
                        })
                      }
                    </Panel>
                  </Collapse>
                )
              }
            </div>
            <div className="col-md-9">
              <Form size="small" onFinish={null}>
                <div className="table-responsive">
                  {dateList && (
                    <table
                      className="table table-bordered table-striped"
                    >
                      <thead className="table-primary" id="theadp">
                        <tr>
                          <th rowSpan={2} style={{ verticalAlign: "middle", textAlign: "center" }}>Date</th>
                          <th colSpan={4} style={{ textAlign: "center", background: "#c5eded" }}>Presence</th>
                          <th rowSpan={1} colSpan={3} style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Absence</th>
                          <th rowSpan={2} colSpan={2} style={{ verticalAlign: "middle", textAlign: "center" }}>Note</th>
                        </tr>
                        <tr>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>BRM</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>CPM</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>BUSINESS TRIP</th>
                          {/* <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>GMI</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>LMR</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>DPM</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>SHS</th> */}
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#c5eded" }}>Other</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Leave</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Sick</th>
                          <th style={{ verticalAlign: "middle", textAlign: "center", background: "#f0b1b1" }}>Day Off</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dateList && (
                            dateList.map((item, i) => {

                              let backgroundColor = {};

                              if (get_week(item.ts_date_dtl) || filterByDate(moment(item.ts_date_dtl).format('YYYY-MM-DD'))) {
                                backgroundColor = { backgroundColor: '#34A853', color: '#FFFFFF', height: 41 };
                              } else if (moment(item.ts_date_dtl).isBetween('2023-04-19', '2023-04-25', 'day', '[]')) {
                                backgroundColor = { backgroundColor: '#F0B1B1', height: 41 };
                              } else {
                                backgroundColor = { height: 41 };
                              }

                              return (
                                <tr style={{ verticalAlign: 'middle', textAlign: 'center', ...backgroundColor }}>
                                  <td style={{ fontSize: 10, minWidth: 10, fontWeight: "bold", verticalAlign: "middle", textAlign: "center" }}>{moment(item.ts_date_dtl).format("DD")}</td>
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                    <Form.Item className="form-item-ts" id={i + 'form-item-ts'}
                                    >
                                      <Radio.Group
                                        size="small"
                                        onChange={(event) =>
                                          handleFormChange(i, event)
                                        }
                                        name="ts_loc_dtl"
                                        value={item.ts_loc_dtl}
                                      >
                                        <Radio
                                          style={
                                            (item.ts_date_dtl)
                                              ? { color: "#FFFFFF" }
                                              : filterByDate(moment(item.ts_date_dtl).format("YYYY-MM-DD"))
                                                ? { color: "#FFFFFF" }
                                                : (moment(item.ts_date_dtl).isSame('2023-04-19', 'day') || moment(item.ts_date_dtl).isSame('2023-04-25', 'day'))
                                                  ? { color: "#999999", cursor: "not-allowed" }
                                                  : {}
                                          }
                                          value={i + "_BRM"}
                                          disabled={moment(item.ts_date_dtl).isBetween('2023-04-19', '2023-04-25', 'day', '[]')}
                                        >
                                        </Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </td>
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                    <Form.Item className="form-item-ts" id={i + 'form-item-ts-radio'}>
                                      <Radio.Group
                                        onChange={(event) =>
                                          handleFormChange(i, event)
                                        }
                                        name="ts_loc_dtl"
                                        value={item.ts_loc_dtl}>

                                        <Radio
                                          style={
                                            get_week(item.ts_date_dtl)
                                              ? { color: "#FFFFFF" }
                                              : filterByDate(
                                                moment(
                                                  item.ts_date_dtl
                                                ).format("YYYY-MM-DD")
                                              )
                                                ? { color: "#FFFFFF" }
                                                : {}
                                          }
                                          value={i + "_CPM"}
                                        >
                                        </Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </td>
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                      <Form.Item className="form-item-ts">
                                        <Radio.Group
                                          onChange={(event) =>
                                            handleFormChange(i, event)
                                          }
                                          name="ts_reason_dtl"
                                          value={item.ts_loc_dtl}>
                                          <Radio
                                            style={
                                              get_week(item.ts_date_dtl)
                                                ? { color: "#FFFFFF" }
                                                : {}
                                            }
                                            value={i + "_BUSINESSTRIP"}
                                          >
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                  </td>
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                    <Form.Item className="form-item-ts">
                                      <Radio.Group
                                        onChange={(event) =>
                                          handleFormChange(i, event)
                                        }
                                        name="ts_loc_dtl"
                                        value={item.ts_loc_dtl}>
                                        <Radio
                                          style={
                                            get_week(item.ts_date_dtl)
                                              ? { color: "#FFFFFF" }
                                              : filterByDate(
                                                moment(
                                                  item.ts_date_dtl
                                                ).format("YYYY-MM-DD")
                                              )
                                                ? { color: "#FFFFFF" }
                                                : {}
                                          }
                                          value={i + "_OTHER"}
                                        >
                                        </Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </td>
                                  
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                      <Form.Item className="form-item-ts">
                                        <Radio.Group
                                          onChange={(event) =>
                                            handleFormChange(i, event)
                                          }
                                          name="ts_reason_dtl"
                                          value={item.ts_reason_dtl}>
                                          <Radio
                                            style={
                                              get_week(item.ts_date_dtl)
                                                ? { color: "#FFFFFF" }
                                                : {}
                                            }
                                            value={i + "_LEAVE"}
                                          >
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                  </td>
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                      <Form.Item className="form-item-ts">
                                        <Radio.Group
                                          onChange={(event) =>
                                            handleFormChange(i, event)
                                          }
                                          name="ts_reason_dtl"
                                          value={item.ts_reason_dtl}>
                                          <Radio
                                            style={
                                              get_week(item.ts_date_dtl)
                                                ? { color: "#FFFFFF" }
                                                : {}
                                            }
                                            value={i + "_SICK"}
                                          >
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                  </td>
                                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                      <Form.Item className="form-item-ts">
                                        <Radio.Group
                                          onChange={(event) =>
                                            handleFormChange(i, event)
                                          }
                                          name="ts_reason_dtl"
                                          value={item.ts_reason_dtl}
                                        >
                                          <Radio
                                            style={
                                              get_week(item.ts_date_dtl)
                                                ? { color: "#FFFFFF" }
                                                : {}
                                            }
                                            value={i + "_HOLIDAY"}

                                          >
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                  </td>
                                  <td style={{ width: 200, minWidth: 150, verticalAlign: "middle", textAlign: "center" }}>
                                    <Form.Item className="form-item-ts">
                                      <input
                                        className="ant-input ant-input-sm"
                                        type={"text"}
                                        key={i + "-ts_note_dtl"}
                                        onBlur={(event) => {
                                          handleFormChange(i, event)
                                        }}
                                        placeholder={

                                          (moment(item.ts_date_dtl).isBetween('2023-04-19', '2023-04-25', 'day', '[]') &&
                                            (moment(item.ts_date_dtl).isBefore('2023-04-22') || moment(item.ts_date_dtl).isAfter('2023-04-23')))
                                            ? "Cuti Bersama"
                                            : filterByDate(moment(item.ts_date_dtl).format("YYYY-MM-DD"))

                                        }
                                        defaultValue={item.ts_note_dtl}
                                        name="ts_note_dtl"
                                        style={{
                                          color: 'grey',
                                          fontStyle: 'italic'
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                </tr>
                              )
                            })
                          )
                        }
                      </tbody>
                    </table>
                  )}
                </div>
              </Form>
            </div>
            {tsheader && tsheader.approval_process.approval_key ? (
              <div className="col-md-9 mt-2">
                <Button type="primary" onClick={() => navigate('/ts/print/' + id)} title="Print" icon={<PrinterOutlined />}>Print</Button>
              </div>
            ) : ''}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewTS);

