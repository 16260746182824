import { Collapse, Table, Descriptions, Comment, Avatar, Timeline, Alert, Spin, Affix, Form, Button, Modal, Input } from "antd";
import { FilePdfOutlined, PrinterOutlined } from "@ant-design/icons";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from '../../../config/api-config';
import { useNavigate, useParams } from "react-router";
import React, { useState, useEffect } from 'react';
import { Confirm, Report } from "notiflix";
import Navbar from '../../../atoms/navbar';
import { QRCodeSVG } from 'qrcode.react';
import "./styles.css";

import HeaderFarms from "../../../utils/header_farms";
import Footer from "../../../atoms/footer";
import API from "../../../services";
import numeral from 'numeral';
import moment from "moment";


const ViewTA = () => {

  const [TA, setTA] = useState(null);
  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [hiddenCancel, setHiddenCancel] = useState(true);
  const [user, setUser] = useState(null);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { Panel } = Collapse;
  let navigate = useNavigate();
  let { id } = useParams();

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      getDetailTA(response.accessToken)
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });
  }

  const getDetailTA = (token) => {
    API.viewTA(HeaderFarms(token), id).then(((res) => {


      setTA(res)
      styleStatus(res.data)

      security(res.data)

      // only one call
      user || getUser(token)
    })).catch((err) => {
      console.error(err)
      Report.info(
        "Travel Authority",
        "Sorry, Travel Authority not found",
        "Okay"
      );
    })
  }

  const security = (res) => {
    if (user) {
      if (user.id === res.requestor_id._id) {
        setHiddenCancel(false)
      } else {
        setHiddenCancel(true)
      }
    }
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then(() => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  const cancelSR = (values) => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ?',
      'Yes',
      'No',
      () => {
        cancel(values)
      },
    );
  }

  const cancel = (values) => {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      setIsModalVisible(false)

      API.workflowCancel(HeaderFarms(response.accessToken), {
        approval_id: TA.data.approval_process_id._id,
        msg: values.message
      }).then((_) => {

        Report.success(
          `Cancel Success`,
          `Travel Authority berhasil di batalkan`,
          "Okay", () => {
            return navigate('/ta/history')
          })


      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
      setUser(user)
    } catch (error) {
      console.error(error)
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const styleStatus = (res) => {
    let status = res.approval_process_id.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    }
    else if (status === "Closed") {
      setStatusColor({
        background: "#52c41a",
        color: "#FFFFFF"
      })
    }
    else if (status === "Cancel") {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
    else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  function getUserList() {
    const userList = TA && TA.data.t_ta_user_dtl.map((user) => {
      let { expense_type, reason_for_travel, departure_date_end, departure_date_start, fieldbreak_date_end, fieldbreak_date_start } = user;
      const { fullname, employee_status } = user.user_id;

      const level = user.user_id.level && user.user_id.level.fullname

      const { code } = user.user_id.company;
      const department = user.user_id?.department?.fullname;

      departure_date_start = moment(departure_date_start || fieldbreak_date_start).format('DD-MMM-YY');
      departure_date_end = moment(departure_date_end || fieldbreak_date_end).format('DD-MMM-YY');

      return { department, code, fullname, level, employee_status, expense_type, reason_for_travel, departure_date_end, departure_date_start };
    });

    return userList;
  }

  function removeDuplicatesByProps(array, props) {
    if (!array) {
      return [];
    }

    return array.filter((item, index, self) =>
      index === self.findIndex((el) => (
        props.every((prop) => el[prop] === item[prop])
      ))
    );
  }

  function formatFlight(flight) {

    const from_date = moment(flight.from_date).format('DD-MMM-YY');
    const from = flight.from;
    const to = flight.to;
    const time = flight.time;
    const airline = flight.airline;
    const _id = flight._id;
    const id_record = flight.t_ta_user_dtl_id;
    const e_ticket = flight.e_ticket;
    const flight_id = flight.flight_id;
    const ticket_price = flight.ticket_price;
    const author = flight.author_ticket && flight.author_ticket.fullname;
    const seat_class = flight.seat_class;

    return {
      from_date,
      from,
      to,
      time,
      airline,
      _id,
      id_record,
      e_ticket,
      flight_id,
      ticket_price,
      author,
      seat_class
    };
  }

  function formatAccommodation(accommodations) {
    const dateIn = moment(accommodations.accomodation_date_in).format('DD-MMM-YY');
    const dateOut = moment(accommodations.accomodation_date_out).format('DD-MMM-YY');
    const accommodation = accommodations.accomodation;
    const _id = accommodations._id

    return { dateIn, dateOut, accommodation, _id };
  }

  function formatCostEst(costEst) {
    const { item, total, _id } = costEst;
    const formated = numeral(total).format('0,0');
    return { item, total, formated, _id };
  }

  function formatDetUserList(formatDetUser) {
    const date_birth = formatDetUser.date_birth;
    const no_ktp = formatDetUser.no_ktp;
    const place_of_birth = formatDetUser.place_of_birth
    const phone_number = formatDetUser.phone_number
    const departure_date_start = formatDetUser.departure_date_start
    const departure_date_end = formatDetUser.departure_date_end
    return { date_birth, no_ktp, place_of_birth, phone_number, departure_date_start, departure_date_end };
  }

  function getFlightList() {
    const flights = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
      user.t_ta_rsv_dst_dtl.map(formatFlight)
    );

    const uniqueFlights = removeDuplicatesByProps(flights, ['from_date', 'from', 'to', 'time', 'airline', '_id', 'id_record', 'seat_class', 'e_ticket', 'flight_id', 'ticket_price', '_id']);

    return uniqueFlights;
  }

  function getAccommodationDates() {

    const accommodationDates = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
      user.t_ta_rsv_hotel_dtl.map(formatAccommodation)
    );

    const uniqueAccommodationDates = removeDuplicatesByProps(accommodationDates, ['dateIn', 'dateOut', 'accommodation', '_id']);

    return uniqueAccommodationDates;
  }

  function getAccommodationMessDates() {

    const accommodationMessDates = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
      user.t_ta_rsv_mess_dtl.map(accomodation => {
        const traveller = user.user_id.fullname
        const messName = accomodation.mess_name

        return { traveller, messName };
      })
    );

    const uniqueAccommodationMess = removeDuplicatesByProps(accommodationMessDates, ['traveller', 'messName'])

    return uniqueAccommodationMess;

  }

  function getDetUserList() {
    const userList = TA && TA.data.t_ta_user_dtl.flatMap((user) =>
      formatDetUserList(user.user_id)
    );
    const uniqueDetUsers = removeDuplicatesByProps(userList, ['date_birth', 'no_ktp', 'place_of_birth', 'phone_number']);

    return uniqueDetUsers;
  }

  function getCostEstlist() {
    const costEstList = TA && TA.data.t_ta_user_dtl.flatMap((userDtl) =>
      userDtl.t_ta_cost_est_dtl.map(formatCostEst)
    );

    const uniqueCostEstList = removeDuplicatesByProps(costEstList, ['item', 'total', 'formated', '_id']);

    return uniqueCostEstList;
  }

  function getObjectiveList() {
    const objectiveList = [];

    if (TA && TA.data.t_ta_user_dtl) {
      const userDtlList = TA.data.t_ta_user_dtl;
      for (let i = 0; i < userDtlList.length; i++) {
        const userDtl = userDtlList[i];
        if (userDtl.objective) {
          const fullName = userDtl.user_id.fullname;
          const objective = userDtl.objective;
          objectiveList.push({ fullName, objective });
        }
      }
    }

    return objectiveList;
  }

  const columnDetUserList = [
    {
      title: 'NIK',
      dataIndex: 'no_ktp',
      key: 'no_ktp',
    },
    {
      title: 'Date Birth',
      dataIndex: 'date_birth',
      key: 'date_birth',
      render: date_birth => date_birth && moment(date_birth).format('DD-MMM-YY'),
    },
    {
      title: 'Place of Birth',
      dataIndex: 'place_of_birth',
      key: 'place_of_birth',
    },
    {
      title: 'Number Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
  ];


  const columnUserList = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'fullname',
      key: 'fullname',
      render: fullname => fullname, // Extract the full name before the ' - ' delimiter
    },
    {
      title: 'Jabatan',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Status',
      dataIndex: 'employee_status',
      key: 'employee_status',
    },
    {
      title: 'Expense Type',
      dataIndex: 'expense_type',
      key: 'expense_type',
      render: expenseType => expenseType,
    },
    {
      title: 'Reason',
      dataIndex: 'reason_for_travel',
      key: 'reason_for_travel',
    },
    {
      title: 'Start',
      dataIndex: 'departure_date_start',
      key: 'departure_date_start',
    },
    {
      title: 'End',
      dataIndex: 'departure_date_end',
      key: 'departure_date_end',
    },
  ];

  const flightListColumns = [
    {
      title: 'From Date',
      dataIndex: 'from_date',
      key: 'from_date',
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      render: from => from, // Extract the code and remove parentheses
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      render: to => to // Extract the code and remove parentheses
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Airline',
      dataIndex: 'airline',
      key: 'airline',
    },
    {
      title: 'Class',
      dataIndex: 'seat_class',
      key: 'seat_class',
    },
    {
      title: 'Ticket Price',
      dataIndex: 'ticket_price',
      key: 'ticket_price',
      render: (text) => {
        return numeral(text).format('0,0');
      }
    },
    {
      title: 'Flight ID',
      dataIndex: 'flight_id',
      key: 'flight_id',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'E - Ticket',
      dataIndex: 'e_ticket',
      key: 'e_ticket',
      render: (text, record) => {
        if (text) {
          return (<Button type="link" size='small' href={`https://farms-travel-authority.s3.ap-southeast-3.amazonaws.com/${text}`} target="_blank" rel="noopener noreferrer">
            <FilePdfOutlined /> View
          </Button>)
        }
      }
    },
  ];

  const AccomodationListColumns = [
    {
      title: 'Hotel Name',
      dataIndex: 'accommodation',
      key: 'accommodation',
    },
    {
      title: 'Date In',
      dataIndex: 'dateIn',
      key: 'dateIn',
    },
    {
      title: 'Date Out',
      dataIndex: 'dateOut',
      key: 'dateOut',
    },
  ];

  const AccomodationMessListColumns = [
    {
      title: 'Mess Name',
      dataIndex: 'messName',
      key: 'messName',
    },
    {
      title: 'Traveller',
      dataIndex: 'traveller',
      key: 'traveller',
    },
  ];

  const CostEstColumns = [
    {
      title: 'Desc',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Total',
      dataIndex: 'formated',
      key: 'formated',
    },
  ];

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Travel Authority"
        navigate="/ta/history"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <div className="container mt-3 " style={{ alignSelf: "center" }}>

          {TA && TA.data.approval_process_id.approval_key ? (
            <div className="col-md-9 mb-2">
              <Button type="primary" onClick={() => navigate('/ta/print/' + id)} title="Print" icon={<PrinterOutlined />}>Print</Button>
            </div>
          ) : ''}

          {
            !TA && (
              <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                <Spin size="large" />
              </div>
            )
          }
          {
            TA && (
              <>
                <div className="col-md-12 mb-2">
                  <Collapse defaultActiveKey={['1']}>
                    <Panel header={TA ? (
                      <div>
                        Detail Information
                      </div>
                    ) : "Travel Authority Information"} key="1">
                      {TA ? (
                        <>
                          <div className="row mt-1">
                            <div className="col-md-12">
                              {
                                TA && (
                                  <Descriptions size="small" bordered title="Information" className="mb-1">
                                    <Descriptions.Item label="Form ID">
                                      {TA.data.id_record}
                                    </Descriptions.Item>
                                    <Descriptions.Item className="col-3" label="Travel Type">
                                      {TA.data.type_travel}
                                    </Descriptions.Item>
                                  </Descriptions>
                                )
                              }
                            </div>
                            <div className="col-md-12">
                              {
                                TA && (
                                  <Descriptions size="small" bordered className="mb-4">
                                    <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                      {TA.data.approval_process_id.status}
                                    </Descriptions.Item>
                                    <Descriptions.Item className="col-3" label="Created at">
                                      {moment(TA.data.created_at).format('LLL')}
                                    </Descriptions.Item>
                                  </Descriptions>
                                )
                              }
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                              <Timeline style={{ marginTop: 30 }}>
                                {
                                  TA && TA.data.approval_process_id.detail.map(item => {
                                    return (
                                      <Timeline.Item >{item.approved_by.fullname}
                                        <Alert
                                          message={item.status ? 'Approved' : 'Waiting Approval'}
                                          className='mt-1 p-1'
                                          type={item.status ? 'success' : 'warning'}
                                          showIcon
                                        />
                                      </Timeline.Item>
                                    )
                                  })
                                }
                              </Timeline>
                            </div>
                            {TA && TA.data.approval_process_id.approval_key && (
                              <>
                                <div className="col-md-6 mt-3">
                                  <label style={{ fontSize: 15, fontWeight: "bold" }}>Approved Barcode</label>
                                  <div className="row mt-4">
                                    <div className="col-md-12 text-center">
                                      <QRCodeSVG size={130} style={{ padding: 10 }} value={`${window.location.origin}/wf/approved/${TA.data.approval_process_id.approval_key}`} />
                                    </div>
                                  </div>

                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Panel>
                  </Collapse>
                </div>
                <div className="col-md-12 mb-3">
                  {
                    TA && TA.data.comments.length > 0 && (
                      <Collapse defaultActiveKey={['1']}>
                        <Panel header="Comments" key="1">
                          {
                            TA.data.comments.map(data => {
                              return (
                                <Comment
                                  author={<p>{data.uid.fullname}</p>}
                                  avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                  content={
                                    <p>
                                      {data.text_plain}
                                    </p>
                                  }
                                />
                              )
                            })
                          }
                        </Panel>
                      </Collapse>
                    )
                  }
                </div>
              </>
            )
          }

          {getUserList() && getUserList().length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>Traveler</h6>
                <Table
                  dataSource={getUserList()}
                  className="custom-table"
                  pagination={{
                    hideOnSinglePage: true,
                    showLessItems: true
                  }}
                  columns={columnUserList}
                />
              </div>
            </div>
          )}

          {getFlightList() && getFlightList().length > 0 && (
            <div className="row mt-3">
              <div className="col-md-12">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>Reservation & Destination</h6>
                <Table
                  dataSource={getFlightList()}
                  className="custom-table"
                  pagination={{
                    hideOnSinglePage: true,
                    showLessItems: true
                  }}
                  columns={flightListColumns}
                />
              </div>
            </div>
          )}

          {getAccommodationMessDates() && getAccommodationMessDates().length > 0 && (
            <div className="row mt-3">
              <div className="col-md-12">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>Accomodation Mess</h6>
                <Table
                  dataSource={getAccommodationMessDates()}
                  className="custom-table"
                  pagination={{
                    hideOnSinglePage: true,
                    showLessItems: true
                  }}
                  columns={AccomodationMessListColumns}
                />
              </div>
            </div>
          )}

          {getDetUserList() && getDetUserList().length > 0 && (
            <div className="row mt-2">
              <div className="col-md-12">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>Detail Traveler</h6>
                <Table
                  dataSource={getDetUserList()}
                  className="custom-table"
                  pagination={{
                    hideOnSinglePage: true,
                    showLessItems: true
                  }}
                  columns={columnDetUserList}
                />
              </div>
            </div>
          )}

          {getAccommodationDates() && getAccommodationDates().length > 0 && (
            <div className="row mt-2">
              <div className="col-md-12">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>Accomodation Hotel</h6>
                <Table
                  dataSource={getAccommodationDates()}
                  className="custom-table"
                  pagination={{
                    hideOnSinglePage: true,
                    showLessItems: true
                  }}
                  columns={AccomodationListColumns}
                />
              </div>
            </div>
          )}

          {getCostEstlist() && getCostEstlist().length > 0 && (
            <div className="row mt-4">
              <div className="col-md-12">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>Travel Cost Estimation</h6>
                <Table
                  dataSource={[...getCostEstlist(), { item: 'Total Cost', formated: numeral(getCostEstlist().reduce((accumulator, currentItem) => accumulator + currentItem.total, 0)).format('0,0') }]}
                  className="custom-table custom-table-cost"
                  pagination={{
                    hideOnSinglePage: true,
                    showLessItems: true
                  }}
                  columns={CostEstColumns}
                />

              </div>
            </div>
          )}

          {
            TA && (
              <div className="col-md-12 mt-4">
                <h6 style={{ background: "#d8d8d8", padding: "10px", borderRadius: 2 }}>User Objective</h6>
                {getObjectiveList().map(item => {
                  return (
                    <Comment
                      author={item.fullName}
                      content={item.objective}
                    />
                  )
                })}
              </div>
            )
          }
        </div>
        {!hiddenCancel && (
          TA && (TA.data.approval_process_id.status === 'Waiting Approval' || TA.data.approval_process_id.status === 'Approved') && (
            <div className="row mt-4">
              <div className="col-md-12">
                <Affix offsetBottom={10}>
                  <Form.Item className="text-center">
                    <Button type="danger" onClick={showModal} className="m-3" size="large" htmlType="submit">
                      Cancel
                    </Button>
                  </Form.Item>
                </Affix>
              </div>
            </div>
          )
        )}
      </div >
      <Modal
        title="Write the reason"
        footer={null}
        visible={isModalVisible}
        onOk={null}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={cancelSR}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label=""
            name="message"
            rules={[
              {
                required: true,
                message: "Please input your reason",
              },
            ]}
          >
            <Input.TextArea
              showCount
              style={{ height: "150px", width: "100%" }}
              minLength={50}
            />
          </Form.Item>
          <Form.Item
            className="mt-3"
            wrapperCol={{
              xs: {
                offset: 8, span: 18
              },
              sm: {
                offset: 7, span: 18
              },
              lg: { offset: 8, span: 18 }
            }}

          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Footer></Footer>
    </>
  );
};

export default ViewTA;