import { PageHeader, Result, Button, Form, Select, Input } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Loading, Notify, Report } from 'notiflix';
import React from 'react';
import { connect } from "react-redux";

import { initializeVerification } from '../../../redux/action';
import API from '../../../services';

import HeaderFarms from '../../../utils/header_farms';
import Footer from '../../../atoms/footer';
import WaLogo from './walogo.png'

const { Option } = Select;

function VerificationChangeNumber() {

    let navigate = useNavigate();
    let { token } = useParams();

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select defaultValue={"62"} style={{ width: 70 }}>
                <Option value="62">+62</Option>
            </Select>
        </Form.Item>
    );

    const onChangeNumber = (value) => {
        Loading.hourglass('Just a moment');

        let getNumber = "62" + removeLeadingZeros(value.phone)

        API.waVerification(HeaderFarms(token), {
            number: getNumber
        }).then(res => {
            Loading.remove()
            Report.success(
                `Activation Success`,
                '',
                "Okay",
                function () {
                    navigate('/home')
                }
            );
        }).catch(err => {
            Loading.remove()
            if (err.response.status === 401) {
                Loading.remove()
                Notify.warning('Waktu verifikasi telah habis.', {
                    timeout: 10000,
                    position: 'center-top'
                });
                navigate('/home')
            } else {
                Loading.remove()
                Notify.warning('Nomor whatsapp tidak di temukan dimohon untuk cek kembali format atau nomor yang anda tulis ', {
                    timeout: 10000,
                    position: 'center-top'
                });
            }
        })
    }

    function removeLeadingZeros(str) {
        // Regex to remove leading
        // zeros from a string
        const regex = new RegExp("^0+(?!$)", 'g');

        // Replaces the matched
        // value with given string
        str = str.replaceAll(regex, "");

        return str
    }

    return (
        <>
            <div className='container' style={{ minHeight: "700px" }}>
                <div className='row'>
                    <div className='col-md-12'>
                        <PageHeader
                            ghost={true}
                            title="Whatsapp Verification"
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Result
                            status="info"
                            icon={<img src={WaLogo} width="120px" alt='' />}
                            title={`Masukan nomor whatsapp pribadi anda.`}
                            extra={<>
                                <Form size='middle' onFinish={onChangeNumber}>
                                    <Form.Item
                                        name="phone"
                                        label=""
                                        className='mt-3'
                                        rules={[{ required: true, message: 'Number phone not valid', pattern: "^[0-9]*$", }]}
                                    >
                                        <Input size='large' placeholder='e.g 85977300189' addonBefore={prefixSelector} style={{ width: '80%' }} />
                                    </Form.Item>
                                    <Form.Item className='mt-5'>
                                        <Button type="primary" size='large' htmlType="submit">
                                            Simpan
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>}
                        />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

const reduxState = (state) => ({
    // initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
    setInitializeVerification: (data) =>
        dispatch(initializeVerification(data)),
});

export default connect(reduxState, reduxDispatch)(VerificationChangeNumber);
