import { initializeMobile, InitializeTicket } from "../../../redux/action";
import { Switch, Badge, Affix, Button, Empty, Spin } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { Fragment, useEffect, useState } from "react";
import { Loading } from "notiflix";
import { connect } from "react-redux";

import Navbar from "../../../atoms/navbar";
import Header from "../../../utils/header_osticket";
import ImportantInfo from "../../../atoms/important-updates";


import { loginRequest } from "../../../config/api-config";
import { callMsGraph } from "../../../utils/graph";
import { useNavigate } from "react-router";
import Footer from "../../../atoms/footer";
import API from "../../../services";
import "./styles.css";


function HistoryTicket(props) {

  let navigate = useNavigate();
  const [ticketList, setTicketList] = useState([]);
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState(true);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  function getAllTicket(mail) {
    API.DetailTicket(Header(), mail)
      .then((res) => {

        res.tickets.sort(function (a, b) {
          return new Date(b.create_timestamp) - new Date(a.create_timestamp);
        });

        setTicketList(res.tickets);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((res) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  function onChangeSwitch(checked) {
    if (!checked) {
      setFilter(false);
    } else {
      setFilter(true);
    }
  }

  function goDetail(id) {
    navigate(`/help/detail/${id}`);
  }

  function RequestToken() {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      callMsGraph(response.accessToken).then(response => {

        getAllTicket(response.mail || response.userPrincipalName);

        // Save Session
        props.setInitializeTicket(null, {
          email: response.mail || response.userPrincipalName,
          fullname: response.displayName,
          phonenumber: response.mobilePhone || props.inititialSession.phone_number,
        });

      })

    }).catch((e) => {
      Loading.remove();
      handleLogin(instance)
    });
  }

  useEffect(() => {

    if (!props.inititialSession) {
      navigate('/')
    }

    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

  }, [isAuthenticated])

  return (
    <Fragment>
      <Navbar
        title="Ticket History"
        // if mobile back button hide, because show in mobile
        back={true}
        subtitle="Help Center"
        navigate="/"
      ></Navbar>
      <div className="container white-back-cr" style={{ minHeight: 500 }}>
        <ImportantInfo></ImportantInfo>
        <div className="container mt-3 p-3">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Affix offsetTop={10}>
                <div className="col-md-6 d-flex justify-content-start">
                  <Button
                    type="primary"
                    onClick={() => {
                      if (props.inititialSession) {
                        navigate("/help/create-ticket-detail")
                      } else {
                        navigate("/help/create-ticket")
                      }
                    }}
                    size="middle"
                  >
                    Create Ticket
                  </Button>
                </div>
              </Affix>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col"></div>
            <div className="col-md-6 d-flex justify-content-end">
              <label style={{ marginRight: 10 }}>Filter</label>
              <Switch
                size="default"
                className="ml-2"
                checkedChildren="Open"
                unCheckedChildren="Finished"
                defaultChecked
                onChange={onChangeSwitch}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 offset-md-1">
              {ticketList.length > 0 ?
                ticketList.map(function (data, index) {
                  if (filter) {
                    if (data.ticket_status === "Open") {
                      return (
                        <Fragment key={index}>
                          <Badge.Ribbon color="blue" text={data.ticket_status}>
                            <div
                              className="list-style p-3 px-4 mb-3"
                              onClick={() => goDetail(data.ticket_number)}
                            >
                              <div className="title mb-4">
                                <p className="fs-7 mb-0">{data.subject}</p>
                              </div>
                              <div className="hour d-flex justify-content-between m-1">
                                <p className="fs-7" style={{ color: "#B7B7B7" }}>
                                  {data.create_timestamp}
                                </p>
                              </div>
                            </div>
                          </Badge.Ribbon>
                        </Fragment>
                      );
                    }
                  } else {
                    if (
                      data.ticket_status === "Closed" ||
                      data.ticket_status === "Resolved"
                    ) {
                      return (
                        <Fragment key={index}>
                          <div
                            className="list-style p-3 px-4 mb-3"
                            onClick={() => goDetail(data.ticket_number)}
                          >
                            <div className="title mb-4">
                              <p className="fs-7 mb-0">{data.subject}</p>
                            </div>
                            <div className="hour d-flex justify-content-between m-1">
                              <p className="fs-7" style={{ color: "#B7B7B7" }}>
                                {data.create_timestamp}
                              </p>
                              <p
                                className="fs-7 fw-bold"
                                style={
                                  data.ticket_status === "Open"
                                    ? { color: "#FF6961" }
                                    : { color: "#089D20" }
                                }
                              >
                                {data.ticket_status}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    }
                  }
                }) :
                <Empty className="mt-5" />
              }
              {
                ticketList.length > 0 && <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                  No more data
                </div>
              }
              {
                loading && (
                  <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                    <Spin size="large" />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}

const reduxState = (state) => ({
  initializeTicket: state.initializeTicket,
  initializeMobile: state.initializeMobile,
  inititialSession: state.session
});

const reduxDispatch = (dispatch) => ({
  setInitializeTicket: (navigate, data) =>
    dispatch(InitializeTicket(navigate, data)),
  setInitializeMobile: (data) => dispatch(initializeMobile(data)),
});

export default connect(reduxState, reduxDispatch)(HistoryTicket);
