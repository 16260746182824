import React, { useState, useEffect } from 'react';
import ImportantInfo from '../../../atoms/important-updates';
import FuelDeliveryHistory from '../history-delivery';
import FuelRequestHistory from '../history-request';
import CreateOperator from '../operator-register';
import Navbar from '../../../atoms/navbar';
import Footer from '../../../atoms/footer';
import { Tabs } from 'antd';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/api-config';


const FuelMenu = () => {
    // Mendapatkan nilai tab aktif dari local storage saat komponen dimuat
    const initialActiveTab = localStorage.getItem('activeTab') || "1";
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    // Fungsi untuk menangani perubahan tab
    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    async function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then(async (responseToken) => {
            })
            .catch((e) => {
                console.error(e)
            });
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    // Menyimpan nilai tab aktif ke local storage setiap kali nilai berubah
    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }


    }, [activeTab, inProgress, accounts, instance, isAuthenticated]);

    return (
        <>
            <Navbar
                title="Fuel Menu"
                back={true}
                subtitle="Fuel System"
                navigate="/home"
            />
            <div className="container-fluid white-back-cr">
                <ImportantInfo />
                <div className="container-fluid" style={{ alignSelf: "center" }}>
                    <Tabs
                        size='large'
                        defaultActiveKey={activeTab}
                        onChange={handleTabChange}
                    >
                        <Tabs.TabPane tab="Request Fuel" key="1">
                            <FuelRequestHistory />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Fuel Delivery" key="2">
                            <FuelDeliveryHistory />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Register Operator" key="3">
                            <CreateOperator />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FuelMenu;
