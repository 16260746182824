import axios from 'axios';

const Put = (path, header, data, timeout = 20000) => {
    const promise = new Promise((resolve, reject) => {
        axios.put(path, data, { headers: header, timeout: timeout })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
    return promise;
};

export default Put;

