import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import React from 'react';

const Success = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Result
            status="success"
            title="Berhasil ! JANGAN BERIKAN PIN ANDA KEPADA SIAPAPUN"
            subTitle="Nomor PIN Anda telah dikirimkan melalui WhatsApp / SMS dan Email Anda."
            extra={<Button type="primary"><Link to="/home" style={{ textDecoration: 'none' }}>Back Home</Link></Button>}
        />
    </div>
);

export default Success;
