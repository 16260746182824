import { Badge, Button, Dropdown, Empty, Menu, Spin } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { Fragment, useEffect, useState } from "react";
import { Loading, Report } from "notiflix";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

import ImportantInfo from "../../atoms/important-updates";
import Navbar from "../../atoms/navbar";
import Footer from "../../atoms/footer";
import "./styles.css";

import { loginRequest } from "../../config/api-config";
import { InitializeTicket } from "../../redux/action";
import HeaderFarms from "../../utils/header_farms";
import { FilterOutlined } from "@ant-design/icons";
import { callMsGraph } from "../../utils/graph";
import API from "../../services";

function WFPendingList() {

    let navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loading, setLoading] = useState(true)
    const [pendingList, setPendingList] = useState([]);

    const [filterStatus, setFilterStatus] = useState('Waiting Approval');


    function getAllPending(accessToken, email) {
        API.workflowPending(HeaderFarms(accessToken), email).then(res => {
            setPendingList(res.data)
            setLoading(false)
        })
            .catch((err) => {
                console.log(err)
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
                Loading.remove();
            });
    }

    const handleFilter = (status) => {
        setFilterStatus(status); // Set the filter status when the button is clicked
    };

    function goDetail(id) {

        /*
            IMPORTANT INFORMATION

            old time must save id form header for show the document pending approvals. 
            for now no need because mistake technical
        */

        let id_doc = id.approval_id.id_form_header ? id.approval_id.id_form_header : id.approval_id.form_submit_id

        //redirect based on code form / document
        navigate(`/wf/${id.approval_id.form_id.code.toLowerCase()}/view/${id_doc}`);
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function RequestToken() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {
                callMsGraph(responseToken.accessToken).then((response) => {
                    getAllPending(responseToken.accessToken, response.mail)
                });
            })
            .catch((e) => {
                handleLogin(instance)
                navigate("/");
            });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={() => handleFilter("Waiting Approval")}>
                <Button type="link">Waiting Approval</Button>
            </Menu.Item>
            <Menu.Item onClick={() => handleFilter("Reject")} >
                <Button type="link">Reject</Button>
            </Menu.Item>
            <Menu.Item onClick={() => handleFilter("Approved")}>
                <Button type="link">Approved</Button>
            </Menu.Item>
            <Menu.Item onClick={() => handleFilter("Cancel")}>
                <Button type="link">Cancel</Button>
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        // Check already login or not ? 
        if (isAuthenticated) {
            RequestToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <Fragment>
            <Navbar
                title="Pending Approvals"
                back={true}
                subtitle=''
                navigate="/home"
            ></Navbar>
            <div className="container white-back-cr" style={{ minHeight: 500 }}>
                <ImportantInfo></ImportantInfo>
                <div className="container mt-3 p-3">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div>
                                <Dropdown overlay={menu} placement="bottomLeft" className="mb-3" trigger={["click"]}>
                                    <Button type="secondary" icon={<FilterOutlined />}>{filterStatus}</Button>
                                </Dropdown>

                            </div>
                            {pendingList && pendingList.length > 0 ?
                                pendingList.map(function (item, index) {
                                    switch (filterStatus) {
                                        case "Waiting Approval":
                                            if (item.approval_id && !item.status && item.approval_id.status === filterStatus) {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="#FF9900" text={item.approval_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(item)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{item.approval_id.form_id.name}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                        {item.approval_id.uid.fullname}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                );
                                            }
                                            break;

                                        case "Approved":
                                            if (item.approval_id && (item.approval_id.status === 'Waiting Approval' || item.approval_id.status === 'Approved') && (item.status || item.deputy_approver)) {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="primary" text={item.approval_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(item)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{item.approval_id.form_id.name}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                        {item.approval_id.uid?.fullname || 'N/A'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                );
                                            }
                                            break;

                                        case "Reject":
                                            if (item.approval_id && !item.status && item.rejected) {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="#F5222D" text={item.approval_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(item)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{item.approval_id.form_id.name}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                        {item.approval_id.uid.fullname}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                );
                                            }
                                            break;
                                        case "Cancel":
                                            if (item.approval_id && item.approval_id.status === 'Cancel') {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="#F5222D" text={item.approval_id.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(item)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{item.approval_id.form_id.name}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                        {item.approval_id.uid.fullname}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                );
                                            }
                                            break;
                                        default:
                                            return null;
                                    }
                                }) :
                                !loading && (
                                    <Empty className="mt-5" />
                                )
                            }
                            {
                                pendingList.length > 0 &&
                                <p className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                    No more data
                                </p>
                            }
                            {
                                loading && (
                                    <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                        <Spin size="large" />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </Fragment>
    );
}

const reduxState = (state) => ({
    initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
    setInitializeTicket: (navigate, data) =>
        dispatch(InitializeTicket(navigate, data)),
});

export default connect(reduxState, reduxDispatch)(WFPendingList);
