import { Collapse, Form, AutoComplete, Input, Row, Col, Checkbox, message, Descriptions, Comment, Avatar, Button, Affix, Modal, Timeline, Alert } from "antd";
import { useNavigate, useParams } from "react-router";
import { Block, Confirm, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import HeaderFarms from "../../../utils/header_farms";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";

function ViewSR() {

  const [form] = Form.useForm();

  const { Panel } = Collapse;
  const { TextArea } = Input;

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [applications, setApplications] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [typeRequestExplain, setTypeRequestExplain] = useState(true);
  const [EquipmentDetails, SetEquipmentDetails] = useState(true);
  const [completeLoadSR, setcompleteLoadSR] = useState(false)
  const [justification, setJustification] = useState(true)

  const [otherChecked, setOtherChecked] = useState(false);
  const [otherAccountChecked, setOtherAccountChecked] = useState(false);
  
  let navigate = useNavigate();
  let { id } = useParams();

  const getDetailSR = (token) => {
    API.viewSR(HeaderFarms(token), id).then(((res) => {
      Block.remove('.ant-collapse');

      res.data.fullname = res.data.fullname + ' - ' + res.data.nik
      res.data.department = res.data.department && res.data.department
      res.data.justification && setJustification(false)
      res.data.equipment_details_explain && SetEquipmentDetails(false)
      res.data.type_request_explain && setTypeRequestExplain(false)
      res.data.system_request_other && setOtherChecked(true)
      res.data.account_request_other && setOtherAccountChecked(true)

      form.setFieldsValue(res.data);

      setcompleteLoadSR(res.data)
      styleStatus(res.data)

    })).catch((err) => {
      console.error(err)
      Report.info(
        "Service Request",
        "Sorry, Service Request not founding",
        "Okay"
      );
      Block.remove('.ant-collapse');
    })
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const styleStatus = (res) => {
    let status = res.approval_process_id.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  const getApplications = (token) => {
    API.getApplications(HeaderFarms(token), null).then(res => {

      let DTO = []

      res.forEach(i => {
        DTO.push({
          id: i.app_id,
          name: i.name
        })
      })

      setApplications(DTO)
    }).catch((err) => {
      console.log(err)
      message.warning('Terjadi Kesalahan saat load data application')
    });
  }

  function RequestToken() {
    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      getDetailSR(response.accessToken)
      getApplications(response.accessToken)
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
      Block.remove('.ant-collapse');
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then(() => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  const cancel = (values) => {
    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      setIsModalVisible(false)

      API.workflowCancel(HeaderFarms(response.accessToken), {
        approval_id: completeLoadSR.approval_process_id._id,
        msg: values.message
      }).then((_) => {

        Report.success(
          `Cancel Success`,
          `Service Request berhasil di batalkan`,
          "Okay", () => {
            return navigate('/sr/history')
          })


      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
      Block.remove('.ant-collapse');
    });
  }

  const cancelSR = (values) => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ?',
      'Yes',
      'No',
      () => {
        cancel(values)
      },
    );
  }

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Service Request"
        navigate="/sr/history"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="service_request_form"
          initialValues={{
            remember: true,
          }}
          onFinish={null}
          onFinishFailed={() => message.warning('Mohon lengkapi formulir.')}
          autoComplete="off"
          size='small'
          layout='vertical'
        >
          <div className="container p-3" style={{ alignSelf: "center" }}>

            <div className="row">
              <div className="col-md-12 mb-2">
                <Collapse defaultActiveKey={['1']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Service Request Information</b>} key="1">
                    {completeLoadSR ? (
                      <>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            {
                              completeLoadSR && (
                                <Descriptions size="small" bordered title="Information" className="mb-4">
                                  <Descriptions.Item label="Form ID">
                                    {completeLoadSR.form_record}
                                  </Descriptions.Item>

                                  {/* {
                                    completeLoadSR.approval_process_id.detail.map((i, index) => {
                                      return (<Descriptions.Item label="Approver">
                                        {i.approved_by.fullname}
                                      </Descriptions.Item>)
                                    })
                                  } */}
                                  <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                    {completeLoadSR.approval_process_id.status}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Created at">
                                    {moment(completeLoadSR.created_at).format('LLL')}
                                  </Descriptions.Item>
                                </Descriptions>
                              )
                            }
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-5'>
                            <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                            <Timeline style={{ marginTop: 30 }}>
                              {
                                completeLoadSR.approval_process_id.detail.map(item => {
                                  return (
                                    <Timeline.Item key={item._id}>{item.approved_by.fullname}
                                      <Alert
                                        message={item.status ? 'Approved' : 'Waiting Approval'}
                                        className='mt-1 p-1'
                                        type={item.status ? 'success' : 'warning'}
                                        showIcon
                                      />
                                    </Timeline.Item>
                                  )
                                })
                              }
                            </Timeline>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Panel>
                </Collapse>
              </div>
              {completeLoadSR && completeLoadSR.approval_process_id.approval_key && (
                <>
                  <div className="col-md-12 mb-2">
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Approved Barcode" key="1">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${completeLoadSR.approval_process_id.approval_key}`} />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </>

              )}
              <div className="col-md-12 mb-3">
                {
                  completeLoadSR.comments && completeLoadSR.comments.length > 0 && (
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Comments" key="1">
                        {
                          completeLoadSR.comments && completeLoadSR.comments.map(data => {
                            return (
                              <Comment key={data._id}
                                author={<p>{data.uid.fullname}</p>}
                                avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                content={
                                  <p>
                                    {data.text_plain}
                                  </p>
                                }
                              />
                            )
                          })
                        }
                      </Panel>
                    </Collapse>
                  )
                }
              </div>
              <div className='col-md-12'>
                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']} onChange={null}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>User Requiring Access</b>} key="1">
                    <Row gutter={10} className="mb-3">
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Fullname"
                          name="fullname"
                          rules={[{ required: false, message: 'Please input fullname' }]}
                        >
                          <AutoComplete
                            options={null}
                            onSelect={null}
                            filterOption={(inputValue, option) => {
                              return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            }
                          >
                            <Input.Search readOnly size="middle" placeholder="Search.." />
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Position"
                          name="position"
                          rules={[{ required: false, message: 'Please input Position' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Department"
                          name="department"
                          rules={[{ required: false, message: 'Please input Department' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Employee ID"
                          name="nik"
                          rules={[{ required: false, message: 'Please input Employee ID' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Business Unit"
                          name="company"
                          rules={[{ required: false, message: 'Please input business unit' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Phone"
                          name="phone_number"
                          rules={[{ required: false, message: 'Please input phone' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Employee Status"
                          name="employee_status"
                          rules={[{ required: false, message: 'Please input status' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Expiry Date"
                          name="expiry_date"
                          rules={[{ required: false }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row> */}
                  </Panel>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Equipment Request</b>} key="2">
                    <Row className='mt-3' gutter={20}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Type of Request"
                          name="type_request"
                          rules={[
                            {
                              required: false,
                              message: 'Please input type request',
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item
                          className='mt-4'
                          label="Explain"
                          name="type_request_explain"
                          hidden={typeRequestExplain}
                          rules={[
                            {
                              required: !typeRequestExplain,
                              message: 'Please input type explain',
                            },
                          ]}
                        >
                          <TextArea rows={4} readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Equipment"
                          name="equipment_details"
                          rules={[
                            {
                              required: false,
                              message: 'Please input type request',
                            },
                          ]}
                        >
                          <TextArea rows={4} readOnly />
                        </Form.Item>
                        <Form.Item
                          className='mt-4'
                          label="Equipment Other"
                          name="equipment_details_explain"
                          hidden={EquipmentDetails}
                          rules={[
                            {
                              required: false,
                              message: 'Please input type explain',
                            },
                          ]}
                        >
                          <TextArea rows={4} readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel style={{ background: "#fce8b6" }} header={<b>System Request</b>} key="3">
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                          style={{ pointerEvents: "none" }}
                          className='mt-4'
                          label={<b>Account</b>}
                          name="account_request"
                          rules={[
                            {
                              required: false,
                              message: 'Please input account',
                            },
                          ]}
                        >
                          <Checkbox.Group
                          >
                            <Row>
                              <Col span={12}>
                                <Checkbox value="Email">Email</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="AD / FARMS">AD / FARMS</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="Internet Access">Internet Access</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="PLC">PLC</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="Epicor Account">Epicor Account</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="CCTV">CCTV</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="VPN">VPN</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="Other">Other</Checkbox>
                                {otherAccountChecked && (
                                  <Form.Item
                                    label={<em style={{ fontSize: 10, marginTop: 10 }}>(Explained)</em>}
                                    name="account_request_other"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input account',
                                      },
                                    ]}
                                  >
                                    <TextArea
                                      rows={2}
                                      placeholder="Please input other account"
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                          className='mt-4'
                          label={<b>System</b>}
                          name="system_request"
                          rules={[
                            {
                              required: false,
                              message: 'Please input system',
                            },
                          ]}
                          style={{ pointerEvents: "none" }}
                        >
                          <Checkbox.Group>
                            <Row>
                              {
                                applications.map(item => {
                                  return (
                                    <Col span={12}>
                                      <Checkbox value={item.id}>{item.name}</Checkbox>
                                    </Col>
                                  )
                                })
                              }
                              <Col span={12}>
                                <Checkbox value={'Other'}>
                                  Other
                                </Checkbox>
                                {otherChecked && (
                                  <Form.Item
                                    label={<em style={{ fontSize: 10, marginTop: 10 }}>( Explained )</em>}
                                    name="system_request_other"
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Please input system',
                                      },
                                    ]}
                                  >
                                    <TextArea
                                      rows={2}
                                      placeholder="Please input other system"
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className='mt-3' gutter={10}>
                      <Col xs={{ span: 23 }} span={12}>
                        <Form.Item
                          label={<><span style={{ fontSize: 11 }}>Justication ( Why internet acces required and/or why additional new access point is required ?  ) </span></>}
                          name="justification"
                          hidden={justification}
                          rules={[
                            {
                              required: false,
                              message: 'Please input type explain',
                            }
                          ]}
                        >

                          <TextArea rows={4} readOnly />
                        </Form.Item>

                      </Col>
                    </Row>
                  </Panel>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Network Access and Communication Request</b>} key="4">
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Login Name"
                          name="login_name"
                          rules={[{ required: false, message: 'Please input login name' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Network Folder"
                          name="network_folder"
                          rules={[{ required: false, message: 'Please input network folder' }]}
                        >
                          <Input readOnly placeholder='Example : HSE or Legal' />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                        <Form.Item
                          label="Permissions"
                          name="permission_network_folder"
                          rules={[{ required: false, message: 'Please input network folder' }]}
                          style={{ pointerEvents: "none" }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Checkbox value="Full Access">Full Access</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="Modifiy / Write">Modifiy /Write</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="Read Only">Read Only</Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                          label="Communication Access"
                          name="communication_access"
                          rules={[{ required: false, message: 'Please input communication access' }]}
                          style={{ pointerEvents: "none" }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Checkbox value="Extension Phone">Extension Phone</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="GSM">GSM</Checkbox>
                              </Col>
                              <Col span={12}>
                                <Checkbox value="Internet Data Package">Internet Data Package</Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Description</b>} key="5">
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} span={12}>
                        <Form.Item
                          label={<><span>Description ( Brief description of the requirements & justification )</span></>}
                          name="description"
                          rules={[
                            {
                              required: false,
                              message: 'Please input Brief description of the requirements & justification',
                            },
                          ]}
                        >
                          <TextArea rows={4} readOnly className="mt-3" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </Form>
        {

          completeLoadSR && completeLoadSR.approval_process_id.status === 'Waiting Approval' && (
            <div className="row mt-4">
              <div className="col-md-12">
                <Affix offsetBottom={10}>
                  <Form.Item className="text-center">
                    <Button type="danger" onClick={showModal} className="m-3" size="large" htmlType="submit">
                      Cancel
                    </Button>
                  </Form.Item>
                </Affix>
              </div>
            </div>
          )
        }
      </div>
      <Modal
        title="Write the reason"
        footer={null}
        visible={isModalVisible}
        onOk={null}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={cancelSR}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label=""
            name="message"
            rules={[
              {
                required: true,
                message: "Please input your reason",
              },
            ]}
          >
            <Input.TextArea
              showCount
              style={{ height: "150px", width: "100%" }}
              minLength={50}
            />
          </Form.Item>
          <Form.Item
            className="mt-3"
            wrapperCol={{
              xs: {
                offset: 8, span: 18
              },
              sm: {
                offset: 7, span: 18
              },
              lg: { offset: 8, span: 18 }
            }}

          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewSR);
