import { Collapse, Form, Input, Row, Col, message, Descriptions, Comment, Avatar, Button, Affix, Modal, Timeline, Alert } from "antd";
import { useNavigate, useParams } from "react-router";
import { Block, Confirm, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import HeaderFarms from "../../../utils/header_farms";
import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";

function ViewVWPApprove() {

  const [form] = Form.useForm();

  const { Panel } = Collapse;
  const { TextArea } = Input;

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [user, setUser] = useState(null);

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [completeLoadWP, setcompleteLoadWP] = useState(false)
  const [itemsWp, setItemsWp] = useState([]);

  let navigate = useNavigate();
  let { id } = useParams();

  const getDetailPermit = async (token) => {
    try {
      const res = await API.viewValidation(HeaderFarms(token), id);

      Block.remove('.ant-collapse');

      form.setFieldsValue(res.data);
      setcompleteLoadWP(res.data);
      styleStatus(res.data);

      // only one call
      user || getUser(token)
      security(res.data)
    } catch (err) {
      console.error(err);
      Report.info("Working Permit", "Sorry, Working Permit not found", "Okay");
      Block.remove('.ant-collapse');
    }
  };

  const submitApprove = () => {

    form.validateFields().then(res => {
      if (res.errorFields) {
        return
      }

      Confirm.show(
        'Important Confirmation',
        'Are you sure? Please make sure it is correct.',
        'Yes',
        'No',
        () => {
          approved(res.notes)
        },
      );
    })
  }

  const approved = (notes) => {

    // get id approve detail process based on approver with status FALSE
    // const getDetailProcess = completeLoadWP.approval_process.detail.filter(item => item.approved_by._id === user.id
    //   && item.status === false)[0]._id
    
    let getDetailProcess;

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    getDetailProcess = completeLoadWP.approval_process.detail.find(item => {
      return (item.approved_by._id === user.id && item.status === false) || (item.approved_by.department === user.department._id);
    });

    // Set deputy based on the conditions
    if (getDetailProcess) {
      if (getDetailProcess.approved_by._id === user.id && getDetailProcess.status === false) {
        request.deputy = false; // Deputy is not needed
      } else if (getDetailProcess.approved_by.department === user.department._id) {
        request.deputy = true; // Deputy is needed
      }
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      API.addNotesValidation(HeaderFarms(response.accessToken), {
        notes: notes, approval_id: getDetailProcess.approval_id
      }).catch(err => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, failed add notes",
          "Okay"
        );
      })

      API.workflowApproved(HeaderFarms(response.accessToken), {
        approval_id_list: getDetailProcess,
        deputy: request.deputy // Use the deputy value from the request object
      }).then((res) => {

        Report.success(
          `Approved Success`,
          `berhasil di setujui, Terima Kasih`,
          "Okay",
          function () {
            return navigate('/wf/pending')
          })

      }).catch(err => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      })

    }).catch((err) => {
      console.error(err)
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    });

  }

  const onReject = (values) => {
    // get id approve detail process based on approver with status FALSE

    let getDetailProcess;

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    getDetailProcess = completeLoadWP.approval_process.detail.find(item => {
      return (item.approved_by._id === user.id && item.status === false) || (item.approved_by.department === user.department._id);
    });

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      API.workflowReject(HeaderFarms(response.accessToken), {
        approval_id_list: getDetailProcess,
        msg: values.message
      }).then((_) => {

        Report.success(
          `Reject Success`,
          `Berhasil di tolak`,
          "Okay", () => {
            return navigate('/wf/pending')
          })

      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })

    }).catch(err => {
      console.error(err)
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );

    })
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
      setUser(user)

    } catch (error) {
      console.error(error)
    }
  }

  const security = (res) => {

    if (user) {
      let found = []
      res.approval_process.detail.forEach(i => {
        if (i.approved_by._id === user.id) {
          found.push(i.approved_by._id)
        }
      })
      res.approval_process.form_id.form_setting.pic.forEach(i => {
        if (i === user.id) {
          found.push(i)
        }
      })
      if (found.length === 0) {
        navigate('/home')
      }
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const styleStatus = (res) => {
    let status = res.approval_process.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  async function getWorkingItems(token) {
    try {
      const res = await API.getWorkingItems(HeaderFarms(token), null);
      setItemsWp(res.data);
    } catch (err) {
      Block.remove('.ant-collapse');
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  }

  function RequestToken() {

    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      if (itemsWp.length === 0) {
        getWorkingItems(response.accessToken);
      } else {
        getDetailPermit(response.accessToken);
      }
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((_) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, itemsWp, user]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Validation Permit"
        navigate="/wf/pending"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="working_permit_form"
          onFinish={null}
          onFinishFailed={() => message.warning('Mohon lengkapi formulir.')}
          autoComplete="off"
          size='middle'
          layout='vertical'

        >
          <div className="container p-3" style={{ alignSelf: "center" }}>

            <div className="row">
              <div className="col-md-12 mb-2">
                <Collapse defaultActiveKey={['1']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Working Permit Validation</b>} key="1">
                    {completeLoadWP ? (
                      <>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            {
                              completeLoadWP && (
                                <Descriptions size="small" bordered title="Information" className="mb-4">
                                  <Descriptions.Item label="Form ID">
                                    {completeLoadWP.id_record}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="ID Permit">
                                    <a href={`/wp/view/${completeLoadWP.id_permit}?id=${completeLoadWP.id_record}`}>
                                      {completeLoadWP.id_permit}
                                    </a>
                                  </Descriptions.Item>
                                  <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                    {completeLoadWP.approval_process.status}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Created at">
                                    {moment(completeLoadWP.created_at).format('LLL')}
                                  </Descriptions.Item>
                                </Descriptions>
                              )
                            }
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-5'>
                            <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                            <Timeline style={{ marginTop: 30 }}>
                              {
                                completeLoadWP.approval_process.detail.map(item => {
                                  return (
                                    <Timeline.Item key={item._id}>{item.deputy_approver ? `Deputy (${item.deputy_approver})` : item.approved_by.fullname}
                                      <Alert
                                        message={item.status ? `Approved - ${moment(item.approved_at).format('YYYY-MM-DD HH:mm')}` : `Waiting Approval`}
                                        className='mt-1 p-1'
                                        type={completeLoadWP.approval_process.detail.find(a => a.status === false) ? 'warning' : 'success'}
                                        showIcon
                                      />
                                    </Timeline.Item>
                                  )
                                })
                              }
                            </Timeline>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Panel>
                </Collapse>
              </div>
              {completeLoadWP && completeLoadWP.approval_process.approval_key && (
                <>
                  <div className="col-md-12">
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Approved Barcode" key="1">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${completeLoadWP.approval_process.approval_key}`} />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </>

              )}
              <div className="col-md-12">
                {
                  completeLoadWP && completeLoadWP.comments.length > 0 && (
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header="Comments" key="1">
                        {
                          completeLoadWP && completeLoadWP.comments.map(data => {
                            return (
                              <Comment
                                key={data._id}
                                author={<p>{data.uid.fullname}</p>}
                                avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                content={
                                  <p>
                                    {data.text_plain}
                                  </p>
                                }
                              />
                            )
                          })
                        }
                      </Panel>
                    </Collapse>
                  )
                }
              </div>
              <div className="col-md-12">
                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Tambahan</b>} key="1">
                    <Row style={{ pointerEvents: "none" }} gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
                        <Form.Item
                          label="Material yang terlibat"
                          name="involved_materials"
                          rules={[
                            { required: false },
                            { max: 180, message: 'Maksimal 180 karakter' },
                          ]}
                        >
                          <TextArea rows={3} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ pointerEvents: "none" }} gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 24 }} xl={{ span: 24 }}>
                        <Form.Item
                          label="Peralatan Keselamatan"
                          name="safety_equipment"
                          rules={[
                            { required: false },
                            { max: 180, message: 'Maksimal 180 karakter' },
                          ]}
                        >
                          <TextArea rows={3} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-1 mt-3">
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Kirim Catatan"
                          name="notes"
                          rules={[
                            { required: true },
                            { max: 180, message: 'Maksimal 180 karakter' },
                          ]}
                        >
                          <TextArea rows={3} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>

          {completeLoadWP && user && (
            <>
              {completeLoadWP.approval_process.status !== 'Reject' &&
                !completeLoadWP.approval_process.detail.find(a => a.approved_by._id === user.id)?.status && (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Affix offsetBottom={10}>
                        <Form.Item className="text-center">
                          <Button type="primary" onClick={submitApprove} className="m-3" size="middle" htmlType="submit">
                            Approved
                          </Button>
                          <Button type="danger" size="middle" htmlType="submit" onClick={showModal}>
                            Reject
                          </Button>
                        </Form.Item>
                      </Affix>
                    </div>
                  </div>
                )
              }


              {completeLoadWP.approval_process.status !== 'Reject' &&
                completeLoadWP.approval_process.detail.filter(a => a.approved_by._id === user.id).length > 1 && (
                  completeLoadWP.approval_process.detail.some(a => a.status === true) && (
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <Affix offsetBottom={10}>
                          <Form.Item className="text-center">
                            <Button type="primary" onClick={submitApprove} className="m-3" size="middle" htmlType="submit">
                              Approved
                            </Button>
                            <Button type="danger" size="middle" htmlType="submit" onClick={showModal}>
                              Reject
                            </Button>
                          </Form.Item>
                        </Affix>
                      </div>
                    </div>
                  )
                )}
            </>
          )}

          <Modal
            title="Write the reason"
            footer={null}
            visible={isModalVisible}
            onOk={null}
            onCancel={handleCancel}
          >
            <Form
              name="basic"
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onReject}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label=""
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please input your Message!",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  style={{ height: "150px", width: "100%" }}
                  minLength={50}
                />
              </Form.Item>
              <Form.Item
                className="mt-3"
                wrapperCol={{
                  xs: {
                    offset: 8, span: 18
                  },
                  sm: {
                    offset: 7, span: 18
                  },
                  lg: { offset: 8, span: 18 }
                }}

              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Form>

      </div>

      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewVWPApprove);
