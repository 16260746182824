import React, { Fragment, useEffect, useState } from "react";

import { Form, Input, Button, Select, Col, Alert, message } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { useNavigate } from "react-router";
import { connect } from "react-redux";

import {
  initializeSession,
  InitializeTicket,
  InitializeTicketAttachments,
  InitializeTicketDetails,
  initializeTopics,
} from "../../../redux/action";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { loginRequest } from "../../../config/api-config";
import HeaderFarms from "../../../utils/header_farms";
import Header from "../../../utils/header_osticket";
import { callMsGraph } from "../../../utils/graph";
import Navbar from "../../../atoms/navbar";
import Footer from "../../../atoms/footer";
import { Loading, Notify } from "notiflix";
import API from "../../../services";

function CreateTicketDetail(props) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [files, setTheFiles] = useState([]);
  const [oldUser, setOldUser] = useState([]);
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  const [notesTopic, setNotesTopic] = useState(null)

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  let navigate = useNavigate();

  const onFinish = (values) => {
    props.setInitializeTicketDetails(navigate, values);
    props.setInitializeTicketAttachments(files);
  };

  const loadProfile = () => {
    form.setFieldsValue({
      topic: props.initializeTicketDetails.topic,
      message: props.initializeTicketDetails.message,
      issue_summary: props.initializeTicketDetails.issue_summary,
    });
  };

  const priorities = [
    {
      "priority_id": 1,
      "priority": "low",
      "priority_desc": "Low"
    },
    {
      "priority_id": 2,
      "priority": "normal",
      "priority_desc": "Normal"
    },
    {
      "priority_id": 3,
      "priority": "high",
      "priority_desc": "High"
    },
    {
      "priority_id": 4,
      "priority": "emergency",
      "priority_desc": "Emergency"
    }
  ];

  const loadFiles = () => {
    setTheFiles(props.initializeTicketAttachments || []);
  };

  function getFiles(e) {
    let file = e.target.files[0];

    // Save all data inputs *problem after upload value
    setFormData(form.getFieldsValue());

    getBase64(file)
      .then((result) => {
        let fileDet = {
          name: file.name,
          size: file.size,
          type: file.type,
        };

        file["base64"] = result;

        Loading.pulse();
        setTimeout(() => {
          Loading.remove();
          // Save Value Before uploading file because rerender
          setTheFiles([
            ...files,
            {
              base64URL: result,
              file: fileDet,
            },
          ]);

          e.target.value = null;

          Notify.success("Uploaded Success");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeFile(a) {
    files.splice(a, 1);
    //rerender after remove array
    setTheFiles([...files]);
  }

  function getBase64(file) {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }

  const getBusinessEntity = (mail) => {
    let unit = null;

    switch (mail && mail.split('@')[1]) {
      case 'brm.co.id':
        unit = 'BRM'
        break;
      case 'citrapalu.com':
        unit = 'CPM'
        break;
      case 'gorontalominerals.com':
        unit = 'GMI'
        break;
      default:
        unit = 'BRM'
    }

    // jika email nya CPM tetapi sudah pindah ke BRM maka, tetap ambil ke company bukan dari email
    const checkCompany = props.session ? (props.session.company ? props.session.company.code : unit) : unit;

    unit = checkCompany !== unit ? checkCompany : unit

    return unit
  }

  const getTopics = (mail) => {

    let unit = getBusinessEntity(mail) === "GMI" ? 'BRM' : getBusinessEntity(mail)

    API.getTopics(Header(), null)
      .then((res) => {
        console.log(res, 'res')
        let getBE = []
        // eslint-disable-next-line array-callback-return
        res.map(item => {
          if (unit === item.topic.split('-')[0].trim()) {
            getBE.push({
              topic_id: item.topic_id,
              topic: item.topic,
              topic_or: item.topic,
              notes: item.notes
            })
          }
        })
        console.log(getBE, 'getBE')
        props.setInitializeTopics(getBE)
      })
      .catch((err) => {
        console.log(err)
      });
  }

  function getUserDetail(token) {
    API.getUserCredential(HeaderFarms(token), { access_token: token }).then((res) => {
      // Save Session
      props.setInitializeTicket(null, {
        email: res.email,
        fullname: res.fullname,
        phonenumber: res.phone_number,
      });

      getTopics(res.email || res.userPrincipalName)

    }).catch((err) => {

      if (err.response.status === 404) {

        callMsGraph(token).then((res) => {

          props.setInitializeTicket(null, {
            email: res.mail || res.userPrincipalName,
            fullname: res.displayName,
            phonenumber: res.mobilePhone
          });

          getTopics(res.mail || res.userPrincipalName)

          navigate('/help/create-ticket')
        })
      }

      console.error(err, "GET-USER")
    })
  }

  const setSession = async (token) => {
    try {
      const res = await API.getUserCredential(HeaderFarms(token), { access_token: token });

      props.setInitializeSession(res);
      localStorage.setItem('session_signin', JSON.stringify(res));

    } catch (err) {
      console.error(err, "setSession");
      // You might want to handle the error or rethrow it depending on your use case.
      throw err;
    }
  };

  function onChangeDeputy(value) {
    const employeeDeputy = oldUser.find(
      user => user.nik === value
    )
    
    const deputy = JSON.parse(localStorage.getItem("session_signin"))

    const ticketDetail = props.initializeTicket || {}
    ticketDetail.email = employeeDeputy.email
    ticketDetail.fullname = employeeDeputy.fullname
    ticketDetail.phonenumber = employeeDeputy.phone_number

    form.setFieldsValue({ deputy_by: deputy.fullname })
    props.setInitializeTicketDetails(null, ticketDetail);
  }

  function onChangePriority(value) {

    const { priority_desc } = priorities.find(
      priority =>
        priority.priority_id === value
    )

    form.setFieldsValue({ priority_desc })
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((res) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  const getUsersAutoComplete = async (accessToken) => {
    setLoading(true)
    API.getUsersTA(HeaderFarms(accessToken), null).then(async res => {
      setOldUser(res);
      setLoading(false)
    }).catch((err) => {
      console.log(err);
      message.warning('Terjadi Kesalahan saat load data user farms');
    });
  };

  function RequestToken() {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {

      // check email, fullname and phone number is set. 
      getUsersAutoComplete(response.accessToken)
      getUserDetail(response.accessToken)
      setSession(response.accessToken)

    }).catch((e) => {
      Loading.remove();
      handleLogin(instance)
    });
  }

  useEffect(() => {

    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        getTopics(props.initializeTicket.email)
        RequestToken()
        loadProfile();
        loadFiles();
        form.setFieldsValue(formData);
      } else {
        handleLogin(instance)
      }
    }

  }, [isAuthenticated])

  return (
    <Fragment>
      <Navbar
        title="New Ticket"
        subtitle="Help Center"
        back={true}
        navigate="/help/create-ticket"
      ></Navbar>
      <div className="container white-back-cr">
        <div className="container my-3 p-4">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p className="fs-6 fw-bold">Ticket Details</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form
                form={form}
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 12,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="on"
                size="large"
              >
                <Form.Item
                  name={'belong_to'}
                  label={
                    <span>
                      Requestor
                      <span style={{ fontSize: '12px', color: '#888' }}> (Tidak Wajib)</span>
                    </span>
                  }
                  rules={[
                    { required: false },
                  ]}
                >
                  <Select
                    loading={loading}
                    showSearch
                    size="middle"
                    placeholder="Pilih Nama"
                    optionFilterProp="children"
                    onChange={onChangeDeputy}
                    filterOption={(inputValue, option) =>
                      option.children?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    {oldUser.map(option => (
                      <Select.Option key={option.nik} value={option.nik}>
                        {option.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name={'priority'}
                  label="Priority"
                  rules={[
                    { required: true },
                  ]}
                >
                  <Select
                    size="middle"
                    placeholder="Select a priority"
                    style={{ width: 200 }}
                    onChange={onChangePriority}
                  >
                    {priorities.map(priority => (
                      <Option key={priority.priority_id} value={priority.priority_id}>
                        {priority.priority_desc}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="topic"
                  label="Select a topic"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select a Topic"
                    onChange={(value) => {
                      props.getTopics.forEach(item => {
                        if (item.topic_id === value) {
                          if (item.notes) {
                            setNotesTopic(item.notes)
                          } else {
                            setNotesTopic(null)
                          }
                        }
                      })

                    }}
                    allowClear
                  >
                    {
                      props.getTopics && props.getTopics.map(data => {
                        return (
                          <Option key={data.topic_id} value={data.topic_id}>{data.topic}</Option>
                        )
                      })
                    }

                  </Select>
                </Form.Item>
                {notesTopic && (
                  <Form.Item
                    label="Notes"
                    name="notes"
                  >
                    <Alert message={notesTopic} className="topic-class" type="info" showIcon icon={<QuestionCircleOutlined />} />
                  </Form.Item>
                )}
                <Form.Item
                  label="Issue Summary"
                  name="issue_summary"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Issue Summary !",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item name={["message"]} label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your description !",
                    },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Col xs={{ span: 25 }} lg={{ span: 12, offset: 6 }}>
                  <input
                    className="form-control"
                    onChange={(e) => getFiles(e)}
                    type="file"
                    id="formFile"
                  />
                </Col>

                <Col xs={{ span: 25 }} lg={{ span: 12, offset: 6 }}>
                  <ul className="list-group mt-3">
                    {files.map(function (item, i) {
                      return (
                        <li
                          key={i}
                          className="list-group-item d-flex justify-content-between"
                        >
                          <a
                            href={item.base64URL}
                            style={{ textDecorationLine: "underline" }}
                            download={item.file.name}
                          >
                            {i + 1}. {item.file.name}
                          </a>
                          <button
                            type="button"
                            onClick={() => removeFile(i)}
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
                <Form.Item
                  name={'priority_desc'}
                  label=""
                  hidden
                  rules={[
                    { required: false },
                  ]}
                >
                  <Input hidden></Input>
                </Form.Item>
                <Form.Item
                  name={'deputy_by'}
                  label=""
                  rules={[
                    { required: false },
                  ]}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Col
                  xs={{ span: 12 }}
                  lg={{ span: 12, offset: 6 }}
                  style={{ marginTop: 20 }}
                >
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Next
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}

const reduxState = (state) => ({
  initializeTicket: state.initializeTicket,
  initializeTicketDetails: state.initializeTicketDetails,
  initializeTicketAttachments: state.initializeTicketAttachments,
  getsession: state.session,
  getTopics: state.topics
});

const reduxDispatch = (dispatch) => ({
  setInitializeTicketDetails: (navigate, data) =>
    dispatch(InitializeTicketDetails(navigate, data)),
  setInitializeTicketAttachments: (data) =>
    dispatch(InitializeTicketAttachments(data)),
  setInitializeTicket: (navigate, data) =>
    dispatch(InitializeTicket(navigate, data)),
  setInitializeTopics: (data) => dispatch(initializeTopics(data)),
  setInitializeSession: (data) =>
    dispatch(initializeSession(data)),
});

export default connect(reduxState, reduxDispatch)(CreateTicketDetail);
