import React, { useEffect, useState } from 'react';
import Navbar from '../../atoms/navbar';

function    AssetSystem() {
  const [iframeHeight, setIframeHeight] = useState(500); // Tinggi awal iframe

  useEffect(() => {
    // Fungsi untuk mengatur tinggi iframe sesuai tinggi layar saat komponen dimuat
    const setIframeHeightToScreenHeight = () => {
      const screenHeight = window.innerHeight;
      setIframeHeight(screenHeight);
    };

    // Panggil fungsi saat komponen dimuat
    setIframeHeightToScreenHeight();

    // Tambahkan event listener untuk menyesuaikan tinggi saat ukuran layar berubah
    window.addEventListener('resize', setIframeHeightToScreenHeight);

    // Bersihkan event listener saat komponen dibongkar
    return () => {
      window.removeEventListener('resize', setIframeHeightToScreenHeight);
    };
  }, []);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Asset System"
        navigate="/home"
      ></Navbar>
      <div className="container white-back-cr" style={{ minHeight: 500 }}>
        <iframe
          src="https:/asset.brmapps.com/"
          width="100%"
          height={iframeHeight + 'px'}
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default AssetSystem;
