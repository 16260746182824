import React from "react";
import "./styles.css";

import { Layout } from 'antd';

function Footer(props) {
  return (
    <Layout.Footer className="footer mt-4" style={{ textAlign: 'center', color:"#252524" }}>©{new Date().getFullYear()} Information Technology </Layout.Footer>
  );
}

export default Footer;
