import { Collapse, Form, Input, Row, Col, Checkbox, message, Descriptions, Comment, Avatar, Button, Affix, Modal, Timeline, Alert, Radio, Select, InputNumber, } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { Block, Confirm, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, EditOutlined, PrinterOutlined } from "@ant-design/icons";
import HeaderFarms from "../../../utils/header_farms";
import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";

function ViewWPApprove() {

  const [form] = Form.useForm();

  const { Panel } = Collapse;
  const { TextArea } = Input;

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [user, setUser] = useState(null);

  const [statusColor, setStatusColor] = useState({ background: null, color: null })
  const [completeLoadWP, setcompleteLoadWP] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const [itemsWp, setItemsWp] = useState([]);
  const [back, setBack] = useState(false)

  let navigate = useNavigate();
  let { id } = useParams();
  let location = useLocation();

  const getDetailPermit = async (token) => {
    try {
      const res = await API.viewWorkingPermit(HeaderFarms(token), id);

      Block.remove('.ant-collapse');

      // Assuming res.data.job_start_time and res.data.job_end_time are valid time values in a supported format
      res.data.job_start_time = moment(res.data.job_start_time).format('HH:mm');
      res.data.job_end_time = moment(res.data.job_end_time).format('HH:mm');

      const checkAndPush = (element, data, targetArray) => {
        if (
          element.checkbox === data &&
          res.data[`item_${element.item_id}`] === true &&
          !targetArray.includes(`item_${element.item_id}`)
        ) {
          targetArray.push(`item_${element.item_id}`);
        }
      };

      const required_personal_protective_equipment = [];
      const identification_job_lifting = []
      const identification_job_area = []
      const other_safety_equipment = [];
      const general_preparation = [];
      const job_preparation = [];
      const complete_work = []

      for (const element of itemsWp) {
        checkAndPush(element, 'Perlengkapan (APD) yang diperlukan', required_personal_protective_equipment);
        checkAndPush(element, 'Persiapan Umum', general_preparation);
        checkAndPush(element, 'Pekerjaan Selesai', complete_work);
        checkAndPush(element, 'Alat Keselamatan Lainnya', other_safety_equipment);
        checkAndPush(element, 'Persiapan Pelaksanaan Pekerjaan', job_preparation);
        checkAndPush(element, 'Identifikasi Pekerjaan Pengangkatan', identification_job_lifting);
        checkAndPush(element, 'Identifikasi Area Kerja', identification_job_area);
      }

      let editJobLocation = res.data.job_location?.split(',')
      let workers = res.data.workers?.split(',')

      const formValues = {
        ...res.data,
        identification_job_area,
        identification_job_lifting,
        required_personal_protective_equipment,
        other_safety_equipment,
        general_preparation,
        job_preparation,
        complete_work,
      };

      formValues.job_location = editJobLocation
      formValues.workers = workers

      formValues.execution_time = formatDateForInput(formValues.execution_time)

      form.setFieldsValue(formValues);
      setcompleteLoadWP(res.data);
      styleStatus(res.data);

      // only one call
      user || getUser(token)
      security(res.data)
    } catch (err) {
      console.error(err);
      Report.info("Working Permit", "Sorry, Working Permit not found", "Okay");
      Block.remove('.ant-collapse');
    }
  };

  const editModeHandle = async () => {
    setEditMode(true);
    if (editMode === true) {
      await new Promise((resolve) => {
        Confirm.show(
          'Informasi Penting',
          'Apakah yang anda edit sudah sesuai.',
          'Ya',
          'Cek Lagi',
          () => {
            const request = {
              ...loginRequest,
              account: accounts[0],
            };

            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance
              .acquireTokenSilent(request)
              .then(async (response) => {
                await edited(response.accessToken);
                setEditMode(false);
                resolve();

                form.setFieldsValue({ notes_input: null })

                window.location.reload();
              })
              .catch((err) => {
                console.error(err);
                Report.warning(
                  'Oops.. something wrong',
                  'Sorry, this application is experiencing problems',
                  'Okay'
                );
                resolve();
              });
          }
        );
      });
    } else {
      Report.info(
        'Mode Edit Permit',
        'Anda sudah masuk ke mode edit, mohon untuk hati hati dalam proses edit nya',
        'Okay'
      );
    }
  };

  const edited = (token) => {

    return new Promise((resolve, reject) => {
      let data = form.getFieldsValue();

      let newNotes = mergeNotes(data, completeLoadWP, user);

      if (newNotes) {
        // Save the new notes back to data
        data.notes = newNotes;
      }

      data.updated_by = user.nik;

      let bodyFormData = new FormData();


      let required_personal_protective_equipment = data.required_personal_protective_equipment
      let identification_job_lifting = data.identification_job_lifting
      let identification_job_area = data.identification_job_area
      let other_safety_equipment = data.other_safety_equipment;
      let general_preparation = data.general_preparation;
      let job_preparation = data.job_preparation;

      // Remove Items Table 
      delete data.required_personal_protective_equipment;
      delete data.identification_job_lifting
      delete data.identification_job_area
      delete data.other_safety_equipment;
      delete data.general_preparation;
      delete data.job_preparation;
      delete data.job_type;
      delete data.workers;

      // getAllObject
      for (const key in data) {

        if (data.hasOwnProperty(key)) {
          let value = data[key];

          // Periksa apakah nilai adalah `undefined`
          if (value === undefined) {
            // Jika nilai undefined dan kunci tidak dimulai dengan "ITEM_", hapus kunci tersebut dari objek
            if (!key.startsWith("item_")) {
              delete data[key];
              continue; // Langsung ke iterasi berikutnya
            } else {
              value = false;
            }
          }

          bodyFormData.append(key, value);
        }
      }

      job_preparation?.forEach((item) => {
        bodyFormData.append(`${item}`, true);
      });

      other_safety_equipment?.forEach((item) => {
        bodyFormData.append(`${item}`, true);
      });

      general_preparation?.forEach((item) => {
        bodyFormData.append(`${item}`, true);
      });

      required_personal_protective_equipment?.forEach((item) => {
        bodyFormData.append(`${item}`, true);
      });

      identification_job_lifting?.forEach((item) => {
        bodyFormData.append(`${item}`, true);
      });

      identification_job_area?.forEach((item) => {
        bodyFormData.append(`${item}`, true);
      })

      // getId Record
      bodyFormData.append('id_record', id);

      // for (const key in completeLoadWP) {
      //   if (key.startsWith("item_")) { // Pastikan kunci dimulai dengan "item_"
      //     let isFound = false;

      //     for (let pair of bodyFormData.entries()) {
      //       const formDataKey = pair[0];

      //       if (formDataKey === key) {
      //         isFound = true;
      //         break;
      //       }
      //     }

      //     if (!isFound) {
      //       // Ambil nilai dari completeLoadWP
      //       bodyFormData.append(key, false); // Tambahkan kunci dan nilainya ke FormData
      //     }
      //   }
      // }

      bodyFormData.append('user_id', completeLoadWP.user_id);

      API.WorkingWorkEdit(HeaderFarms(token), bodyFormData)
        .then(() => {
          resolve(); // Berhasil, resolve Promise
        })
        .catch((err) => {
          console.error(err);
          Report.warning(
            "Oops.. something wrong",
            "Sorry, this application is experiencing problems",
            "Okay"
          );
          reject(err); // Gagal, reject Promise
        });
    });
  }

  function formatDateForInput(originalDateString) {
    const originalDate = new Date(originalDateString);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because it's zero-based
    const day = originalDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }


  const submitApprove = () => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ? please make sure is correct.',
      'Yes',
      'No',
      () => {
        approved()
      },
    );
  }

  const submitApproveEnd = () => {
    Confirm.show(
      'Important Confirmation',
      'Are you sure ? please make sure is correct.',
      'Yes',
      'No',
      () => {
        approved('end')
      },
    );
  }

  function mergeNotes(data, completeLoadWP, user) {
    // Get the previous notes from completeLoadWP.notes
    let previousNotes = completeLoadWP.notes || '';

    // Create a new note with the requested date and time format
    let formattedTime = `[${user.fullname}] : ${data.notes_input}`;

    // Combine the previous notes with the new and old notes
    let newNotes = previousNotes + '\n' + formattedTime + '<br>';

    // Return the combined notes
    return newNotes;
  }

  const approved = () => {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    let getDetailProcess;

    getDetailProcess = completeLoadWP.approval_process_id.detail.find(item => {
      return (item.approved_by._id === user.id && item.status === false) || (item.approved_by.department === user.department._id && item.status === false);
    });

    // Set deputy based on the conditions
    if (getDetailProcess) {
      if (getDetailProcess.approved_by._id === user.id && getDetailProcess.status === false) {
        request.deputy = false; // Deputy is not needed
      } else if (getDetailProcess.approved_by.department === user.department._id) {
        request.deputy = true; // Deputy is needed
      }
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      API.workflowApproved(HeaderFarms(response.accessToken), {
        approval_id_list: getDetailProcess,
        deputy: request.deputy // Use the deputy value from the request object
      }).then((_) => {
        Report.success(
          `Approved Success`,
          `berhasil di setujui, Terima Kasih`,
          "Okay",
          function () {
            if (back) {

              return navigate('/wp/history')
            } else {
              return navigate('/wf/pending');
            }

          }
        );

      }).catch(err => {
        console.error(err);
        Report.warning(
          "Mohon maaf sudah di setujui oleh salah satu department terkait",
          "Mohon lakukan pengecekan terlebih dahulu sebelum melakukan persetujuan",
          "Okay"
        );
      });

    }).catch((err) => {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    });
  }

  const onReject = (values) => {

    // get id approve detail process based on approver with status FALSE
    const getDetailProcess = completeLoadWP.approval_process_id.detail.find(item => {
      return (item.approved_by._id === user.id && item.status === false) || (item.approved_by.department === user.department._id && item.status === false);
    })?._id;

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      API.workflowReject(HeaderFarms(response.accessToken), {
        approval_id_list: getDetailProcess,
        msg: values.message
      }).then((_) => {

        Report.success(
          `Reject Success`,
          `Berhasil di tolak`,
          "Okay", () => {
            return navigate('/wf/pending')
          })

      }).catch((err) => {
        console.error(err)
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );

      })

    }).catch(err => {
      console.error(err)
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );

    })
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
      setUser(user)

    } catch (error) {
      console.error(error)
    }
  }

  const security = (res) => {

    if (user) {
      let found = []
      res.approval_process_id.detail.forEach(i => {
        if (i.approved_by._id === user.id) {
          found.push(i.approved_by._id)
        }
      })
      res.approval_process_id.form_id.form_setting.pic.forEach(i => {
        if (i === user.id) {
          found.push(i)
        }
      })
      if (found.length === 0) {
        navigate('/home')
      }
    }
  }


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const styleStatus = (res) => {
    let status = res.approval_process_id.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF"
      })
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF"
      })
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF"
      })
    }
  }

  async function getWorkingItems(token) {
    try {
      const res = await API.getWorkingItems(HeaderFarms(token), null);

      setItemsWp(res.data);
    } catch (err) {
      Block.remove('.ant-collapse');
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  }

  function RequestToken() {

    Block.hourglass('.ant-collapse');

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      if (itemsWp.length === 0) {
        getWorkingItems(response.accessToken);
      } else {
        getDetailPermit(response.accessToken);
      }
    }).catch((e) => {
      handleLogin(instance)
      console.error(e)
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((_) => {
    })
      .catch(e => {
        console.error(e);
      });
  }

  function GeneralPreparationValueToLabel(value) {
    const valueToLabel = {
      "Hot Space": "Pekerjaan Berbahaya",
      "Limited Work": "Pekerjaan Berbahaya",
      "Work at height": "Pekerjaan Berbahaya",
      "Electric Work": "Pekerjaan Kelistrikan",
      "Excavation": "Pekerjaan Penggalian",
    };

    return valueToLabel[value] || value;
  }

  function preparationValueToLabel(value, preparationType) {
    const valueToLabel = {
      "Hot Space": preparationType === 'Job' ? "Pekerjaan Panas" : "Pekerjaan Berbahaya",
      "Limited Work": preparationType === 'Job' ? "Pekerjaan Ruang Terbatas" : "Pekerjaan Berbahaya",
      "Work at height": preparationType === 'Job' ? "Pekerjaan Ketinggian" : "Pekerjaan Berbahaya",
      "Electric Work": preparationType === 'Job' ? "Pekerjaan Kelistrikan" : "Pekerjaan Kelistrikan",
      "Excavation": preparationType === 'Job' ? "Pekerjaan Penggalian" : "Pekerjaan Penggalian",
    };

    return valueToLabel[value] || value;
  }

  useEffect(() => {
    // Check already login or not ? 
    if (!isAuthenticated) {
      RequestToken()
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken()
      } else {
        handleLogin(instance)
      }
    }

    const searchParams = new URLSearchParams(location.search);
    setBack(searchParams.get('monitoring'));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, itemsWp, user]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Working Permit"
        // navigate="/wf/pending"
        navigate={back ? '/wp/history/?monitoring=1' + back : '/wf/pending/'}
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="working_permit_form"
          onFinish={editModeHandle}
          onFinishFailed={() => message.warning('Mohon lengkapi formulir.')}
          autoComplete="off"
          size='middle'
          layout='vertical'
          scrollToFirstError

        >
          <div className="container p-3" style={{ alignSelf: "center" }}>

            <div className="row">
              <div className="col-md-9 mb-2">
                <Button type="primary" onClick={() => navigate('/wp/print/' + id)} title="Print" icon={<PrinterOutlined />}>Print</Button>
              </div>
              {
                !editMode && (
                  <>
                    <div className="col-md-12">
                      <Collapse defaultActiveKey={['1']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Working Permit Information</b>} key="1">
                          {completeLoadWP ? (
                            <>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  {
                                    completeLoadWP && (
                                      <Descriptions size="small" bordered title="Information" className="mb-4">
                                        <Descriptions.Item label="Form ID">
                                          {completeLoadWP.id_record}
                                        </Descriptions.Item>
                                        <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                          {completeLoadWP.approval_process_id.status}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Created at">
                                          {moment(completeLoadWP.created_at).format('LLL')}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    )
                                  }
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-md-5'>
                                  <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                                  <Timeline style={{ marginTop: 30 }}>
                                    {
                                      completeLoadWP.approval_process_id.detail.map(item => {
                                        return (
                                          <Timeline.Item key={item._id}>{item.deputy_approver ? `Deputy (${item.deputy_approver})` : item.approved_by.fullname}
                                            <Alert
                                              message={item.status ? `Approved - ${moment(item.approved_at).format('YYYY-MM-DD HH:mm')}` : `Waiting Approval`}
                                              className='mt-1 p-1'
                                              type={completeLoadWP.approval_process_id.detail.find(a => a.status === false) ? 'warning' : 'success'}
                                              showIcon
                                            />
                                          </Timeline.Item>
                                        )
                                      })
                                    }
                                  </Timeline>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Panel>
                      </Collapse>
                    </div>

                    {completeLoadWP && completeLoadWP.approval_process_id.approval_key && (
                      <div className="col-md-12 mt-3">
                        <Collapse defaultActiveKey={['0']}>
                          <Panel style={{ background: "#fce8b6" }} header={<b>Approved Barcode</b>} key="1">
                            <div className="row">
                              <div className="col-md-12 text-center">
                                <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${completeLoadWP.approval_process_id.approval_key}`} />
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    )}

                    <div className="col-md-12 mb-3">
                      {
                        completeLoadWP.comments && completeLoadWP.comments.length > 0 && (
                          <Collapse defaultActiveKey={['1']}>
                            <Panel header="Comments" key="1">
                              {
                                completeLoadWP.comments && completeLoadWP.comments.map(data => {
                                  return (
                                    <Comment
                                      key={data._id}
                                      author={<p>{data.uid.fullname}</p>}
                                      avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                      content={
                                        <p>
                                          {data.text_plain}
                                        </p>
                                      }
                                    />
                                  )
                                })
                              }
                            </Panel>
                          </Collapse>
                        )
                      }
                    </div>
                  </>
                )
              }
              <div className='col-md-12'>
                {
                  completeLoadWP.status === 'Closed' && (
                    <>
                      <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Pekerjaan Selesai</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                              <Form.Item
                                name={'complete_work'}
                                rules={[{ required: false, message: 'Wajib di isi' }]}
                              >
                                <Checkbox.Group>
                                  {itemsWp
                                    .filter((a) => a.checkbox === 'Pekerjaan Selesai' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                    .map((value) => (
                                      <Checkbox
                                        key={value.item_id}
                                        value={"item_" + value.item_id}
                                        style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                      >
                                        {value.value}
                                      </Checkbox>
                                    ))}
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          {["Hot Space", "Limited Work", "Work at height", "Excavation"].includes(form.getFieldValue('permit_type')) && (
                            <Row gutter={10} className="mb-1">
                              <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item
                                  label="Limbah yang dihasilkan Kg ( Jika Ada )"
                                  name="waste_produced"
                                  className="mt-3"
                                >
                                  <Input type={"text"} className="w-50" />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}

                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                              <Form.Item
                                label="Catatan"
                                name="job_completion_notes"
                                rules={[
                                  { required: false },
                                  { max: 180, message: 'Maksimal 180 karakter' },
                                ]}
                              >
                                <TextArea rows={3} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )
                }
                {
                  completeLoadWP && completeLoadWP.validation.length > 0 && (
                    <Collapse defaultActiveKey={['0']} className="mb-3">
                      <Panel
                        style={{ background: "#fce8b6" }}
                        header={<b>Validation Permit</b>}
                      >
                        <Collapse defaultActiveKey={['0']} className="p-0">
                          {
                            completeLoadWP &&
                            completeLoadWP.validation.map((validationItem, index) => (
                              <Panel
                                header={(
                                  <b>
                                    <a href={`/wf/vwp/view/${validationItem.id_record}`} target="_blank">
                                      {validationItem.id_record}
                                    </a>
                                  </b>
                                )}
                                key={validationItem.id_record}
                                className="p-0"

                              >
                                <div className="row mt-3">
                                  <div className="col-md-12">
                                    {
                                      validationItem && (
                                        <Descriptions size="small" bordered title="Information" className="mb-4">
                                          <Descriptions.Item label="Form ID">
                                            {validationItem.id_record}
                                          </Descriptions.Item>
                                          <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                            {validationItem.approval_process.status}
                                          </Descriptions.Item>
                                          <Descriptions.Item label="Created at">
                                            {moment(validationItem.created_at).format('LLL')}
                                          </Descriptions.Item>
                                        </Descriptions>
                                      )
                                    }
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                                    <Timeline style={{ marginTop: 30 }}>
                                      {validationItem.approval_process.detail.map(item => (
                                        <Timeline.Item key={item._id}>{item.deputy_approver ? `Safety Team (${item.deputy_approver})` : item.approved_by.fullname}
                                          <Alert
                                            message={item.status ? 'Approved' : 'Waiting Approval'}
                                            className='mt-1 p-1'
                                            type={item.status ? 'success' : 'warning'}
                                            showIcon
                                          />
                                        </Timeline.Item>
                                      ))}
                                    </Timeline>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-4">
                                      <label className="mb-2">Material yang terlibat:</label>
                                      <Input readOnly value={validationItem.involved_materials} />
                                    </div>
                                    <div className="mb-4">
                                      <label className="mb-2">Peralatan Keselamatan:</label>
                                      <Input readOnly value={validationItem.safety_equipment} />
                                    </div>
                                  </div>
                                </div>

                                {/* Comments for this validation */}
                                {validationItem.comments && validationItem.comments.length > 0 && (
                                  <Collapse defaultActiveKey={['1']}>
                                    <Panel header="Comments" key="1">
                                      {validationItem.comments
                                        .map(data => (
                                          <Comment
                                            key={data._id}
                                            author={<p>{data.uid.fullname}</p>}
                                            avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                            content={
                                              <p>
                                                {data.text_plain}
                                              </p>
                                            }
                                          />
                                        ))}
                                    </Panel>
                                  </Collapse>

                                )}

                                {validationItem.approval_process && validationItem.approval_process.approval_key && (
                                  <div className="col-md-12 ">
                                    <Collapse defaultActiveKey={['0']}>
                                      <Panel header={<b>Validation Approval Barcode </b>} key="0">
                                        <div className="row">
                                          <div className="col-md-12 text-center">
                                            <QRCodeSVG size={70} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${validationItem.approval_process.approval_key}`} />
                                          </div>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </div>
                                )}
                              </Panel>
                            ))}
                        </Collapse>

                      </Panel>
                    </Collapse>
                  )
                }
                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']} onChange={null}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Personal Info</b>} key="1">
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Nama Karyawan"
                          name="allowed_employee_name"
                          rules={[{ required: false, message: 'Nama Karyawan Wajib Isi' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Kepala Satuan Kerja"
                          name="direct_supervisor"
                          rules={[{ required: false, message: 'Nama Supervisor Wajib Isi' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label="Departemen"
                          name="department_name"
                          rules={[{ required: false, message: 'Departemen Wajib Isi' }]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
                {
                  !editMode && (
                    <>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Umum</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="Jenis Izin Pekerjaan Berbahaya"
                                name="permit_type"
                                rules={[{ required: false, message: 'Jenis Izin Wajib di isi' }]}
                              >
                                <Radio.Group name='permit-type'>
                                  {[
                                    "Hot Space",
                                    "Limited Work",
                                    "Work at height",
                                    "Electric Work",
                                    "Excavation",
                                    "Crane Lifting / Operation"
                                  ].map((value, index) => (
                                    <Radio key={value} value={value} style={{ marginBottom: 10 }}>{[
                                      "Panas",
                                      "Ruang Terbatas",
                                      "Ketinggian",
                                      "Kelistrikan",
                                      "Penggalian",
                                      "Pengangkatan ( Crane Operation )"
                                    ][index]}</Radio>
                                  ))}
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="Pelaksana"
                                name="executor"
                                rules={[{ required: false, message: 'Pelaksana Wajib di isi' }]}
                              >
                                <Checkbox.Group name='permit-type'>
                                  {[
                                    { "value": "PT CPM", "label": "PT CPM" },
                                    { "value": "PT BRM", "label": "PT BRM" },
                                    { "value": "PT AKM", "label": "PT AKM" },
                                    { "value": "PT SSS", "label": "PT SSS" },
                                    { "value": "PT ETI", "label": "PT ETI" },
                                    { "value": "PT AED", "label": "PT AED" },
                                    { "value": "PT PBU", "label": "PT PBU" },
                                    { "value": "PT PPU", "label": "PT PPU" },
                                    { "value": "PT TRISULA", "label": "PT TRISULA" },
                                    { "value": "PT LPM", "label": "PT LPM" },
                                    { "value": "Magang/Intership", "label": "Magang/Intership" },
                                    { "value": "Visitor/Tamu", "label": "Visitor/Tamu" },
                                    { "value": "Lainnya", "label": "Lainnya" },
                                  ].map((value, index) => (
                                    <Checkbox key={value.value} value={value.value} style={{ marginBottom: 10, marginLeft: 10 }}>{value.value}</Checkbox>
                                  ))}
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-3">
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="Pengawas"
                                name="work_supervisor"
                                rules={[{ required: true, message: 'Pengawas Wajib di isi' }]}
                              >
                                <Input
                                  size="middle"
                                  placeholder="Reza Muhammad"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {
                            form.getFieldValue('permit_type') === 'Excavation' && (
                              <Row gutter={10} className="mb-1">
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nama Operator Alat Berat"
                                    name="heavy_equipment_operator_name"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },
                                      { max: 180, message: 'Maksimal 180 karakter' },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nama Pengawas Alat"
                                    name="heavy_equipment_supervisor_name"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },
                                      { max: 180, message: 'Maksimal 180 karakter' },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nomor Registrasi Alat Berat"
                                    name="heavy_equipment_registration_number"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },

                                      { max: 180, message: 'Maksimal 180 karakter' },
                                    ]}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nomor SIM Perusahaan"
                                    name="company_driving_licenses"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },
                                    ]}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )
                          }
                          {
                            setcompleteLoadWP && setcompleteLoadWP.permit_type === 'excavation' && (
                              <Row gutter={10} className="mb-1">
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nama Operator Alat Berat"
                                    name="heavy_equipment_operator_name"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },

                                      { max: 180, message: 'Maksimal 180 karakter' },
                                    ]}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nama Pengawas Alat"
                                    name="heavy_equipment_supervisor_name"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },

                                      { max: 180, message: 'Maksimal 180 karakter' },
                                    ]}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nomor Registrasi Alat Berat"
                                    name="heavy_equipment_registration_number"
                                    rules={[
                                      { required: false, message: 'Wajib di isi' },

                                      { max: 180, message: 'Maksimal 180 karakter' },
                                    ]}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                  <Form.Item
                                    label="Nomor SIM Perusahaan"
                                    name="company_driving_licenses"
                                    rules={[
                                      { required: true, message: 'Wajib di isi' },
                                    ]}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )
                          }
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="Jenis Pekerjaan"
                                name="job_type"
                                rules={[
                                  { required: false, message: 'Jenis Pekerjaan anda wajib di isi' },
                                  { max: 180, message: 'Maksimal 180 karakter' },
                                ]}
                              >
                                <TextArea rows={3} readOnly />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="Uraian Kegiatan"
                                name="activity_description"
                                rules={[
                                  { required: false, message: 'Uraian Kegiatan anda wajib di isi' },
                                  { max: 180, message: 'Maksimal 180 karakter' },
                                ]}
                              >
                                <TextArea rows={3} readOnly />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="Lokasi Pekerjaan"
                                name="job_location"
                                rules={[{ required: false, message: 'Wajib Isi' }]}
                              >
                                <Select
                                  size='middle'
                                  placeholder="Select"
                                  allowClear={true}
                                  mode="multiple"
                                />

                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                      <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Waktu Pelaksanaan</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Waktu Pelaksanaan"
                                name="execution_time"
                                rules={[
                                  { required: true, message: 'Jam Pekerjaan Dimulai wajib diisi' },
                                ]}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>

                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Jam Pekerjaan Dimulai (Pukul)"
                                name="job_start_time"
                                rules={[
                                  { required: false, message: 'Jam Pekerjaan Dimulai wajib diisi' },
                                ]}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>

                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Jam Pekerjaan Selesai (Pukul)"
                                name="job_end_time"
                                rules={[
                                  { required: false, message: 'Jam Pekerjaan Selesai wajib diisi' },
                                ]}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )}

                {
                  form.getFieldValue('permit_type') === "Crane Lifting / Operation" && (
                    <>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Crane</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Nama Operator Crane"
                                name="crane_operator_name"
                                rules={[{ required: true, message: 'Nama Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Nama Rigger"
                                name="rigger_name"
                                rules={[{ required: true, message: 'Nama Rigger Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="No. Registrasi"
                                name="registration_number"
                                rules={[{ required: true, message: 'No. Registrasi Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Jenis Crane"
                                name="crane_type"
                                rules={[{ required: true, message: 'Jenis Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Kapasitas Crane"
                                name="crane_capacity"
                                rules={[{ required: true, message: 'Kapasitas Crane Operator Crane Wajib Isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                              <Form.Item
                                label="Commissioning terakhir"
                                name="last_commissioning_date"
                                rules={[{ required: true, message: 'Commissioning terakhir Operator Crane Wajib Isi' }]}
                              >
                                <Input type="text" readOnly />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>

                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Identifikasi Pekerjaan Pengangkatan</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Total Beban Diangkat"
                                name="total_load_lifted"
                                rules={[{ required: true, message: 'Total Beban Diangkat Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="ton" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Total Beban Rigging"
                                name="total_load_rigging"
                                rules={[{ required: true, message: 'Total Beban Rigging Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="ton" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Panjang Boom"
                                name="boom_length"
                                rules={[{ required: true, message: 'Panjang Boom Rigging Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="m" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Radius Kerja"
                                name="working_radius"
                                rules={[{ required: true, message: 'Radius Kerja Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="m" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                              <Form.Item
                                label="Perhitungan SWL*"
                                name="swl_calculation"
                                rules={[{ required: true, message: 'Perhitungan SWL* Wajib Isi' }]}
                              >
                                <InputNumber step={0.1} addonAfter="%" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10} className="mb-1">
                            <Form.Item
                              label=""
                              name="identification_job_lifting"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                <>
                                  {itemsWp
                                    .filter((a) => a.checkbox === 'Identifikasi Pekerjaan Pengangkatan')
                                    .map((value) => (
                                      <Checkbox
                                        key={value.item_id}
                                        value={"item_" + value.item_id}
                                        style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                      >
                                        {value.value}
                                      </Checkbox>
                                    ))}
                                </>
                              </Checkbox.Group>
                            </Form.Item>
                          </Row>
                        </Panel>
                      </Collapse>
                      <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Identifikasi Area Kerja</b>} key="1">
                          <Row gutter={10} className="mb-1">
                            <Form.Item
                              label=""
                              name="identification_job_area"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                <>
                                  {itemsWp.filter(a => a.checkbox === 'Identifikasi Area Kerja').map((value) => (
                                    <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}>{value.value}</Checkbox>
                                  ))}
                                </>
                              </Checkbox.Group>
                            </Form.Item>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )
                }

                <Collapse style={{ ...editMode === false ? { pointerEvents: "none" } : null }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Perlengkapan Keselamatan</b>} key="1">
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                          label="Perlengkapan ( APD ) yang diperlukan"
                          name="required_personal_protective_equipment"
                          rules={[
                            { required: false, message: 'wajib di isi' },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Pilih perlengkapan (APD)"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {itemsWp.filter(a => a.checkbox === 'Perlengkapan (APD) yang diperlukan' && a.type === 'Pekerjaan Berbahaya').map((value) => (
                              <Select.Option key={value.item_id} value={"item_" + value.item_id}>
                                {value.value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="mb-1">
                      <Col xs={{ span: 23 }} sm={{ span: 23 }} xl={{ span: 23 }}>
                        <Form.Item
                          label="Alat Keselamatan Lainnya"
                          name="other_safety_equipment"
                          rules={[{ required: false, message: 'Wajib di isi' }]}
                        >
                          <Checkbox.Group name='permit-type'>
                            {itemsWp.filter(a => a.checkbox === 'Alat Keselamatan Lainnya' && a.type === 'Pekerjaan Berbahaya').map((value) => (
                              <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, }}>{value.value}</Checkbox>
                            ))}

                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>

                <Collapse style={{ pointerEvents: "none" }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Kondisi Cuaca & Pekerja</b>} key="1">
                    <div className="mt-2">Pekerja yang Terlibat</div>
                    <Row gutter={100} className="mt-3">
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Pekerja"
                          name="workers"
                          rules={[{ required: false, message: 'Wajib Isi' }]}
                        >
                          <Select
                            size='middle'
                            placeholder="Select"
                            allowClear={true}
                            mode="multiple"
                          />

                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Kondisi Cuaca"
                          name="weather_condition"
                          rules={[{ required: true, message: 'Wajib di isi' }]}
                        >
                          <Input
                            size="middle"
                            placeholder="Cerah"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
                {
                  form.getFieldValue('permit_type') &&
                  form.getFieldValue("permit_type") !==
                  "Crane Lifting / Operation" && (
                    <Collapse className='collapse_sr' style={{ ...editMode === false ? { pointerEvents: "none" } : null }} expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                      <Panel style={{ background: "#fce8b6" }} header={<b>Persiapan Pelaksanaan Pekerjaan</b>} key="1">
                        <Row gutter={10} className="mb-1">
                          <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                              label=""
                              name="job_preparation"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                {itemsWp.filter(a => a.checkbox === 'Persiapan Pelaksanaan Pekerjaan' && a.type === preparationValueToLabel(form.getFieldValue('permit_type'), 'Job')).map((value) => (
                                  <Checkbox key={value.item_id} value={"item_" + value.item_id} style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}>{value.value}</Checkbox>
                                ))}
                              </Checkbox.Group>

                            </Form.Item>
                          </Col>
                        </Row>

                      </Panel>
                    </Collapse>
                  )
                }

                {
                  !editMode && (
                    <>
                      {
                        ['Electric Work', 'Excavation'].includes(form.getFieldValue('permit_type')) && (
                          <>
                            {form.getFieldValue('permit_type') && (
                              <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                                  <Row gutter={10} className="mb-1">
                                    {itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === preparationValueToLabel(form.getFieldValue('permit_type'), 'Job')).map(value => (

                                      <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                          key={value.item_id}
                                          label={value.value}
                                          className="label_attachment"
                                          name={"item_" + value.item_id}
                                          rules={[{ required: false, message: 'Wajib di isi' }]}
                                        >

                                          <div className="mt-2">
                                            {!completeLoadWP['item_' + value.item_id] ? (
                                              "Tidak Dilampirkan"
                                            ) : (
                                              <a
                                                href={`https://farms-working-permit.s3.ap-southeast-3.amazonaws.com/${completeLoadWP.id_record}-item_${value.item_id}.pdf`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <DownloadOutlined /> Download PDF
                                              </a>
                                            )}
                                          </div>

                                        </Form.Item>
                                      </Col>
                                    ))}
                                  </Row>
                                </Panel>
                              </Collapse>
                            )}
                          </>
                        )
                      }

                      {
                        ["Hot Space", "Limited Work", "Work at height"].includes(form.getFieldValue('permit_type')) && (
                          <>
                            {form.getFieldValue('permit_type') && (
                              <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                                  <Row gutter={10} className="mb-1">
                                    {itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pekerjaan Berbahaya').map(value => (
                                      <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                          key={value.item_id}
                                          label={value.value}
                                          className="label_attachment"
                                          name={"item_" + value.item_id}
                                          rules={[{ required: false, message: 'Wajib di isi' }]}
                                        >
                                          <div className="mt-2">
                                            {!completeLoadWP['item_' + value.item_id] ? (
                                              "Tidak Dilampirkan"
                                            ) : (
                                              <a
                                                href={`https://farms-working-permit.s3.ap-southeast-3.amazonaws.com/${completeLoadWP.id_record}-item_${value.item_id}.pdf`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <DownloadOutlined /> Download PDF
                                              </a>
                                            )}
                                          </div>
                                        </Form.Item>
                                      </Col>
                                    ))}
                                  </Row>
                                </Panel>
                              </Collapse>
                            )}
                          </>
                        )
                      }
                    </>
                  )
                }

                {
                  ["Crane Lifting / Operation"].includes(form.getFieldValue('permit_type')) && (
                    <>
                      {form.getFieldValue('permit_type') && (
                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                          <Panel style={{ background: "#fce8b6" }} header={<b>Lampiran</b>} key="1">
                            <Row gutter={10} className="mb-1">
                              {itemsWp.filter(a => a.checkbox === 'Lampiran' && a.type === 'Pengangkatan / Pengoprasian Crane').map(value => (
                                <Col key={value.item_id} xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                  <Form.Item
                                    key={value.item_id}
                                    label={value.value}
                                    className="label_attachment"
                                    name={"item_" + value.item_id}
                                    rules={[{ required: false, message: 'Wajib di isi' }]}
                                  >
                                    <div className="mt-2">
                                      {completeLoadWP['item_' + value.item_id] === false ? (
                                        "Tidak Dilampirkan"
                                      ) : (
                                        <a
                                          href={`https://farms-working-permit.s3.ap-southeast-3.amazonaws.com/${completeLoadWP.id_record}-item_${value.item_id}.pdf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <DownloadOutlined /> Download PDF
                                        </a>
                                      )}
                                    </div>
                                  </Form.Item>
                                </Col>
                              ))}
                            </Row>
                          </Panel>
                        </Collapse>
                      )}
                    </>
                  )
                }

                {
                  form.getFieldValue('permit_type') &&
                  form.getFieldValue("permit_type") !==
                  "Crane Lifting / Operation" && (
                    <Collapse style={{ ...editMode === false ? { pointerEvents: "none" } : null }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                      <Panel style={{ background: "#fce8b6" }} header={<b>Persiapan Umum</b>} key="1">
                        <Row gutter={10} className="mb-1">
                          <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                              label=""
                              name="general_preparation"
                              rules={[{ required: false, message: 'Wajib di isi' }]}
                            >
                              <Checkbox.Group name='permit-type'>
                                {form.getFieldValue('permit_type') && (
                                  <>
                                    {itemsWp
                                      .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                      .map((value) => (
                                        <Checkbox
                                          key={value.item_id}
                                          value={"item_" + value.item_id}
                                          style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                        >
                                          {value.value}
                                        </Checkbox>
                                      ))}

                                    {itemsWp
                                      .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                      .length === 0 && (
                                        <>
                                          {itemsWp
                                            .filter((a) => a.checkbox === 'Persiapan Umum' && a.type === GeneralPreparationValueToLabel(form.getFieldValue('permit_type')))
                                            .map((value) => (
                                              <Checkbox
                                                key={value.item_id}
                                                value={"item_" + value.item_id}
                                                style={{ marginBottom: 10, marginLeft: 10, width: "100%" }}
                                              >
                                                {value.value}
                                              </Checkbox>
                                            ))}
                                        </>
                                      )}
                                  </>
                                )}

                              </Checkbox.Group>
                            </Form.Item>
                          </Col>
                        </Row>

                      </Panel>
                    </Collapse>

                  )
                }

                {
                  ["Limited Work"].includes(form.getFieldValue('permit_type')) && (
                    <>
                      <Collapse style={{ ...editMode === false ? { pointerEvents: "none" } : null }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                        <Panel style={{ background: "#fce8b6" }} header={<b>Pengukuran Gas</b>} key="1">
                          <Row gutter={10}>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="O2 ( 19,5% - 23,5% )"
                                name="o2"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="H2S ( < 10 ppm ) "
                                name="h2s"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="CO ( < 30 ppm )"
                                name="co"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                              <Form.Item
                                label="HCN ( < 4,7 ppm )"
                                name="hcn"
                                rules={[{ required: false, message: 'Wajib Di isi' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  )
                }

                <Collapse style={{ ...editMode === false ? { pointerEvents: "none" } : null }} className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                  <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Tambahan</b>} key="1">
                    <Row gutter={10}>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="No. Tim Tanggap Darurat"
                          name="emergency_response_team_number"
                          rules={[{ required: false, message: 'Wajib di isi' }]}
                        >
                          <TextArea rows={"3"} defaultValue={"(Yoom) 62812-4036-7711 dan (Basri) 62812-4511-1651"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="No. Safety Officer"
                          name="safety_officer_number"
                          rules={[{ required: false, message: 'Wajib di isi' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10} className="p-2">
                      <div style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: completeLoadWP.notes }}></div>
                    </Row>
                    <Row gutter={10} className="mb-1 mt-3">
                      <Input name="notes" type={"hidden"} />

                      {
                        editMode && (
                          <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                              label="Kirim Catatan"
                              name="notes_input"
                              rules={[
                                { required: true },
                                { max: 180, message: 'Maksimal 180 karakter' },
                              ]}
                            >
                              <TextArea rows={3} />
                            </Form.Item>
                          </Col>
                        )
                      }
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>

          {completeLoadWP && user && (
            <>
              {
                completeLoadWP.approval_process_id.status !== 'Reject' && completeLoadWP.approval_process_id.status !== 'Cancel' &&
                completeLoadWP.approval_process_id.detail.filter(a => a.approved_by._id === user.id || a.approved_by.department === user.department._id).length > 0 &&
                completeLoadWP.approval_process_id.detail.some(a => a.status === false) && completeLoadWP.status !== "Closed" &&
                (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Affix offsetBottom={10}>
                        <Form.Item className="text-center">
                          {
                            !editMode ? (
                              <>
                                <Button type="primary" onClick={submitApprove} className="m-2" size="middle">
                                  <CheckCircleOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Approved
                                </Button>
                                <Button type="danger" size="middle" className="m-2" onClick={showModal}>
                                  <CloseCircleOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Rejected
                                </Button>
                                <Button type="primary" color="#1890FF" size="middle" className="m-2" htmlType="submit">
                                  <EditOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Edit Permit
                                </Button>
                              </>

                            ) : (
                              <>
                                <Button type="primary" color="#1890FF" size="middle" htmlType="submit" className="m-2">
                                  <CheckCircleOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Save Edit
                                </Button>
                              </>

                            )
                          }
                        </Form.Item>
                      </Affix>
                    </div>
                  </div>
                )
              }
            </>
          )}
        </Form>

        {completeLoadWP && user && (
          <>
            {
              completeLoadWP.approval_process_id.status !== 'Reject' && completeLoadWP.status === "Closed" &&
              completeLoadWP.approval_process_id.detail.filter(a => a.approved_by._id === user.id || a.approved_by.department === user.department._id).length > 1 && (
                completeLoadWP.approval_process_id.detail.some(a => a.status === true) && (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Affix offsetBottom={10}>
                        <Form.Item className="text-center">
                          <Button type="primary" onClick={submitApproveEnd} className="m-2" size="middle">
                            <CheckCircleOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Approved
                          </Button>
                          {/* <Button type="danger" size="middle" className="m-2" onClick={showModal}>
                            <CloseCircleOutlined style={{ position: 'relative', top: '-2px', left: '-1px' }} /> Rejected
                          </Button> */}
                        </Form.Item>
                      </Affix>
                    </div>
                  </div>
                )
              )
            }
          </>
        )}

        <Modal
          title="Write the reason"
          footer={null}
          visible={isModalVisible}
          onOk={null}
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onReject}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label=""
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input your Message!",
                },
              ]}
            >
              <Input.TextArea
                showCount
                style={{ height: "150px", width: "100%" }}
                minLength={50}
              />
            </Form.Item>
            <Form.Item
              className="mt-3"
              wrapperCol={{
                xs: {
                  offset: 8, span: 18
                },
                sm: {
                  offset: 7, span: 18
                },
                lg: { offset: 8, span: 18 }
              }}

            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>

      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(ViewWPApprove);
