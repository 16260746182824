import React, { Fragment, useEffect } from "react";

import "antd/dist/antd.css";
import "./styles.css";

import { connect } from "react-redux";
import { useNavigate } from "react-router";

import { InitializeTicket, InitializeTicketDetails } from "../../../redux/action";
import Navbar from "../../../atoms/navbar";
import { Button, Col } from "antd";

import { OsTicket, whatsappServices } from "../../../config/api-config";
import { useIsAuthenticated } from "@azure/msal-react";
import { Confirm, Loading, Report } from "notiflix";

import Header from "../../../utils/header_osticket";
import Footer from "../../../atoms/footer";
import API from "../../../services";

function CreateTicketCheckin(props) {
  let navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  function getTitleTopics(topic) {
    let getTopic;
    props.getTopics.map(item => {
      if (topic === item.topic_id)
        getTopic = item.topic_or
    })
    return getTopic
  }

  function changeNumberTo62(number) {
    if (number?.charAt(0) === '0') {
      return '62' + number.slice(1);
    }
    return number;
  }

  function ServicesCreateTicket() {
    let Attachments = [];

    props.initializeTicketAttachments.forEach((element) => {
      Attachments.push({
        [element.file.name]: element.base64URL,
      });
    });

    let body = {
      alert: true,
      autorespond: true,
      source: "API",
      name: props.initializeTicket.fullname,
      email: props.initializeTicket.email,
      phone: changeNumberTo62(props.initializeTicket.phonenumber),
      subject: props.initializeTicketDetails.issue_summary,
      priority: props.initializeTicketDetails.priority,
      deputy_requestor: getDeputyRequestor(),
      message: `data:text/html, ${props.initializeTicketDetails.message}`,
      attachments: Attachments,
      topicId: parseInt(props.initializeTicketDetails.topic),
    };

    API.CreateTicket(Header(), body)
      .then((res) => {

        sendNotificationToGroup(res);
        sendNotificationToCreator(res);

        Report.success(
          `Create Ticket Success`,
          `Ticket anda berhasil dibuat dengan nomor ${res}, Terima kasih`,
          "Okay",
          function () {
            props.setInitializeTicketDetails(navigate, null);
            navigate("/help/history-ticket");
          }
        );

        Loading.remove();
      })
      .catch((err) => {
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
        Loading.remove();
      });

    function getDeputyRequestor() {
      if (props.initializeTicketDetails.deputy_by) {
        return JSON.parse(localStorage.getItem("session_signin")).email;
      }
    }

    function sendNotificationToCreator(res) {
      let messageTemplate = "Request anda telah dibuat dengan nomor " + res + " kami akan menindak lanjuti request Anda\n\n";
      messageTemplate += "Name : " + props.initializeTicket.fullname + '\n';
      messageTemplate += "Email : " + props.initializeTicket.email + '\n';
      messageTemplate += "Phone : " + changeNumberTo62(props.initializeTicket.phonenumber) + '\n';
      messageTemplate += "Topic : " + getTitleTopics(props.initializeTicketDetails.topic) + '\n';
      messageTemplate += "Priority : " + props.initializeTicketDetails.priority_desc + '\n';
      if (props.initializeTicketDetails.deputy_by) {
        messageTemplate += "Created By : " + props.initializeTicketDetails.deputy_by + '\n';
      }
      messageTemplate += "Subject : " + props.initializeTicketDetails.issue_summary + '\n \n';
      messageTemplate += "Message : " + props.initializeTicketDetails.message + '\n \n';
      messageTemplate += "\nTerima Kasih"
      API.sendPersonalWa({
        number: changeNumberTo62(props.initializeTicket.phonenumber),
        message: messageTemplate
      });
    }

    function sendNotificationToGroup(res) {
      let messageTemplate = "Information New Ticket " + res + " Created \n \n";
      messageTemplate += "Name : " + props.initializeTicket.fullname + '\n';
      messageTemplate += "Email : " + props.initializeTicket.email + '\n';
      messageTemplate += "Phone : " + changeNumberTo62(props.initializeTicket.phonenumber) + '\n';
      messageTemplate += "Topic : " + getTitleTopics(props.initializeTicketDetails.topic) + '\n';
      messageTemplate += "Priority : " + props.initializeTicketDetails.priority_desc + '\n';
      if (props.initializeTicketDetails.deputy_by) {
        messageTemplate += "Created By : " + props.initializeTicketDetails.deputy_by + '\n';
      }
      messageTemplate += "Subject : " + props.initializeTicketDetails.issue_summary + '\n \n';
      messageTemplate += "Message : " + props.initializeTicketDetails.message + '\n \n';

      messageTemplate += OsTicket + "/scp/tickets.php?a=search&search-type=typeahead&number=" + res + '\n \n';
      messageTemplate += "Thanks for handle";

      API.sendGroupWa({
        id: whatsappServices.it_service_group,
        message: messageTemplate
      });
    }
  }

  function submit() {
    Confirm.show(
      "Create Ticket",
      "Are you sure want create ticket ?",
      "Yes",
      "No",
      () => {
        Loading.hourglass("Just a Moment");
        ServicesCreateTicket();
      },
      {}
    );
  }

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/')
    }
  })

  return (
    <Fragment>
      <Navbar
        title="New Ticket"
        subtitle="Help Center"
        back={true}
        navigate="/help/create-ticket-detail"
      ></Navbar>
      <div className="container white-back-cr">
        <div className="container my-3 p-3" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p className="fs-6 fw-bold">Check again your ticket</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="background-checkin p-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="fs-7 text-muted mb-2">Email</div>
                    <p className="fs-7">
                      {props.initializeTicket.email || (
                        <span className="text-danger">
                          This field cannot be empty
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 w-50">
                    <div className="fs-7 text-muted mb-2">Phone Number</div>
                    <p className="fs-7 ">
                      {props.initializeTicket.phonenumber || "-"}
                    </p>
                  </div>
                  <div className="col-md-6 col-md-6 w-50">
                    <div className="fs-7 text-muted mb-2">Full Name</div>
                    <p className="fs-7">
                      {props.initializeTicket.fullname || (
                        <span className="text-danger">
                          This field cannot be empty
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 w-50">
                    <div className="fs-7 text-muted mb-2">Help Topic</div>
                    <p className="fs-7 ">
                      {getTitleTopics(props.initializeTicketDetails.topic) || (
                        <span className="text-danger">
                          This field cannot be empty
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-6 w-50">
                    <div className="fs-7 text-muted mb-2">Priority</div>
                    <p className="fs-7 ">
                      {props.initializeTicketDetails.priority_desc || (
                        <span className="text-danger">
                          This field cannot be empty
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-6 w-50">
                    <div className="fs-7 text-muted mb-2">Issue Summary</div>
                    <p className="fs-7 ">
                      {props.initializeTicketDetails.issue_summary || (
                        <span className="text-danger">
                          This field cannot be empty
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-6 w-50">
                    <div className="fs-7 text-muted mb-2">Deputy By</div>
                    <p className="fs-7 ">
                      {props.initializeTicketDetails.deputy_by || (
                        <span className="text-danger">
                          This field cannot be empty
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="fs-7 text-muted mb-2">Message</div>
                    <p className="fs-7 ">
                      {props.initializeTicketDetails.message || "-"}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="fs-7 text-muted mb-2">File</div>
                    <Col xs={{ span: 25 }} lg={{ span: 12, offset: 0 }}>
                      <ol>
                        {props.initializeTicketAttachments.length > 0 ? (
                          props.initializeTicketAttachments.map(function (
                            item,
                            i
                          ) {
                            return (
                              <li key={i} className="mt-2">
                                <a
                                  href={item.base64URL}
                                  style={{ textDecorationLine: "underline" }}
                                  download={item.file.name}
                                >
                                  {item.file.name}
                                </a>
                              </li>
                            );
                          })
                        ) : (
                          <span className="text-danger">No Attachment</span>
                        )}
                      </ol>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12 text-center">
              <Button type="primary" onClick={() => submit()} size="large">
                Submit Ticket
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}

const reduxState = (state) => ({
  initializeTicket: state.initializeTicket,
  initializeTicketDetails: state.initializeTicketDetails,
  initializeTicketAttachments: state.initializeTicketAttachments,
  getTopics: state.topics,
  session: state.session
});

const reduxDispatch = (dispatch) => ({
  setInitializeTicket: (navigate, data) =>
    dispatch(InitializeTicket(navigate, data)),
  setInitializeTicketDetails: (data) =>
    dispatch(InitializeTicketDetails(null, data)),
});

export default connect(reduxState, reduxDispatch)(CreateTicketCheckin);
