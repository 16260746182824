import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";

import { Layout, Alert, Collapse, Form, AutoComplete, Input, Row, Col, Select, Button, message, Upload, Modal, Radio } from "antd";
import { PlusOutlined } from '@ant-design/icons';

import Navbar from '../../atoms/navbar';

import API from '../../services';
import HeaderFarms from '../../utils/header_farms';
import { loginRequest } from '../../config/api-config';

import { useMsal } from '@azure/msal-react';
import { Confirm, Loading, Report } from 'notiflix';

// Load Data static
import Departement from './data/depatment.json';
import radioDataLevel from './data/level.json';
import Company from './data/company.json';

function CreateHias() {

    const [form] = Form.useForm();

    let navigate = useNavigate();
    const { instance, accounts } = useMsal();

    const { Panel } = Collapse;
    const { TextArea } = Input;
    const { Option } = Select;

    const [informationCategory, setInformationCategory] = useState(null);
    const [categorySuggestion, setCategorySuggestion] = useState(null);

    const [directCause, setDirectCause] = useState(false);
    const [directCauseCategory, setDirectCauseCategory] = useState(null);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewTitle, setPreviewTitle] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [Location, setLocation] = useState([]);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const InformationNoted = () => {
        return (
            <>
                <div className='note-sr'>
                    <div>KTT/CFO/GM = 1 HIAS/Bulan</div>
                    <div>Crew/Operator (Non Staff) = 2 HIAS/Bulan</div>
                    <div>Supervisor/Foreman/Officer = 4 HIAS/Bulan</div>
                    <div>Supervisor/Foreman/Officer = 2 HIAS/Bulan</div>
                </div>
            </>
        )
    };

    const getResposibleArea = (token) => {
        API.ResponsibleArea(HeaderFarms(token), null).then(res => {
            // Menggunakan objek Set untuk membuat lokasi menjadi unik
            const uniqueLocations = [...new Set(res.data.map(area => area.location))];
            // Menggunakan setLocations untuk menetapkan nilai lokasi
            setLocation(uniqueLocations);
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }


    const handleInformationCategory = (a) => {

        setInformationCategory(a.target.value)

        if (a.target.value === 'Saran') {
            setDirectCause(false)
        }

        let categories = ["kesehatan", "keselamatan", "lingkungan"]
        if (a.target.value === 'Ketidaksesuaian' && categories.includes(categorySuggestion)) {
            setDirectCause(true)
        }
    }

    const handleCategorySuggestion = (a) => {

        setCategorySuggestion(a.target.value)

        let categories = ["kesehatan", "keselamatan", "lingkungan"]

        if (informationCategory === 'Ketidaksesuaian' && categories.includes(a.target.value)) {
            setDirectCause(true)
        } else {
            setDirectCause(false)
        }
    }

    const handleDirectCause = (a) => {
        setDirectCauseCategory(a.target.value)
        console.log(directCauseCategory)
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const onFinish = (a) => {

        Confirm.show(
            'Important Confirmation',
            'Are you sure ?',
            'Yes',
            'No',
            () => {
                Loading.hourglass("Mohon tunggu sedang di proses.. ");

                let bodyFormData = new FormData();

                delete a.attachments

                for (const key in a) {
                    if (a.hasOwnProperty(key)) {
                        bodyFormData.append(key, a[key]);
                    }
                }

                if (fileList.length > 0) {
                    fileList.forEach((file) => {
                        bodyFormData.append('attachments', file.originFileObj);
                    });
                }

                API.CreateHias(HeaderFarms(null), bodyFormData).then(_ => {
                    Report.success(
                        `Your response has been recorded.`,
                        "Thankyou",
                        "Okay",
                        function () {
                            navigate('/home')
                        }
                    );

                    localStorage.setItem('formDataHias', JSON.stringify(a));

                    Loading.remove();
                }).catch(err => {
                    Loading.remove();
                    console.error(err)
                    Report.warning(
                        "Oops.. something wrong",
                        "Sorry, this application is experiencing problems",
                        "Okay"
                    );
                })

            })

    }

    const handlePhoneNumberChange = (e) => {
        let value = e.target.value;

        if (value === '0') {
            value = '62';
        }

        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');

        form.setFieldsValue({
            number_phone: value,
        });
    };

    const GetUser = (token) => {
        API.getUserCredential(HeaderFarms(token), { access_token: token }).then((res) => {
            form.setFieldsValue({
                reporter_name: res.fullname,
                number_phone: res.phone_number,
                employee_id: res.nik,
                department_division: res.department.fullname,
                current_company: 'PT ' + res.company.code,
                position: res.level.fullname
            })
        }).catch((err) => {
            console.error(err, "Verification")
        })
    }

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {
                GetUser(responseToken.accessToken)
                getResposibleArea(responseToken.accessToken)
            })
            .catch((e) => {
            });
    }


    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    useEffect(() => {

        if (accounts.length > 0) {
            RequestToken()
        } else {
            getResposibleArea(null)
            const cachedFormData = localStorage.getItem('formDataHias');

            if (cachedFormData) {
                let getJson = JSON.parse(cachedFormData)
                form.setFieldsValue({
                    reporter_name: getJson.reporter_name,
                    number_phone: getJson.number_phone,
                    employee_id: getJson.employee_id,
                    department_division: getJson.department_division,
                    position: getJson.position,
                    current_company: getJson.current_company
                })
            }
        }

    }, [])

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="HIAS"
                navigate="/home"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="service_request_form"
                    initialValues={{
                        remember: true,
                    }}
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='large'
                    layout='vertical'
                >
                    <div className="container p-3" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <Alert
                                    message={<b style={{ fontSize: 15 }}>HAZARD INFORMATION AND SUGGESTION</b>}
                                    description="Informasi Bahaya dan Rekomendasi Perbaikan"
                                    type="warning"
                                    className='mb-2'
                                />
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Lokasi dan Foto</b>} key="1">
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Attachment"
                                                    name="attachments"
                                                    rules={[{ required: false, message: 'Attachment wajib di isi' }]}
                                                >
                                                    <Upload
                                                        listType="picture-card"
                                                        accept="image/*"
                                                        name="attachments"
                                                        fileList={fileList}
                                                        onPreview={handlePreview}
                                                        onChange={handleChange}
                                                        beforeUpload={() => {
                                                            return false;
                                                        }}
                                                        maxCount={5}
                                                    >
                                                        {fileList.length >= 8 ? null : uploadButton}
                                                    </Upload>
                                                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                                        <img
                                                            alt="example"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            src={previewImage}
                                                        />
                                                    </Modal>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Lokasi"
                                                    name="location"
                                                    rules={[{ required: true, message: 'Lokasi wajib di isi' }]}
                                                >
                                                    <AutoComplete
                                                        size="middle"
                                                        placeholder="Select"
                                                        allowClear
                                                        options={Location.map((item) => ({ value: item }))}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Identitas anda</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Nama Pelapor"
                                                    name="reporter_name"
                                                    rules={[{ required: true, message: 'Nama Pelapor wajib di isi' }]}
                                                >
                                                    <Input placeholder="Mohamad Fazrin" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>

                                                <Form.Item
                                                    label="Nomor Handphone / WA"
                                                    name="number_phone"
                                                    rules={[{ required: true, message: 'Nomor HP Wajib di isi' }]}
                                                >
                                                    <Input
                                                        placeholder="example: 628597721233"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={handlePhoneNumberChange}
                                                    />

                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>

                                                <Form.Item
                                                    label="Nomor Induk Karyawan / NIK KTP"
                                                    name="employee_id"
                                                    rules={[{ required: true, message: 'NIK Wajib di isi' }]}
                                                >
                                                    <Input />

                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Jabatan"
                                                    name="position"
                                                    rules={[{ required: true, message: 'Jabatan wajib di isi' }]}
                                                >
                                                    <Select>
                                                        {radioDataLevel.map((item) => (
                                                            <Select.Option value={item.value} key={item.value}>
                                                                {item.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Departement / Divisi"
                                                    name="department_division"
                                                    rules={[{ required: true, message: 'Department wajib di isi' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Pilih Departemen / Divisi"
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {Departement.map((departement) => (
                                                            <Option key={departement.value} value={departement.value}>
                                                                {departement.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Perusahaan Anda bekerja saat ini?"
                                                    name="current_company"
                                                    rules={[{ required: false, message: 'Perusahaan anda wajib di isi' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="PT RAK"
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {Company.map((company) => (
                                                            <Option key={company.value} value={company.value}>
                                                                {company.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>

                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Kategori</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Kategori Informasi"
                                                    name="information_category"
                                                    rules={[{ required: true, message: 'Kategori wajib di isi' }]}
                                                    onChange={(event) =>
                                                        handleInformationCategory(event)
                                                    }
                                                >
                                                    <Radio.Group name='information-category' >
                                                        <Radio value="Ketidaksesuaian">Ketidaksesuaian</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Kategori"
                                                    name="category_suggestions"
                                                    rules={[{ required: true, message: 'Kategori wajib di isi' }]}
                                                >
                                                    <Radio.Group name='category-suggestions' onChange={(event) =>
                                                        handleCategorySuggestion(event)
                                                    }>
                                                        {[
                                                            "kesehatan",
                                                            "keselamatan",
                                                            "lingkungan",
                                                            "eksternal",
                                                            "kepatuhan",
                                                        ].map((subcategory, index) => (
                                                            <Radio key={index} value={subcategory} style={{ marginBottom: 10 }}>{subcategory}</Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Penyebab Langsung, Hasil Pengamatan, Tindakan Perbaikan Langsung dan Rekomendasi</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Penyebab Langsung"
                                                    name="direct_cause"
                                                    rules={[{ required: true, message: 'Penyebab Langsung wajib di isi' }]}
                                                >
                                                    <Radio.Group onChange={(event) => handleDirectCause(event)}>
                                                        <Radio style={{ marginBottom: 10 }} value="Hampir Celaka">Hampir Celaka</Radio>
                                                        <Radio style={{ marginBottom: 10 }} value="Kondisi Tidak Aman">Kondisi Tidak Aman</Radio>
                                                        <Radio style={{ marginBottom: 10 }} value="Tindakan Tidak Aman">Tindakan Tidak Aman</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                {directCauseCategory === "Tindakan Tidak Aman" ? (
                                                    <Form.Item
                                                        label="Kategori"
                                                        name="direct_cause_category"
                                                        rules={[{ required: true, message: 'wajib di isi' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="Pilih Kategori"
                                                            filterOption={(inputValue, option) =>
                                                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="Mengoperasikan alat tanpa wewenang">
                                                                Mengoperasikan alat tanpa wewenang
                                                            </Option>
                                                            <Option value="Penanganan material tidak tepat">
                                                                Penanganan material tidak tepat
                                                            </Option>
                                                            <Option value="cara penggunaan peralatan tidak aman">
                                                                cara penggunaan peralatan tidak aman
                                                            </Option>
                                                            <Option value="Tidak memperhatikan lingkungan kerja">
                                                                Tidak memperhatikan lingkungan kerja
                                                            </Option>
                                                            <Option value="Tidak menggunakan APD">
                                                                Tidak menggunakan APD
                                                            </Option>
                                                            <Option value="Merusak peralatan">
                                                                Merusak peralatan
                                                            </Option>
                                                            <Option value="Memaksakan kapasitas kerja pribadi">
                                                                Memaksakan kapasitas kerja pribadi
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                ) : directCauseCategory === "Kondisi Tidak Aman" ? (
                                                    <Form.Item
                                                        label="Kategori"
                                                        name="direct_cause_category"
                                                        rules={[{ required: true, message: 'wajib di isi' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="Pilih Kategori"
                                                            filterOption={(inputValue, option) =>
                                                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="Mesin / alat kerja tidak standar">
                                                                Mesin / alat kerja tidak standar
                                                            </Option>
                                                            <Option value="kelistrikan / berenergi kondisi">
                                                                kelistrikan / berenergi kondisi
                                                            </Option>
                                                            <Option value="lingkungan kerja berbahaya">
                                                                lingkungan kerja berbahaya
                                                            </Option>
                                                            <Option value="Rambu dan tanda peringatan tidak memadai">
                                                                Ramu dan tanda peringatan tidak memadai
                                                            </Option>
                                                            <Option value="Housekeeping buruk">
                                                                Housekeeping buruk
                                                            </Option>
                                                            <Option value="APD tidak memadai">
                                                                APD tidak memadai
                                                            </Option>
                                                            <Option value="peringatan dan pengamatan tidak memadai">
                                                                peringatan dan pengamatan tidak memadai
                                                            </Option>
                                                            <Option value="tata letak tempat kerja tidak mamadai">
                                                                tata letak tempat kerja tidak mamadai
                                                            </Option>
                                                            <Option value="peralatan keselamatan dan kedaruratan tidak memadai">
                                                                peralatan keselamatan dan kedaruratan tidak memadai
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                ) : directCauseCategory === "Hampir Celaka" ? (
                                                    <Form.Item
                                                        label="Kategori"
                                                        name="direct_cause_category"
                                                        rules={[{ required: true, message: 'wajib di isi' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="Pilih Kategori"
                                                            filterOption={(inputValue, option) =>
                                                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="Hampir Terjatuh/Kejatuhan">
                                                                Hampir Terjatuh/Kejatuhan
                                                            </Option>
                                                            <Option value="Hampir Tertusuk">
                                                                Hampir Tertusuk
                                                            </Option>
                                                            <Option value="Hampir Terpotong">
                                                                Hampir Terpotong
                                                            </Option>
                                                            <Option value="Ramu dan tanda peringatan tidak memadai">
                                                                Ramu dan tanda peringatan tidak memadai
                                                            </Option>
                                                            <Option value="Hampir Tersetrum">
                                                                Hampir Tersetrum
                                                            </Option>
                                                            <Option value="Hampir Tertabrak/Menabrak">
                                                                Hampir Tertabrak/Menabrak
                                                            </Option>
                                                            <Option value="Hampir Terpukul/Terbentur">
                                                                Hampir Terpukul/Terbentur
                                                            </Option>
                                                            <Option value="Hampir Tergores">
                                                                Hampir Tergores
                                                            </Option>
                                                            <Option value="Hampir Terkontaminasi Bahan Berbahaya">
                                                                Hampir Terkontaminasi Bahan Berbahaya
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Hasil Pengamatan, Tindakan Perbaikan dan Rekomendasi</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Hasil Pengamatan"
                                                    name="observation_results"
                                                    rules={[
                                                        { required: true, message: 'Hasil Pengamatan anda wajib di isi' },
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={2} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Tindakan Perbaikan Langsung"
                                                    name="immediate_corrective_actions"
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={2} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Rekomendasi / Masukan Perbaikan"
                                                    name="recommendations_improvement_inputs"
                                                    rules={[
                                                        { required: true, message: 'Rekomendasi / Masukan Perbaikan anda wajib di isi' },
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={2} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <Alert
                                    message="Target HIAS Level Jabatan"
                                    description={<InformationNoted />}
                                    type="info"
                                    className='note-sr'
                                />
                            </div>
                        </div>
                    </div>
                    <Row className='justify-content-center'>
                        <Col>
                            <Form.Item>
                                <Button type="primary" size="middle" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2023 Information Technology</Layout.Footer>
        </>
    );
}
export default CreateHias