import React from "react";

import { Collapse } from 'antd';

const { Panel } = Collapse;

const ImportantInfo = (props) => {
    return (
        <>
            <div className="container-fluid p-0">
                <Collapse defaultActiveKey={["0"]} className="mt-3" bordered={false}>
                    <Panel header="Important Updates" key="1">
                        {/* <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="fs-7 text-muted mb-1">Name</div>
                                    <p className="fs-7 ">{graph ? graph.displayName : "-"}</p>
                                </div>
                            </div>

                        </div> */}
                    </Panel>
                </Collapse>
            </div>
        </>
    )
}


export default ImportantInfo;
