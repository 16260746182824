import { Result } from 'antd';
import Navbar from '../navbar';
import './UnderConstruction.css'; // mengimpor file CSS

function UnderConstruction() {
  return (
    <>
      <Navbar
        title="Menu"
        back={true}
        subtitle=""
        navigate="/home"
      ></Navbar>
      <div className="under-construction-container ">
        <Result
          status="info"
          title="Fitur Sedang Dalam Pembuatan"
          subTitle="Maaf, fitur yang Anda cari sedang dalam proses pembuatan. Kami akan segera menyediakannya untuk Anda. Terima kasih atas kesabaran Anda."
        />
      </div>
    </>
  );
}

export default UnderConstruction;
