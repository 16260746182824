export const InitializeTicket = (navigate, data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_TICKET", value: data });

  if (navigate) {
    return navigate("/help/create-ticket-detail");
  }

};

export const InitializeTicketDetails = (navigate, data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_TICKET_DET", value: data });

  if (navigate)
    return navigate("/help/create-ticket-checkin");

};

export const InitializeTicketAttachments = (data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_TICKET_FILE", value: data });
};

export const initializeSession = (data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_SESSION", value: data });
}

export const initializeHeaderInfo = (data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_SESSION_HEADER", value: data });
}

export const initializeMobile = (data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_MOBILE", value: data });
}

export const initializeVerification = (data) => (dispatch) => {
  dispatch({ type: "INITIALIZE_VERIFICATION", value: data });
}

export const initializeTopics = (data) => (dispatch) => {

  dispatch({ type: "INITIALIZE_TOPICS", value: data });
}